import { ColumnDef } from "@tanstack/react-table";
import {
  CalendlyInvite,
  ReferredPatient,
  RegistrationStatusType,
} from "../../../types";
import { TriangleAlert } from "lucide-react";
import moment from "moment";
import { CalendlyStatus } from "../../../referralDetails/calendly/calendlyStatus";
import { NextStepSelector } from "../../../referralDetails/nextStep";

export const NextStepColName = "Next Step";

export const NextStepCol: ColumnDef<ReferredPatient> = {
  id: NextStepColName,
  accessorKey: "nextStep",
  size: 250,
  minSize: 10,
  header: () => {
    return <p>Next step</p>;
  },
  cell: ({ getValue, row }) => {
    const val = getValue() as string;
    const calendlyStatus = row.original.calendlyInvite;

    const isLastStatusChangeAtLeastThreeDaysAgo = () => {
      return (
        moment().diff(moment(new Date(row.original.lastActivityOn)), "days") >=
        3
      );
    };

    const isInProgressStatus = () => {
      // if a referral is closed/rejected, we shouldn't show warnings that they are in the same status for a long time
      const inProgressStatus: RegistrationStatusType[] = [
        "New",
        "ReachOut",
        "RegisterPatientAndIntake",
        "AwaitingPatientApproval",
        "AwaitingIWelcomeEmailSend",
      ];

      return inProgressStatus.includes(row.original.status);
    };

    return (
      <>
        <div className="mt-2">
          <CalendlyState calendly={calendlyStatus} phase={val} />
          {isInProgressStatus() && isLastStatusChangeAtLeastThreeDaysAgo() && (
            <div className="inline-flex items-center space-x-2 px-2 py-1 font-medium text-red-800 dark:text-red-300 bg-red-100 dark:bg-red-900 rounded text-xs">
              <div className="w-4 h-4">
                <TriangleAlert className="w-4 h-4" />
              </div>
              <p className="">
                No change in{" "}
                {moment().diff(
                  moment(new Date(row.original.lastActivityOn)),
                  "days"
                )}{" "}
                days
              </p>
            </div>
          )}
        </div>

        <NextStepSelector
          referralId={row.original.id}
          referralStatus={row.original.status}
          value={row.original.nextStep}
        />
      </>
    );
  },
};

const CalendlyState = ({
  calendly,
  phase,
}: {
  calendly: CalendlyInvite;
  phase: string;
}) => {
  if (phase === "PatientToBeReached") {
    return <CalendlyStatus calendlyInvite={calendly} />;
  }
  return <div />;
};
