import React from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";
import { observer } from "mobx-react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Hidden from "@mui/material/Hidden";
import moment from "moment";
import Box from "@mui/material/Box";
import homeImage from "../../resources/undraw_growth_analytics_8btt.svg";
import useStores from "../../useStores";
import StyledRoot from "../../shared/StyledRootDiv";

const StyledCapitalizedTypography = styled(Typography)(() => ({
  textTransform: "capitalize",
}));

const WelcomePage = () => {
  const { authStore } = useStores();

  const getGreeting = () => {
    const splitEarly = 8;
    const splitAfternoon = 12;
    const splitEvening = 17;
    const currentHour = parseFloat(moment().format("HH"));

    if (currentHour < splitEarly) {
      return "Zo jij bent vroeg";
    }

    if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
      return "Goedemiddag";
    }

    if (currentHour >= splitEvening) {
      return "Goedeavond";
    }

    return "Goedemorgen";
  };

  const getMotivation = () => {
    if (moment().day() === 1) {
      return "Kees geniet van zijn vrije dag vandaag";
    }

    if (moment().day() === 5) {
      return "Heb je deze week al de kracht van een collega gecomplimenteerd?";
    }

    if (moment().day() > 5) {
      return "Het is weekend, wat doe jij hier?";
    }

    return "Vergeet niet te wandelen vandaag";
  };

  return (
    <StyledRoot>
      <Container maxWidth="lg">
        <Grid
          alignItems="center"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item md={6} xs={12}>
            <StyledCapitalizedTypography
              component="h1"
              gutterBottom
              variant="h3"
            >
              {getGreeting()}{" "}
              {authStore.user.userName &&
                authStore.user.userName.substr(
                  0,
                  authStore.user.userName.indexOf("@")
                )}
            </StyledCapitalizedTypography>
            <Typography gutterBottom variant="subtitle1">
              {getMotivation()}
            </Typography>
            <Box mt={8}>
              <Typography gutterBottom variant="subtitle1">
                Op deze website wordt gezondheidsinformatie verwerkt, ga hier
                voorzichtig mee om.
              </Typography>
            </Box>
          </Grid>
          <Hidden mdDown>
            <Grid item md={6}>
              <img
                alt="Cover"
                style={{
                  width: "100%",
                  maxHeight: 400,
                }}
                src={homeImage}
              />
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </StyledRoot>
  );
};

export default observer(WelcomePage);
