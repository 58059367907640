import { ColumnDef } from "@tanstack/react-table";
import { MainListPatient } from "../../../types";
import { getSimpleFormattedDateTime } from "../../../utils";
import { useTranslation } from "react-i18next";
import { SortableCell } from "../../../../../components/ui/table";

export const NextRegularConsultOnColName = "Next Regular Consult";

export const NextRegularConsultOnCol: ColumnDef<MainListPatient> = {
  id: NextRegularConsultOnColName,
  accessorKey: "nextRegularConsultOn",
  size: 150,
  minSize: 150,
  header: ({ column }) => {
    const { t } = useTranslation();
    return (
      <SortableCell
        column={column}
        label={t("case-load-manager-column-next-regular-consult")}
      />
    );
  },
  cell: ({ getValue }) => {
    const dateAsString = getValue()?.toString();

    if (!dateAsString) {
      return <></>;
    }

    return <p>{getSimpleFormattedDateTime(dateAsString)}</p>;
  },
};
