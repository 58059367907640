import { useState } from "react";
import { FindDossier } from "./findDossier";
import { useDebounce } from "use-debounce";
import { QuestionnairesOfDossier } from "./questionnairesOfDossier";

export default function SendQuestionnairesToPraktijkdataAdmin() {
  const [epdPatientId, setEpdPatientId] = useState<number | null>(null);
  const [epdFileId, setEpdFileId] = useState<number>(1);
  const [debouncedEpdPatientId] = useDebounce(epdPatientId, 500);

  return (
    <main className="p-24">
      <div className="flex item-start justify-between w-full">
        <section>
          <h1 className="font-bold text-4xl">
            Send questionnaires to PraktijkData
          </h1>

          <p className="pt-2">
            In case a questionnaire is not present in PraktijkData, you can use
            this tool to try to send it again.
          </p>
        </section>
      </div>

      <div className="mt-6">
        <FindDossier
          epdPatientId={epdPatientId}
          setEpdPatientId={setEpdPatientId}
          epdFileId={epdFileId}
          setEpdFileId={setEpdFileId}
        />

        <QuestionnairesOfDossier
          epdPatientId={debouncedEpdPatientId}
          epdFileId={epdFileId}
        />
      </div>
    </main>
  );
}
