import { CircleSlash2 } from "lucide-react";
import { Skeleton } from "../../../components/ui/skeleton";
import { isDateInPastWeeks } from "../utils";
import { useFetchProgress } from "../caseLoadOverview/hooks";
import { useTranslation } from "react-i18next";

export const ProgressChart = ({ date }: { date: Date }) => {
  const isInPast = isDateInPastWeeks(date);
  const { t } = useTranslation();

  const { data, isError, isLoading } = useFetchProgress(!isInPast);

  const EmptyChart = () => <div className="h-20" />;

  if (isError) return <EmptyChart />;

  if (isLoading) {
    return (
      <div className="w-1/2 flex flex-col justify-end items-end -mb-1">
        <Skeleton className="h-7 w-52 rounded" />
        <Skeleton className="h-4 w-32 rounded mt-1" />
        <Skeleton className="h-6 w-1/2 rounded mt-3" />
      </div>
    );
  }

  if (isInPast) return <EmptyChart />;

  const total = data!.total;
  const completed = data!.completed;
  const percent = Math.round((completed / total) * 100);

  if (total === 0)
    return (
      <div className="flex flex-col items-end space-y-2">
        <CircleSlash2 className="w-8 h-8 opacity-50" />
        <h2>{t("case-load-manager-progress-chart-no-patients")}</h2>
      </div>
    );

  return (
    <div className="w-1/2 flex flex-col justify-end items-end">
      <h1 className="text-xl">
        {t("case-load-manager-progress-chart-title")}{" "}
        <span className="font-bold">{percent}%</span>
      </h1>
      <h2 className="text-sm">
        {t("case-load-manager-progress-chart-desc", {
          done: completed,
          total: total,
        })}
      </h2>

      <div className="w-1/2 h-6 rounded flex mt-2">
        <div
          className={`h-6 bg-green-600 dark:bg-green-900 ${
            total == completed ? "rounded" : "rounded-tl rounded-bl"
          }`}
          style={{
            width: `${percent}%`,
            animation: "growWidth 0.5s ease-out forwards",
          }}
        />
        <style>
          {`
          @keyframes growWidth {
            0% {
              width: 0%;
            }
            100% {
              width: ${percent}%;
            }
          }
          @keyframes shrinkWidth {
            0% {
              width: 100%;
            }
            100% {
              width: ${100 - percent}%;
            }
          }
        `}
        </style>
        <div
          className={`h-6 bg-slate-200 dark:bg-slate-800 ${
            percent == 0 ? "rounded" : "rounded-tr rounded-br"
          }`}
          style={{
            width: "0%",
            animation: "shrinkWidth 0.5s ease-out forwards",
          }}
        />
      </div>
    </div>
  );
};
