import { useTranslation } from "react-i18next";
import { OpToCrpPsychologistRatioType } from "../../types";
import { getMainCount } from "./opToCrpPsychologistRatio";
import { Skeleton } from "../../../../components/ui/skeleton";

export const OpToCrpPsychologistRatioAverage = ({
  data,
}: {
  data: OpToCrpPsychologistRatioType[] | undefined;
}) => {
  const { t } = useTranslation();

  if (!data) {
    return (
      <div className="inline-flex p-6 items-start space-x-2">
        <div className="w-[200px] flex flex-col text-right items-end">
          <Skeleton className="w-[75px] h-12" />
          <Skeleton className="mt-1 w-[175px] h-4" />
        </div>
        <div className="text-4xl font-bold opacity-20">:</div>
        <div className="w-[200px] flex flex-col text-left">
          <Skeleton className="w-[75px] h-12" />
          <Skeleton className="mt-1 w-[175px] h-4" />
        </div>
      </div>
    );
  }

  return (
    <div className="inline-flex p-6 items-start space-x-2">
      <div className="w-[200px] flex flex-col text-right">
        <div className="text-4xl font-bold">{getMainCount(data)}</div>
        <div className="text-gray-500 dark:text-gray-400 text-xs">
          {t("case-load-manager-statistics-op-skp-ratio-chat-appointments")}
        </div>
      </div>
      <div className="text-4xl font-bold opacity-20">:</div>
      <div className="w-[200px] flex flex-col text-left">
        <div className="text-4xl font-bold">1</div>
        <div className="text-gray-500 dark:text-gray-400 text-xs">
          {t("case-load-manager-statistics-op-skp-ratio-cpr-appointments")}
        </div>
      </div>
    </div>
  );
};
