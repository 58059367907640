import { CircleCheckBig, CircleSlash, CircleX } from "lucide-react";
import { KopModelStatus, OnboardPatientDetails } from "../../types";
import { useTranslation } from "react-i18next";
import { CustomizeWelcomeMessage } from "./customizeWelcomeMessage";
import { getDidIntakeHappen } from "../../utils";

export const KopModelAndWelcomeMessageStep = ({
  onboardingPatient,
}: {
  onboardingPatient: OnboardPatientDetails;
}) => {
  const { t } = useTranslation();

  const getStatus = () => {
    if (getDidIntakeHappen(onboardingPatient)) {
      return t("case-load-manager-onboarding-kop-model-irrelevant");
    }

    switch (onboardingPatient.kopModel.status) {
      case KopModelStatus.Sent:
        return t("case-load-manager-onboarding-details-kop-sent");
      case KopModelStatus.Draft:
        return t("case-load-manager-onboarding-details-kop-drafted");
      case KopModelStatus.NotStarted:
        return t("case-load-manager-onboarding-details-kop-not-yet-started");
    }
  };

  const renderStatusIcon = () => {
    if (onboardingPatient.kopModel.status == KopModelStatus.Sent) {
      return <CircleCheckBig className="h-4 w-4" />;
    } else if (getDidIntakeHappen(onboardingPatient)) {
      return <CircleX className="h-4 w-4" />;
    } else {
      return <CircleSlash className="h-4 w-4" />;
    }
  };
  return (
    <div
      className={`p-2 border rounded-lg ${
        onboardingPatient.kopModel.status == KopModelStatus.Sent
          ? "bg-green-100 text-green-600"
          : "bg-slate-50"
      }`}
    >
      <div className="flex items-center space-x-2">
        <div className="h-4 w-4">{renderStatusIcon()}</div>
        <p className="font-bold">KOP-model & Welkomstbericht</p>
      </div>
      <p className="pl-6">{getStatus()}</p>

      <CustomizeWelcomeMessage onboardingPatient={onboardingPatient} />
    </div>
  );
};
