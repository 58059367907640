import { Button } from "../../../../../components/ui/button";
import { OnboardPatientDetails } from "../../types";
import { useSendTreatmentStartMessage } from "../../hooks";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../../components/ui/dialog";
import { Loader2 } from "lucide-react";
import { getDidIntakeHappen } from "../../utils";

export const SendTreatmentStartMessage = ({
  onboardingPatient,
}: {
  onboardingPatient: OnboardPatientDetails;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();

  const { mutate: save, isLoading: isSending } = useSendTreatmentStartMessage(
    () => {
      toast(
        t("case-load-manager-onboarding-send-treatment-msg-feedback-title"),
        {
          description: t(
            "case-load-manager-onboarding-send-treatment-msg-feedback-desc"
          ),
        }
      );
      setIsOpen(false);
    }
  );

  if (onboardingPatient.treatmentStartStandardMessageSent) {
    return <></>;
  }
  return (
    <>
      <Button
        className="ml-5 mt-2 text-sm"
        onClick={() => setIsOpen(true)}
        disabled={!getDidIntakeHappen(onboardingPatient)}
      >
        {t("case-load-manager-onboarding-send-treatment-msg-button")}
      </Button>
      {getDidIntakeHappen(onboardingPatient) ? (
        <></>
      ) : (
        <p className="ml-5 mt-1 text-xs opacity-75">
          {t(
            "case-load-manager-onboarding-send-treatment-msg-no-intake-taken-place-yet"
          )}
        </p>
      )}
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="min-w-[600px]">
          <DialogHeader>
            <DialogTitle>
              {t(
                "case-load-manager-onboarding-send-treatment-msg-dialog-title"
              )}
            </DialogTitle>
            <DialogDescription>
              {t("case-load-manager-onboarding-send-treatment-msg-dialog-desc")}
            </DialogDescription>
          </DialogHeader>

          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setIsOpen(false)}
              className="w-1/2"
            >
              {t(
                "case-load-manager-onboarding-send-treatment-msg-dialog-cancel"
              )}
            </Button>
            <Button
              onClick={async () => {
                await save({
                  id: onboardingPatient.id,
                });
              }}
              className="w-1/2 flex items-center space-x-2"
              disabled={isSending}
            >
              {isSending && <Loader2 className="w-4 h-4 animate-spin" />}
              <p>
                {t(
                  "case-load-manager-onboarding-send-treatment-msg-dialog-save"
                )}
              </p>
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
