import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import { useState } from "react";
import {
  useGetAllEpdAppointmentTypes,
  useGetSavedAppointmentTypeToDossierTypeConfig,
} from "../hooks";
import {
  CurrentAppointmentPerDossierTypeConfig,
  SelectableEpdAppointmentType,
} from "../../types";
import { SaveButton } from "./saveButton";
import { Skeleton } from "../../../../components/ui/skeleton";
import { useTranslation } from "react-i18next";

export const AutomaticBillingConfiguration = () => {
  const { t } = useTranslation();

  const [mutatedData, setMutatedData] = useState<
    CurrentAppointmentPerDossierTypeConfig[]
  >([]);

  const { data: originalData, isFetching } =
    useGetSavedAppointmentTypeToDossierTypeConfig((data) => {
      setMutatedData(data);
    });

  const {
    data: allAppointmentTypes,
    isFetching: isLoadingAllEpdAppointmentTypes,
  } = useGetAllEpdAppointmentTypes();

  return (
    <div className="mt-12">
      <h2 className="text-3xl font-bold">
        {t("case-load-manager-admin-panel-auto-bill-config-title")}
      </h2>
      <p>{t("case-load-manager-admin-panel-auto-bill-config-desc")}</p>

      {isFetching || isLoadingAllEpdAppointmentTypes ? (
        <div className="mt-4">
          <div>
            <Skeleton className="w-full rounded mt-6 bg-muted/50 pb-2">
              <div className="px-2 pt-12 space-y-1">
                <Skeleton className="h-12 w-full rounded" />
                <Skeleton className="h-12 w-full rounded" />
                <Skeleton className="h-12 w-full rounded" />
              </div>
            </Skeleton>
          </div>
          <div className="w-full flex items-end justify-end mt-3">
            <Skeleton className="h-12 w-[9rem] rounded bg-muted/50" />
          </div>
        </div>
      ) : (
        <div className="mt-4">
          <Table limitHeight className="border rounded">
            <TableHeader>
              <TableRow>
                <TableHead className="w-1/2">
                  {t(
                    "case-load-manager-admin-panel-auto-bill-config-dossier-type-col-caption"
                  )}
                </TableHead>
                <TableHead className="w-1/2">
                  {t(
                    "case-load-manager-admin-panel-auto-bill-config-appointment-type-col-caption"
                  )}
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {mutatedData!
                .toSorted((a, b) => {
                  if (a.epdDossierType.name < b.epdDossierType.name) {
                    return -1;
                  }
                  if (a.epdDossierType.name > b.epdDossierType.name) {
                    return 1;
                  }
                  return 0;
                })
                .map((x) => {
                  return (
                    <Row
                      key={x.epdDossierType.name}
                      data={x}
                      allAppointmentTypes={allAppointmentTypes!}
                      updateAppointmentType={(newType) => {
                        setMutatedData((prev) => {
                          const index = prev.findIndex(
                            (p) => p.epdDossierType.id === x.epdDossierType.id
                          );
                          const newPrev = [...prev];
                          newPrev[index] = {
                            ...newPrev[index],
                            epdAppointmentType: newType,
                          };
                          return newPrev;
                        });
                      }}
                    />
                  );
                })}
            </TableBody>
          </Table>

          <div className="w-full flex items-end justify-end mt-6">
            <SaveButton
              originalData={originalData!}
              mutatedData={mutatedData}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const Row = ({
  data,
  allAppointmentTypes,
  updateAppointmentType,
}: {
  data: CurrentAppointmentPerDossierTypeConfig;
  allAppointmentTypes: SelectableEpdAppointmentType[];
  updateAppointmentType: (
    newValue: SelectableEpdAppointmentType | null
  ) => void;
}) => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell className="py-0">{data.epdDossierType.name}</TableCell>
      <TableCell className="py-1">
        <Select
          value={data.epdAppointmentType?.id}
          onValueChange={(x) => {
            let result: SelectableEpdAppointmentType | null = null;
            if (x != "unassigned") {
              result = allAppointmentTypes.filter((a) => a.id === x)[0];
            }
            updateAppointmentType(result);
          }}
        >
          <SelectTrigger>
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem value={"unassigned"}>
                <p className="italic opacity-70">
                  {t(
                    "case-load-manager-admin-panel-auto-bill-config-no-assignment-value"
                  )}
                </p>
              </SelectItem>
              {allAppointmentTypes
                .toSorted((a, b) => {
                  if (a.name < b.name) return -1;
                  if (a.name > b.name) return 1;
                  return 0;
                })
                .map((i) => {
                  return (
                    <SelectItem value={i.id}>
                      <span className="inline-block w-10 opacity-40 text-xs">
                        ID: {i.epdId}
                      </span>{" "}
                      {i.name}
                    </SelectItem>
                  );
                })}
            </SelectGroup>
          </SelectContent>
        </Select>
      </TableCell>
    </TableRow>
  );
};
