import React from "react";
import Drawer from "@mui/material/Drawer";
import SidebarNav from "./SidebarNav";
import { styled } from "@mui/system";

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  paper: {
    width: 240,
    [theme.breakpoints.up("lg")]: {
      marginTop: 64,
      height: "calc(100% - 64px)",
    },
  },
}));

const Sidebar = ({ open, variant, onClose }) => {
  return (
    <StyledDrawer anchor="left" onClose={onClose} open={open} variant={variant}>
      <SidebarNav />
    </StyledDrawer>
  );
};

export default Sidebar;
