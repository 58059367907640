import { Button } from "../../../components/ui/button";
import { CalendarSearch } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/ui/popover";
import { Calendar } from "../../../components/ui/calendar";
import { SelectSingleEventHandler } from "react-day-picker";
import { Table } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { getCurrentMondayAndFriday } from "../utils";

export const ViewPreviousWeeksButton = ({
  selectedDate,
  setSelectedDate,
  table,
}: {
  selectedDate: Date;
  setSelectedDate: SelectSingleEventHandler;
  table: Table<any>;
}) => {
  const { t } = useTranslation();
  const { friday } = getCurrentMondayAndFriday();

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button className="flex items-center gap-2 w-52" variant="outline">
          <CalendarSearch className="w-5 h-5" />
          <p>{t("case-load-manager-customization-week-selector-button")}</p>
        </Button>
      </PopoverTrigger>
      <PopoverContent align="center" side="left" className="min-w-fit">
        <Calendar
          mode="single"
          showWeekNumber
          toDate={friday}
          selected={selectedDate}
          onSelect={(day, selectedDay, activeModifiers, e) => {
            table.resetRowSelection();
            setSelectedDate(day, selectedDay, activeModifiers, e);
          }}
          disabled={(d) => {
            // mondays are OK to select
            if (d.getDay() == 1) return false;

            // rest is disabled
            return true;
          }}
        />
      </PopoverContent>
    </Popover>
  );
};
