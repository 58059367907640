import { useEffect, useState } from "react";
import {
  getDefaultHealthcareInsurerFilters,
  getDefaultIsContractedInsurerFilter,
  getDefaultLocationFilters,
  getDefaultPageSize,
  getDefaultRegistrationStatusFilters,
  getDefaultTransferReferralFilter,
  getDefaultNextStepsFilters,
} from "../utils";
import { ViewType } from "../types";
import { useGetReferredPatients } from "../hooks";
import { ReferralsTable } from "./referralsTable";
import { useDebounce } from "use-debounce";
import { SortingState } from "@tanstack/react-table";
import { getSortingData } from "./columns/sortingUtil";
import { Sheet, SheetClose, SheetContent } from "../../../components/ui/sheet";
import { Details } from "../referralDetails/details";
import { useHistory, useLocation } from "react-router-dom";

export const ReferralOverview = ({
  defaultSelectedReferralId,
}: {
  defaultSelectedReferralId: string | null;
}) => {
  const [selectedReferralId, setSelectedReferralId] = useState<string | null>(
    defaultSelectedReferralId
  );
  const [pageSize, setPageSize] = useState<number>(getDefaultPageSize());
  const [skip, setSkip] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  const [locationIds, setLocationIds] = useState<string[]>(
    getDefaultLocationFilters()
  );
  const [healthcareInsurerIds, setHealthcareInsurerIds] = useState<string[]>(
    getDefaultHealthcareInsurerFilters()
  );

  const [registrationStatuses, setRegistrationStatuses] = useState<string[]>(
    getDefaultRegistrationStatusFilters()
  );

  const [nextSteps, setNextSteps] = useState<string[]>(
    getDefaultNextStepsFilters()
  );

  const [sorting, setSorting] = useState<SortingState>([]);
  const sortingData = getSortingData(sorting);

  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const [viewType, setViewType] = useState<ViewType>(
    (localStorage.getItem("crm-main-list-view-type") as ViewType | null) ??
      "working-view"
  );

  const [transferFilter, setTransferFilter] = useState(
    getDefaultTransferReferralFilter()
  );

  const [contractFilter, setContractFilter] = useState(
    getDefaultIsContractedInsurerFilter()
  );

  const onChangeViewType = (newValue: ViewType) => {
    localStorage.setItem("crm-main-list-view-type", newValue);
    setViewType(newValue);
  };

  // todo: in this case we don't have the entry necessarily in the 'data'
  // todo: and fetch the details immediately

  useEffect(() => {
    // if on the first render we have an ?id searchParam, we open the Sheet
    if (defaultSelectedReferralId) {
      setIsSheetOpen(true);
    }

    if (location.search.startsWith("?id=")) {
      const id = location.search.split("=")[1];
      // updateSelectPageSize(Number(id));
    }
  }, []);

  useEffect(() => {
    setSkip(0);
  }, [
    debouncedSearchTerm,
    registrationStatuses,
    nextSteps,
    locationIds,
    healthcareInsurerIds,
    contractFilter,
    transferFilter,
    viewType,
    pageSize,
  ]);

  const updateSelectPageSize = (val: number) => {
    setPageSize(val);
    localStorage.setItem("crm-page-size", val.toString());
  };

  const {
    data: paginatedPatientData,
    isLoading,
    isFetching,
    isError,
  } = useGetReferredPatients(
    locationIds,
    registrationStatuses,
    nextSteps,
    healthcareInsurerIds,
    transferFilter,
    contractFilter,
    viewType,
    pageSize,
    skip,
    debouncedSearchTerm,
    sortingData.column,
    sortingData.isAsc
  );

  const handleRowClick = (id: string) => {
    setSelectedReferralId(id);
    setIsSheetOpen(true);

    history.push({
      search: `?id=${id}`,
    });
  };

  const onCloseSheet = () => {
    // we remove the ?id=... searchParam when the Sheet closes
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete("id");

    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });

    setIsSheetOpen(false);
    setSelectedReferralId(null);
  };

  return (
    <Sheet
      open={isSheetOpen}
      onOpenChange={(newValue) => {
        if (newValue) {
          setIsSheetOpen(true);
        } else {
          onCloseSheet();
        }
      }}
    >
      <ReferralsTable
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        data={paginatedPatientData?.data ?? []}
        pagination={paginatedPatientData?.pagination}
        sorting={sorting}
        setSorting={setSorting}
        pageSize={pageSize}
        setPageSize={updateSelectPageSize}
        skip={skip}
        setSkip={setSkip}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        registrationStatuses={registrationStatuses}
        setRegistrationStatuses={setRegistrationStatuses}
        nextSteps={nextSteps}
        setNextSteps={setNextSteps}
        locationIds={locationIds}
        setLocationIds={setLocationIds}
        healthcareInsurerIds={healthcareInsurerIds}
        setHealthcareInsurerIds={setHealthcareInsurerIds}
        viewType={viewType}
        setViewType={onChangeViewType}
        transferFilter={transferFilter}
        setTransferFilter={setTransferFilter}
        contractFilter={contractFilter}
        setContractFilter={setContractFilter}
        onRowClick={handleRowClick}
      />
      <SheetContent
        side={"right"}
        onInteractOutside={() => onCloseSheet()}
        className="min-w-[100vw] xl:min-w-[70vw] overflow-y-auto overflow-x-hidden p-8 flex items-start justify-center bg-gradient-to-tl from-gray-100 to-white dark:from-slate-950 dark:to-slate-900"
      >
        {selectedReferralId && <Details referralId={selectedReferralId} />}
      </SheetContent>
      <SheetClose onClick={() => onCloseSheet()} />
    </Sheet>
  );
};
