import { KopModelTextBoxes } from "../../../onboardPatients/details/kopModelInput/kopModelTextBoxes";
import { useGetAssignedPatient } from "../../hooks";

export const KopModel = ({ clientId }: { clientId: string }) => {
  const { data } = useGetAssignedPatient(clientId);

  if (!data) return <></>;

  return (
    <KopModelTextBoxes
      isDisabled
      complaints={data.kopModel?.complaints ?? ""}
      circumstances={data.kopModel?.circumstances ?? ""}
      personalStyle={data.kopModel?.personalStyle ?? ""}
      requestForHelp={data.kopModel?.requestForHelp ?? ""}
      myCommentsForDiagnosisAndTreatment={
        data.kopModel?.myCommentsForDiagnosisAndTreatment ?? ""
      }
      setComplains={() => {}}
      setCircumstances={() => {}}
      setPersonalStyle={() => {}}
      setRequestForHelp={() => {}}
      setMyCommentsForDiagnosisAndTreatment={() => {}}
    />
  );
};
