import moment from "moment";
import { IntakeOnColName } from "./caseLoadOverview/columns/distinctCols/intakeOnCol";
import { LastRegularConsultOnColName } from "./caseLoadOverview/columns/distinctCols/lastRegularConsultOnCol";
import { NextRegularConsultOnColName } from "./caseLoadOverview/columns/distinctCols/nextRegularConsultOnCol";
import { LastChatConsultOnColName } from "./caseLoadOverview/columns/distinctCols/lastChatConsultOnCol";
import { IsPatientInsuredColName } from "./caseLoadOverview/columns/distinctCols/isPatientInsuredCol";
import { IsPatientContractedColName } from "./caseLoadOverview/columns/distinctCols/isPatientContractedCol";
import { NumberOfRegularConsultsColName } from "./caseLoadOverview/columns/distinctCols/numberOfRegularConsultsCol";
import { RegularConsultPsychologistsColName } from "./caseLoadOverview/columns/distinctCols/regularConsultPsychologistsCol";
import { TreatmentColName } from "./caseLoadOverview/columns/distinctCols/treatmentCol";

export const getSimpleFormattedDate = (input: Date | string) => {
  const inputAsDate = input instanceof Date ? input : new Date(input);

  return inputAsDate.toLocaleDateString("nl-NL", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};

export const getSimpleFormattedDateTime = (input: Date | string) => {
  const inputAsDate = input instanceof Date ? input : new Date(input);

  return inputAsDate.toLocaleString("nl-NL", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const getSimpleFormattedDateWithoutYear = (input: Date | string) => {
  const inputAsDate = input instanceof Date ? input : new Date(input);

  return inputAsDate.toLocaleString("nl-NL", {
    month: "2-digit",
    day: "2-digit",
  });
};

export const isDateInPastWeeks = (date: Date) => {
  const currentDate = new Date();

  const currentYear = moment(currentDate).year();
  const selectedYear = moment(date).year();

  if (currentYear > selectedYear) return true;

  const currentWeek = moment(currentDate).week();
  const selectedWeek = moment(date).week();

  return currentWeek > selectedWeek;
};

export const isPeriodInPastWeeks = (period: { year: number; week: number }) => {
  const currentDate = new Date();

  const currentYear = moment(currentDate).year();
  const selectedYear = period.year;

  if (currentYear > selectedYear) return true;

  const currentWeek = moment(currentDate).week();
  const selectedWeek = period.week;

  return currentWeek > selectedWeek;
};

export const isPeriodInFutureWeeks = (period: {
  year: number;
  week: number;
}) => {
  const currentDate = new Date();

  const currentYear = moment(currentDate).year();
  const selectedYear = period.year;

  if (currentYear < selectedYear) return true;

  const currentWeek = moment(currentDate).week();
  const selectedWeek = period.week;

  return currentWeek < selectedWeek;
};

export const getDefaultColumnSizingFromLocalStorage = () => {
  const fromLocalStorage = localStorage.getItem("case-load-column-sizing");

  if (!fromLocalStorage || fromLocalStorage == "undefined") {
    return {};
  }

  return JSON.parse(fromLocalStorage);
};

export const getDefaultColumnVisibilityFromLocalStorage = (
  key: "case-load-column-visibility" | "automatic-billing-column-visibility"
) => {
  const fromLocalStorage = localStorage.getItem(key);
  return !fromLocalStorage || fromLocalStorage == "undefined"
    ? {
        [LastRegularConsultOnColName]: false,
        [NextRegularConsultOnColName]: false,
        [LastChatConsultOnColName]: false,
        [NumberOfRegularConsultsColName]: false,
        [RegularConsultPsychologistsColName]: false,
        [IntakeOnColName]: false,
        [IsPatientInsuredColName]: false,
        [IsPatientContractedColName]: false,
        [TreatmentColName]: false,
      } // hide a couple of columns by default
    : JSON.parse(fromLocalStorage);
};

export const getDefaultPageSize = (
  key: "case-load-page-size" | "automatic-billing-page-size"
) => {
  const fromLocalStorage = localStorage.getItem(key);
  return !fromLocalStorage || fromLocalStorage == "undefined"
    ? 100
    : Number(fromLocalStorage);
};

export const getOnlyCaseLoadEntriesWithAlertsFromLocalStorage = () => {
  const val = localStorage.getItem("only-show-case-load-with-alerts");
  return val == null ? false : val === "true";
};

export const getExcludeNoCheckinPatientsFromLocalStorage = () => {
  const val = localStorage.getItem("exclude-no-checkin-patients");
  return val == null ? false : val === "true";
};

export const getExcludeAlreadySupportedPatientsFromLocalStorage = () => {
  const val = localStorage.getItem("exclude-already-supported-patients");
  return val == null ? false : val === "true";
};

export const getOnlyShowSoonOffboardingPatients = () => {
  const val = localStorage.getItem("only-show-soon-offboarding-patients");
  return val == null ? false : val === "true";
};

export const getCurrentMondayAndFriday = () => {
  let now = new Date();
  let day = now.getDay();
  let diffToMonday = now.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is Sunday
  let diffToFriday = diffToMonday + 4; // 4 days after Monday

  let monday = new Date(now.setDate(diffToMonday));
  monday.setHours(0, 0, 0, 0); // set time to 00:00:00.000

  let friday = new Date(now.setDate(diffToFriday));
  friday.setHours(23, 59, 59, 999); // set time to 23:59:59.999

  return {
    monday: moment(monday).utc(true).toDate(),
    friday: moment(friday).utc(true).toDate(),
  };
};

export const getNextMonday = () => {
  const today = new Date();
  const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)
  const daysUntilNextMonday = (8 - dayOfWeek) % 7 || 7;
  const nextMonday = new Date(today);
  nextMonday.setDate(today.getDate() + daysUntilNextMonday);
  return nextMonday;
};
