import axios from "axios";
import { useQuery } from "react-query";
import {
  CaseLoadWeekByWeekProgressChart,
  OpToCrpPsychologistRatioType,
} from "../types";

export const useGetMyCaseLoadWeekByWeekProgress = (
  start: Date | undefined,
  end: Date | undefined
) => {
  const query = useQuery<CaseLoadWeekByWeekProgressChart[]>(
    [
      "case-load-manager",
      "statistics",
      "week-by-week-progress",
      start ?? "start-default",
      end ?? "end-default",
    ],
    async () => {
      const url = new URL(
        `${window.location.origin}/api/caseloadmanagerreporting/chart/my-week-by-week-progress`
      );

      if (start) {
        url.searchParams.append("start", start?.toISOString());
      }

      if (end) {
        url.searchParams.append("end", end?.toISOString());
      }

      const res = await axios.get(url.toString());
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useGetMyChatToConsultationRoomAppointmentsRatio = (
  onSuccess: (data: OpToCrpPsychologistRatioType[]) => void
) => {
  const query = useQuery<OpToCrpPsychologistRatioType[]>(
    [
      "case-load-manager",
      "statistics",
      "chat-to-consultation-room-appointment-ratio",
    ],
    async () => {
      const res = await axios.get(
        `caseloadmanagerreporting/chat-to-consultation-room-appointment-ratio`
      );
      return res.data;
    },
    {
      onSuccess: onSuccess,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};
