import React from "react";
import ScheduleDefinitionsStore from "./stores/ScheduleDefinitionsStore";
import PatientsStore from "./stores/PatientsStore";
import PatientStore from "./stores/PatientStore";
import WireUsersStore from "./stores/WireUsersStore";
import AuthStore from "./stores/AuthStore";
import DossiersStore from "./stores/DossiersStore";
import ConsultsStore from "./stores/ConsultsStore";
import EmployeesStore from "./stores/EmployeesStore";
import SubscriptionsStore from "./stores/SubscriptionsStore";
import OnboardingStore from "./stores/OnboardingStore";
import CalendarStore from "./stores/CalendarStore";
import OnboardingsStore from "./stores/OnboardingsStore";
import AdminStore from "./stores/AdminStore";

const storesContext = React.createContext({
  patientsStore: new PatientsStore(),
  patientStore: new PatientStore(),
  dossiersStore: new DossiersStore(),
  consultsStore: new ConsultsStore(),
  scheduleDefinitionsStore: new ScheduleDefinitionsStore(),
  wireUsersStore: new WireUsersStore(),
  authStore: new AuthStore(),
  subscriptionStore: new SubscriptionsStore(),
  employeesStore: new EmployeesStore(),
  onboardingStore: new OnboardingStore(),
  onboardingsStore: new OnboardingsStore(),
  calendarStore: new CalendarStore(),
  adminStore: new AdminStore(),
});

const useStores = () => React.useContext(storesContext);
export default useStores;
