import { Info } from "lucide-react";
import { useGetAssignedPatient } from "../../hooks";

export const Remarks = ({ clientId }: { clientId: string }) => {
  const { data } = useGetAssignedPatient(clientId);

  if (!data || data.remarks?.length == 0) return <></>;

  return (
    <section>
      <ul className="mt-4 mb-2 list-none space-y-2 text-xs bg-slate-50 rounded-lg p-2">
        {data.remarks.map((r, index) => {
          if (index == 0) {
            return (
              <li key={index} className="flex items-start space-x-1">
                <div className="w-4 h-4">
                  <Info className="w-4 h-4" />
                </div>
                <p>{r}</p>
              </li>
            );
          }
          return (
            <li key={index} className="pl-5">
              {r}
            </li>
          );
        })}
      </ul>
    </section>
  );
};
