import { ColumnDef } from "@tanstack/react-table";
import { MainListPatient } from "../../../types";
import { useTranslation } from "react-i18next";

export const RegularConsultPsychologistsColName =
  "Regular Consult Psychologists";

export const RegularConsultPsychologistsCol: ColumnDef<MainListPatient> = {
  id: RegularConsultPsychologistsColName,
  accessorKey: "nameOfConsultationRoomPsychologists",
  size: 200,
  minSize: 100,
  header: () => {
    const { t } = useTranslation();
    return <p>{t("case-load-manager-column-regular-consult-psychologists")}</p>;
  },
  cell: ({ getValue }) => {
    const psychologists = getValue() as string[];

    if (psychologists.length === 0) return <p className="opacity-30">N/A</p>;

    return (
      <ul className="list-inside list-disc">
        {psychologists.map((psychologist) => (
          <li key={psychologist} className="text-xs">
            {psychologist}
          </li>
        ))}
      </ul>
    );
  },
};
