import { Info } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";
import { useGetIntakeDeviationsForCalendar } from "./hooks";
import moment from "moment";

export const CalendarInfoForIntakeDeviations = ({ date }: { date: string }) => {
  const { data } = useGetIntakeDeviationsForCalendar(
    // we get back in GMT the data from the calendar, and we want to strip the timezone offset before passing it to the backend
    // (eg. if we're in GMT, and we get back this Monday 00:00, the backend may get last Sunday 23:00 --> which we need to avoid)
    moment(date).utc(true).toISOString()
  );

  if ((data ?? []).length == 0) return <></>;

  const getDescription = () => {
    if (data!.length == 1)
      return `Deze week heeft ${
        data![0].psychologistName
      } afwijkingen in hoeveel intakes hij/zij per dag accepteert.`;

    if (data!.length < 3)
      return `Deze week hebben ${data!
        .map((d) => d.psychologistName)
        .join(" en ")} afwijkingen in hoeveel intakes zij per dag accepteren.`;

    if (data!.length == 3)
      return `Deze week hebben ${data!
        .slice(0, 2)
        .map((d) => d.psychologistName)
        .join(
          ", "
        )} en nog een andere psycholoog afwijkingen in hoeveel intakes zij per dag accepteren.`;

    return `Deze week hebben ${data!
      .slice(0, 2)
      .map((d) => d.psychologistName)
      .join(", ")} en nog ${
      data!.length - 2
    } andere psychologen afwijkingen in hoeveel intakes zij per dag accepteren.`;
  };

  return (
    <div className="w-full p-2 bg-amber-100 text-amber-600 border-t border-x mt-6 -mb-[21px] rounded-t text-sm flex items-center space-x-1">
      <Info className="w-4 h-4" />
      <p>
        {getDescription()}{" "}
        <TooltipProvider delayDuration={100}>
          <Tooltip>
            <TooltipTrigger>
              <span className="underline cursor-pointer">Bekijk details</span>
            </TooltipTrigger>
            <TooltipContent style={{ zIndex: 99999 }}>
              <ul className="list-inside list-disc space-y-1 p-2">
                {data!.map((d, i) => {
                  return (
                    <li key={i}>
                      {d.psychologistName}: {d.acceptedIntakes} intakes per dag
                    </li>
                  );
                })}
              </ul>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </p>
    </div>
  );
};
