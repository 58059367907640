import { Skeleton } from "../../../../../../components/ui/skeleton";
import { useGetTeamChatToConsultationRoomAppointmentRatio } from "../../../hooks";
import { OpToCrpPsychologistRatioType } from "../../../../types";
import { useState } from "react";
import { getMainCount } from "../../../../../caseLoadCockpit/userStatistics/psychologistRatio/opToCrpPsychologistRatio";
import { FlattenedTableRows } from "./flattenedTableRows";
import { AggregatedTableRows } from "./aggregatedTableRows";
import { Header } from "./header";
import {
  Table,
  TableHeader,
  TableHead,
  TableBody,
  TableRow,
} from "../../../../../../components/ui/table";
import { PerPatientFilter } from "./perPatientFilter";
import { PsychologistFilter } from "./psychologistFilter";

export const OpToCrpPsychologistRatioTable = () => {
  const { data, isLoading } =
    useGetTeamChatToConsultationRoomAppointmentRatio();

  const [selectedPsychologist, setSelectedPsychologist] =
    useState<string>("full-team");
  const [showPerPatientData, setShowPerPatientData] = useState(false);

  const getFilteredData = () => {
    if (!data || data.length === 0) return [];

    if (!showPerPatientData) {
      const groupedByPsy = data.reduce(
        (entryMap, e) =>
          entryMap.set(e.psychologist, [
            ...(entryMap.get(e.psychologist) || []),
            e,
          ]),
        new Map()
      );

      const aggregatedResult: { psychologist: string; ratio: string }[] = [];

      for (let [key, value] of groupedByPsy) {
        aggregatedResult.push({
          psychologist: key,
          ratio: getMainCount(value),
        });
      }

      if (selectedPsychologist != "full-team") {
        return aggregatedResult.filter(
          (x) => x.psychologist === selectedPsychologist
        );
      }

      return aggregatedResult;
    }

    if (selectedPsychologist != "full-team") {
      return data.filter((x) => x.psychologist === selectedPsychologist);
    }

    return data;
  };

  const renderTableBody = () => {
    let filteredData = getFilteredData();

    if (showPerPatientData) {
      return (
        <FlattenedTableRows
          data={filteredData as OpToCrpPsychologistRatioType[]}
        />
      );
    }

    return (
      <AggregatedTableRows
        data={
          filteredData as {
            psychologist: string;
            ratio: string;
          }[]
        }
      />
    );
  };

  if (isLoading) {
    return (
      <div>
        <Header />
        <Skeleton className="h-12 w-full rounded mt-6" />
        <Skeleton className="h-8 w-full rounded mt-1" />
        <Skeleton className="h-8 w-full rounded mt-1" />
        <Skeleton className="h-8 w-full rounded mt-1" />
      </div>
    );
  }

  return (
    <>
      <Header />

      <section className="mt-4 w-full flex items-center space-x-2">
        <PsychologistFilter
          value={selectedPsychologist}
          setValue={setSelectedPsychologist}
          data={[...new Set((data ?? [])?.map((d) => d.psychologist))]}
        />
        <PerPatientFilter
          value={showPerPatientData}
          setValue={setShowPerPatientData}
        />
      </section>
      <div className="mt-6">
        <Table limitHeight className="border">
          <TableHeader>
            <TableRow>
              <TableHead>Psychologist</TableHead>
              <TableHead
                className={`${showPerPatientData ? "visible" : "hidden"}`}
              >
                Patient
              </TableHead>
              <TableHead
                className={`${
                  showPerPatientData ? "visible" : "hidden"
                } text-center`}
              >
                Chat Appointments
              </TableHead>
              <TableHead
                className={`${
                  showPerPatientData ? "visible" : "hidden"
                } text-center`}
              >
                Consultation Room Appointments
              </TableHead>
              <TableHead className="text-center">Ratio</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>{renderTableBody()}</TableBody>
        </Table>
      </div>
    </>
  );
};
