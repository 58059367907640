import React from "react";
import { observer } from "mobx-react";
import moment from "moment";
import useStores from "../../useStores";
import {
  BookOpenText,
  Info,
  Link,
  MessageSquareText,
  NotebookTabs,
  ChevronDown,
  Rss,
} from "lucide-react";
import { Tile } from "./Tile";
import { Post } from "./news/post";
import homeImage from "../../resources/undraw_growth_analytics_8btt.svg";

const WelcomePage = () => {
  const { authStore } = useStores();

  const getGreeting = () => {
    const splitEarly = 8;
    const splitAfternoon = 12;
    const splitEvening = 17;
    const currentHour = parseFloat(moment().format("HH"));

    if (currentHour < splitEarly) {
      return "Zo jij bent vroeg";
    }

    if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
      return "Goedemiddag";
    }

    if (currentHour >= splitEvening) {
      return "Goedeavond";
    }

    return "Goedemorgen";
  };

  const getMotivation = () => {
    if (moment().day() === 1) {
      return "Kees geniet van zijn vrije dag vandaag";
    }

    if (moment().day() === 5) {
      return "Heb je deze week al de kracht van een collega gecomplimenteerd?";
    }

    if (moment().day() > 5) {
      return "Het is weekend, wat doe jij hier?";
    }

    return "Vergeet niet te wandelen vandaag";
  };

  return (
    <section className="min-w-[75vw] pl-0 lg:pl-[5vw] min-h-[100vh] flex flex-col justify-center relative">
      <img
        alt="Cover"
        style={{
          height: 450,
        }}
        src={homeImage}
        className="absolute z-0 opacity-20 top-0 m-12 right-0 hidden lg:block"
      />
      <div className="z-10">
        <h1 className="text-2xl lg:text-5xl font-bold">
          {getGreeting()}{" "}
          {(authStore.user as any).userName &&
            (authStore.user as any).userName.substr(
              0,
              (authStore.user as any).userName.indexOf("@")
            )}
        </h1>
        <h2 className="text-lg lg:text-xl">{getMotivation()}</h2>
        <p className="pt-6">
          Op deze website wordt gezondheidsinformatie verwerkt, ga hier
          voorzichtig mee om.
        </p>
      </div>
      {/* <section className="mt-12 w-full lg:w-2/3">
        <div className="flex items-center space-x-2 mb-3">
          <Rss className="w-6 h-6" />
          <h2 className="text-2xl font-bold">News</h2>
        </div>
        <div className="flex flex-col space-y-3">
          <Post
            title="Dashboard design upgrades"
            shortDesc="Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum quos similique ex, beatae reiciendis rem omnis totam eos facilis odio porro voluptates harum eum quidem reprehenderit aliquid. Vero, eaque!"
            longDesc="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quidem praesentium maxime perferendis ipsam iusto consectetur nulla, incidunt sequi vitae, perspiciatis id quaerat quas rem, similique voluptas eveniet. Maiores numquam dolore excepturi. Corrupti praesentium sapiente animi itaque sunt doloribus odio, recusandae, aperiam laborum rerum eos quaerat ex voluptatem similique nostrum labore voluptatum in nisi quas dignissimos alias quae, ad necessitatibus iste? Doloremque dolorum facilis maxime rem praesentium sequi consectetur repellat eaque maiores non perferendis, suscipit harum quae quis quos provident vel atque accusantium eum nesciunt quas consequatur dignissimos temporibus. Qui quibusdam nemo temporibus sit unde magnam maxime, hic consequuntur neque ducimus repellat, vitae nobis minus fugit, explicabo voluptatibus molestiae expedita culpa laudantium odit illo tempore reiciendis eaque quaerat. Saepe sint, ipsa repellendus magni repudiandae nihil, possimus repellat explicabo nisi quisquam, quae ducimus iure. Officiis necessitatibus molestias ex? Deserunt quae suscipit corporis aliquid adipisci blanditiis quibusdam perferendis et ex obcaecati assumenda odit repudiandae maxime eius praesentium, id error nesciunt ut? Beatae accusamus nemo corrupti nisi. Nobis, distinctio ducimus cum placeat impedit et! Nobis ipsum aliquid, repellendus ea suscipit, facere iure ipsa ad delectus harum provident maxime laborum sequi, omnis corporis voluptas magnam quidem odit earum aliquam in eaque ullam. Commodi molestias nam laborum. Consequatur fugit eius dolor natus, neque sint cupiditate beatae facere aliquid quis placeat veniam aspernatur atque nulla fuga! Fugit accusantium quod harum pariatur consequuntur sapiente sequi hic ullam ut numquam obcaecati earum, fuga reprehenderit voluptate sunt quam dignissimos molestiae suscipit beatae. Laborum cumque incidunt corporis, tenetur molestias quaerat nobis?"
            publishedOn={new Date("2024-04-06")}
          />
          <Post
            title="Caseload Manager is live!"
            shortDesc="Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum quos similique ex, beatae reiciendis rem omnis totam eos facilis odio porro voluptates harum eum quidem reprehenderit aliquid. Vero, eaque!"
            longDesc="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quidem praesentium maxime perferendis ipsam iusto consectetur nulla, incidunt sequi vitae, perspiciatis id quaerat quas rem, similique voluptas eveniet. Maiores numquam dolore excepturi. Corrupti praesentium sapiente animi itaque sunt doloribus odio, recusandae, aperiam laborum rerum eos quaerat ex voluptatem similique nostrum labore voluptatum in nisi quas dignissimos alias quae, ad necessitatibus iste? Doloremque dolorum facilis maxime rem praesentium sequi consectetur repellat eaque maiores non perferendis, suscipit harum quae quis quos provident vel atque accusantium eum nesciunt quas consequatur dignissimos temporibus. Qui quibusdam nemo temporibus sit unde magnam maxime, hic consequuntur neque ducimus repellat, vitae nobis minus fugit, explicabo voluptatibus molestiae expedita culpa laudantium odit illo tempore reiciendis eaque quaerat. Saepe sint, ipsa repellendus magni repudiandae nihil, possimus repellat explicabo nisi quisquam, quae ducimus iure. Officiis necessitatibus molestias ex? Deserunt quae suscipit corporis aliquid adipisci blanditiis quibusdam perferendis et ex obcaecati assumenda odit repudiandae maxime eius praesentium, id error nesciunt ut? Beatae accusamus nemo corrupti nisi. Nobis, distinctio ducimus cum placeat impedit et! Nobis ipsum aliquid, repellendus ea suscipit, facere iure ipsa ad delectus harum provident maxime laborum sequi, omnis corporis voluptas magnam quidem odit earum aliquam in eaque ullam. Commodi molestias nam laborum. Consequatur fugit eius dolor natus, neque sint cupiditate beatae facere aliquid quis placeat veniam aspernatur atque nulla fuga! Fugit accusantium quod harum pariatur consequuntur sapiente sequi hic ullam ut numquam obcaecati earum, fuga reprehenderit voluptate sunt quam dignissimos molestiae suscipit beatae. Laborum cumque incidunt corporis, tenetur molestias quaerat nobis?"
            publishedOn={new Date("2024-03-26")}
          />
          todo: if we have more than 3 news entries, probably we want to do something like a "pagination" (or "Load older entries"):
           <div className="inline-flex justify-start items-center space-x-1 text-link cursor-pointer">
            <ChevronDown className="h-4 w-4" />
            <p className="hover:underline">Load older news</p>
          </div>
        </div>
      </section> */}

      {/* <section>
        <div className="mt-12 w-full lg:w-fit mb-12 rounded grid grid-cols-1 lg:grid-cols-4 gap-6  justify-items-center">
          <Tile
            label="iPractice Handbook"
            icon={<BookOpenText className="w-14 h-14" />}
          />
          <Tile
            label="Patient Referral Manual"
            icon={<NotebookTabs className="w-14 h-14" />}
          />
          <Tile
            label="Caseload Manager Handbook"
            icon={<MessageSquareText className="w-16 h-16" />}
          />
          <Tile label="Useful links" icon={<Link className="w-14 h-14" />} />
          <Tile label="Other stuff" icon={<Info className="w-14 h-14" />} />
          <Tile label="External link" icon={<Link className="w-14 h-14" />} />
        </div>
      </section> */}
    </section>
  );
};

export default observer(WelcomePage);
