export default {
  ONLINE: "Online",
  AMSTERDAM: "Amsterdam",
  UTRECHT: "Utrecht",
  ROTTERDAM: "Rotterdam",
  GRONINGEN: "Groningen",
  EINDHOVEN: "Eindhoven",
  NIJMEGEN: "Nijmegen",
  DEN_HAAG: "Den Haag",
  ALKMAAR: "Alkmaar",
};
