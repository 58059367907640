import {
  HorizontalTab,
  HorizontalTabHeader,
  HorizontalTabContent,
} from "../../../../components/ui/horizontalTab";
import { InflowReport } from "./inflow-report";
import { BacklogReport } from "./backlog-report";
import { InflowByLocationReport } from "./inflow-location-report";

export default function CrmStatistics() {
  return (
    <div className="p-16">
      <HorizontalTab>
        <div className="w-full mb-12">
          <div className="flex items-center ml-3">
            <HorizontalTabHeader index={0}>Inflow Report</HorizontalTabHeader>
            <HorizontalTabHeader index={1}>Backlog Report</HorizontalTabHeader>
            <HorizontalTabHeader index={2}>
              Inflow By Location Report
            </HorizontalTabHeader>
          </div>
          <HorizontalTabContent index={0}>
            <InflowReport />
          </HorizontalTabContent>
          <HorizontalTabContent index={1}>
            <BacklogReport />
          </HorizontalTabContent>
          <HorizontalTabContent index={2}>
            <InflowByLocationReport />
          </HorizontalTabContent>
        </div>
      </HorizontalTab>
    </div>
  );
}
