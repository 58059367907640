import axios from "axios";
import { useQuery } from "react-query";
import { AutomaticBilling, AutomaticBillingSortingColumns } from "../types";
import { Pagination } from "../../../shared/pagination";
import { isDateInPastWeeks } from "../utils";

export const useGetAutomaticBillingsForPatient = (
  patientId: string,
  date: Date,
  isEnabled: boolean
) => {
  const query = useQuery<AutomaticBilling[]>(
    ["case-load-manager", "automatic-billings", patientId, date.toString()],
    async () => {
      const res = await axios.get(
        `automaticbilling/my-automatic-bills/patients/${patientId}?period=${date.toISOString()}`
      );
      return res.data;
    },
    {
      enabled: isEnabled,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useGetAllPaginatedAutomaticBillings = (
  date: Date,
  pageSize: number,
  skip: number,
  searchTerm: string,
  columnToSortOn: AutomaticBillingSortingColumns,
  orderByAsc: boolean
) => {
  const cacheInMinutes = 1000 * 60 * 0.5; // 0.5 min cache
  const isInPast = isDateInPastWeeks(date);

  const query = useQuery<{
    data: AutomaticBilling[];
    pagination: Pagination;
  }>(
    [
      "case-load-manager",
      "automatic-billings",
      "all",
      date.toString(),
      searchTerm,
      pageSize,
      skip,
      columnToSortOn,
      orderByAsc ? "asc" : "desc",
    ],
    async () => {
      const res = await axios.get(
        `automaticbilling/my-automatic-bills?period=${date.toISOString()}` +
          `&pageSize=${pageSize}` +
          `&skip=${skip}` +
          `&searchTerm=${searchTerm}` +
          `&orderBy=${columnToSortOn}` +
          `&orderByAsc=${orderByAsc}`
      );
      return res.data;
    },
    {
      cacheTime: isInPast ? undefined : cacheInMinutes,
      refetchInterval: isInPast ? undefined : cacheInMinutes, // we only want to refetch if the date is for the current week
    }
  );

  return query;
};
