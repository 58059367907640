import useStores from "../../../../../useStores";
import roleNames from "../../../../../utils/constants/roleNames";
import { Button } from "../../../../../components/ui/button";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../../../../components/ui/alert-dialog";
import { useState } from "react";
import { useRegenerateWorkingViewReport } from "../../hooks";
import { toast } from "sonner";

export const RegenerateButton = () => {
  const { authStore } = useStores();
  const isAdmin = authStore.roles.includes(roleNames.ADMINISTRATOR);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const { mutate, isLoading } = useRegenerateWorkingViewReport(() => {
    setIsConfirmDialogOpen(false);
    toast("Regenerated", {
      description: "The report has been successfully regenerated for today.",
    });
  });

  if (!isAdmin) return <></>;

  return (
    <>
      <AlertDialog
        open={isConfirmDialogOpen}
        onOpenChange={setIsConfirmDialogOpen}
      >
        <AlertDialogTrigger asChild>
          <Button variant="destructive">Refresh report</Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription className="space-y-2">
              <p>
                The system will re-generate today's values for the report. This
                might take a minute.
              </p>
              <p>If you are sure, please click the "Regenerate" button.</p>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <Button variant="destructive" onClick={async () => await mutate()}>
              {isLoading ? "Regenerating..." : "Regenerate"}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
