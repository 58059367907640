import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../components/ui/popover";
import { Calendar } from "../../../../components/ui/calendar";
import moment from "moment";
import { Skeleton } from "../../../../components/ui/skeleton";
import { Button } from "../../../../components/ui/button";
import { useGetMyCaseLoadWeekByWeekProgress } from "../hooks";
import { useTranslation } from "react-i18next";

export const WeekSelector = ({
  start,
  setStart,
  end,
  setEnd,
}: {
  start: Date | undefined;
  setStart: (newStart: Date) => void;
  end: Date | undefined;
  setEnd: (newEnd: Date) => void;
}) => {
  const { data, isLoading } = useGetMyCaseLoadWeekByWeekProgress(start, end);
  const { t } = useTranslation();

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-end space-x-2">
          <Skeleton className="h-6 w-24" />
          <Skeleton className="h-10 w-28" />
          <Skeleton className="h-10 w-28" />
        </div>
      ) : (
        <div className="flex items-center space-x-2">
          <p className="font-medium pr-2">
            {t("case-load-manager-statistics-week-selector-label")}
          </p>

          <Popover>
            <PopoverTrigger asChild>
              <Button className="px-4" variant="outline">
                {t("case-load-manager-statistics-week-selector-start-week")} (
                {data![0].week})
              </Button>
            </PopoverTrigger>
            <PopoverContent align="center" side="left" className="min-w-fit">
              <Calendar
                mode="single"
                showWeekNumber
                toDate={end ? end : new Date()}
                selected={start}
                onSelect={(_, selectedDay) => {
                  const parsedSelectedDate = moment(selectedDay)
                    .utc(true)
                    .toDate();

                  // make sure we don't set the start date after (greater than) the end date
                  if (end && parsedSelectedDate > end) {
                    setEnd(parsedSelectedDate);
                  }

                  setStart(parsedSelectedDate);
                }}
                disabled={(d) => {
                  // mondays are OK to select
                  if (d.getDay() == 1) return false;

                  // rest is disabled
                  return true;
                }}
              />
            </PopoverContent>
          </Popover>

          <Popover>
            <PopoverTrigger asChild>
              <Button className="px-4" variant="outline">
                {t("case-load-manager-statistics-week-selector-end-week")} (
                {data![data!.length - 1].week})
              </Button>
            </PopoverTrigger>
            <PopoverContent align="center" side="left" className="min-w-fit">
              <Calendar
                mode="single"
                showWeekNumber
                fromDate={start ? start : new Date()}
                selected={end}
                onSelect={(_, selectedDay) => {
                  const parsedSelectedDate = moment(selectedDay)
                    .utc(true)
                    .toDate();

                  // make sure we don't set the end date before (less than) the start date
                  if (start && parsedSelectedDate < start) {
                    setStart(parsedSelectedDate);
                  }

                  setEnd(parsedSelectedDate);
                }}
                disabled={(d) => {
                  // mondays are OK to select
                  if (d.getDay() == 1) return false;

                  // rest is disabled
                  return true;
                }}
              />
            </PopoverContent>
          </Popover>
        </div>
      )}
    </>
  );
};
