import { getSimpleFormattedDateTime } from "../utils";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { Loading } from "../tableStates/loading";
import { OnboardPatientMainListEntry } from "./types";
import { KopStateColumn } from "./columns/kopStateColumn";
import { WelcomeMessageColumn } from "./columns/welcomeMessageColumn";
import { TreatmentStartStandardMessageColumn } from "./columns/treatmentStartMessageColumn";
import { Sheet, SheetClose, SheetContent } from "../../../components/ui/sheet";
import { useState } from "react";
import { OnboardingPatientDetail } from "./details/details";
import { useGetOnboardingPatients } from "./hooks";
import { useTranslation } from "react-i18next";

export const OnboardPatients = () => {
  const { t } = useTranslation();

  const [selectedPatientEntry, setSelectedPatientEntry] =
    useState<OnboardPatientMainListEntry | null>(null);

  const { data, isLoading } = useGetOnboardingPatients();

  const onRowClick = (patient: OnboardPatientMainListEntry) => {
    setSelectedPatientEntry(patient);
  };

  const onCloseSheet = () => {
    setSelectedPatientEntry(null);
  };

  return (
    <main>
      <Sheet
        open={selectedPatientEntry != null}
        onOpenChange={(newValue) => {
          if (!newValue) {
            onCloseSheet();
          }
        }}
      >
        <Table className="border rounded">
          <TableHeader>
            <TableRow>
              <TableHead>
                {t("case-load-manager-onboarding-col-patient")}
              </TableHead>
              <TableHead>
                {t("case-load-manager-onboarding-col-pre-intake")}
              </TableHead>
              <TableHead>
                {t("case-load-manager-onboarding-col-intake")}
              </TableHead>
              <TableHead>
                {t("case-load-manager-onboarding-col-kop-model")}
              </TableHead>
              <TableHead>
                {t("case-load-manager-onboarding-col-welcome-message")}
              </TableHead>
              <TableHead>
                {t(
                  "case-load-manager-onboarding-col-treatment-start-standard-message"
                )}
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={6}>
                  <div className="w-full flex items-center justify-center space-x-1">
                    <Loading />
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {data?.data?.length == 0 ? (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <div className="w-full flex items-center justify-center space-x-1">
                        <p>No patients found to onboard.</p>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  data?.data?.map((d) => {
                    return (
                      <TableRow
                        key={d.id}
                        className="cursor-pointer"
                        onMouseDown={() => onRowClick(d)}
                      >
                        <TableCell>
                          <p className="font-bold">
                            {d.epdPatientId}.{d.epdFileId} {d.name}
                          </p>
                        </TableCell>
                        <TableCell>
                          {getSimpleFormattedDateTime(d.preIntakeOn)}
                        </TableCell>
                        <TableCell>
                          {d.intakeOn && getSimpleFormattedDateTime(d.intakeOn)}
                        </TableCell>
                        <TableCell>
                          <KopStateColumn patient={d} />
                        </TableCell>
                        <TableCell>
                          <WelcomeMessageColumn patient={d} />
                        </TableCell>
                        <TableCell>
                          <TreatmentStartStandardMessageColumn patient={d} />
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </>
            )}
          </TableBody>
        </Table>

        <SheetContent
          side={"right"}
          onInteractOutside={() => onCloseSheet()}
          className="min-w-[100vw] xl:min-w-[50vw] overflow-y-auto overflow-x-hidden p-8 flex items-start justify-center bg-white"
        >
          {selectedPatientEntry && (
            <OnboardingPatientDetail id={selectedPatientEntry.id} />
          )}
        </SheetContent>
        <SheetClose onClick={() => onCloseSheet()} />
      </Sheet>
    </main>
  );
};
