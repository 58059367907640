import { ColumnDef } from "@tanstack/react-table";
import { MainListPatient } from "../../../types";
import { Button } from "../../../../../components/ui/button";
import { ChevronsRight, Eye } from "lucide-react";
import { CaseLoadManagerSelectedPatientContext } from "../../../caseLoadManagerSelectedPatientContext";
import { useContext } from "react";

export const OpenDetailsSheetColName = "OpenDetailsSheet";

export const OpenDetailsSheetCol: ColumnDef<MainListPatient> = {
  id: OpenDetailsSheetColName,
  enableResizing: false,
  size: 30,
  minSize: 30,
  maxSize: 30,
  header: () => {
    return <></>;
  },
  cell: ({ row }) => {
    const { setSelectedPatientEntry } = useContext(
      CaseLoadManagerSelectedPatientContext
    )!;

    return (
      <Button
        variant="outline"
        onClick={() => setSelectedPatientEntry(row.original)}
      >
        <div className="h-4 w-4">
          <ChevronsRight className="h-4 w-4" />
        </div>
      </Button>
    );
  },
};
