import { ColumnDef } from "@tanstack/react-table";
import { MainListPatient } from "../../../types";
import { CheckCircle2, XCircle } from "lucide-react";
import { isPeriodInPastWeeks } from "../../../utils";
import { useTranslation } from "react-i18next";

export const IsPatientContractedColName = "Contracted";

export const IsPatientContractedCol: ColumnDef<MainListPatient> = {
  id: IsPatientContractedColName,
  accessorKey: "isPatientContracted",
  size: 100,
  minSize: 80,
  header: () => {
    const { t } = useTranslation();

    return (
      <p className="text-center">
        {t("case-load-manager-column-is-contracted")}
      </p>
    );
  },
  cell: ({ getValue, row }) => {
    const isContracted = getValue() == true;
    const isInPast = isPeriodInPastWeeks(row.original.period);

    return (
      <div className='flex justify-center ${isInPast && "opacity-50'>
        {isContracted ? (
          <CheckCircle2 className="h-5 w-5 text-green-600" />
        ) : (
          <XCircle className="h-5 w-5 text-red-600" />
        )}
      </div>
    );
  },
};
