import { CircleCheckBig, HelpCircle } from "lucide-react";
import { ReferralDetails } from "../../types";
import { RegisterPatientButton } from "./registerPatientButton";
import { Separator } from "../../../../components/ui/separator";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../../components/ui/tooltip";

const title = "Register patient";

export const RegisterPatientRow = ({
  referral,
  stepNumber,
}: {
  referral: ReferralDetails;
  stepNumber: number;
}) => {
  // cannot modify, we're done
  if (
    referral.status === "AwaitingIWelcomeEmailSend" ||
    referral.status === "Closed"
  ) {
    return <ReadOnlyRegisterPatientRow stepNumber={stepNumber} />;
  }

  if (referral.status === "AwaitingPatientApproval") {
    return <PatientRegistrationPendingApproval stepNumber={stepNumber} />;
  }

  // awaiting intake schedule
  if (!referral.intakeOn || !referral.preIntakeOn) {
    return <AwaitingIntakeScheduleRegisterPatientRow stepNumber={stepNumber} />;
  }

  return (
    <div>
      <div className="w-full flex items-center justify-between">
        <p className="text-lg font-medium leading-none">
          {stepNumber}. {title}
        </p>
        <div className="flex items-center justify-between w-fit">
          <TooltipProvider delayDuration={100}>
            <Tooltip>
              <TooltipTrigger>
                <HelpCircle className="h-4 w-4" />
              </TooltipTrigger>
              <TooltipContent
                style={{ zIndex: 99999 }}
                className="max-w-[24rem]"
              >
                <p>
                  After an intake is scheduled with a psychologist, you will be
                  able to register the patient in Praktijkdata. A manual
                  approval in Praktijkdata will be necessary afterwards.
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
      <Separator className="my-1" />

      <div className="w-full flex items-center space-x-2 text-xs pt-1">
        <RegisterPatientButton referral={referral} enabled variant="default" />
      </div>
    </div>
  );
};

const ReadOnlyRegisterPatientRow = ({ stepNumber }: { stepNumber: number }) => {
  return (
    <div>
      <p className="text-lg font-medium leading-none">
        {stepNumber}. {title}
      </p>
      <Separator className="my-1" />

      <div className="flex items-center space-x-2 opacity-70 text-xs">
        <div className="h-4 w-4">
          <CircleCheckBig className="h-4 w-4" />
        </div>
        <p>Patient is successfully registered.</p>
      </div>
    </div>
  );
};

const AwaitingIntakeScheduleRegisterPatientRow = ({
  stepNumber,
}: {
  stepNumber: number;
}) => {
  return (
    <div>
      <div className="w-full flex items-center justify-between">
        <p className="text-lg font-medium leading-none">
          {stepNumber}. {title}
        </p>
      </div>
      <Separator className="my-1" />

      <p className="opacity-70">
        Please complete the previous steps, before registering the patient.
      </p>
    </div>
  );
};

const PatientRegistrationPendingApproval = ({
  stepNumber,
}: {
  stepNumber: number;
}) => {
  return (
    <div>
      <div className="w-full flex items-center justify-between">
        <p className="text-lg font-medium leading-none">
          {stepNumber}. {title}
        </p>
      </div>
      <Separator className="my-1" />

      <div className="flex items-center space-x-2 opacity-70 text-xs">
        <div className="h-4 w-4">
          <CircleCheckBig className="h-4 w-4" />
        </div>
        <p>The registration has been sent to Praktijkdata.</p>
      </div>
    </div>
  );
};
