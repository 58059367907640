import axios from "axios";
import { UseQueryOptions, useQuery } from "react-query";

export const useAreEnabled = (
  featureFlags: string[],
  options?: Omit<
    UseQueryOptions<
      unknown,
      unknown,
      { featureFlag: string; isEnabled: boolean }[],
      string[]
    >,
    "queryKey" | "queryFn"
  >
) => {
  const query = useQuery(
    ["feature-management", ...featureFlags],
    async () => {
      let url = new URL(
        `${window.location.protocol}/${window.location.host}/api/feature-management`
      );

      featureFlags.forEach((ff) => {
        url.searchParams.append("featureFlags", ff);
      });

      const res = await axios.get(url.toString());
      return res.data.results;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      ...options,
    }
  );

  return query;
};
