import { Button } from "../../../components/ui/button";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Ban, CircleCheckBig } from "lucide-react";
import { CreateOffboarding } from "./createOffboarding";
import { UndoMarkAsOffboarding } from "./undoMarkAsOffboarding";
import { UpdateOffboardingPatient } from "./updateDialog";
import { MainListPatient } from "../types";

export const OffboardingButton = ({
  data,
  showLabel,
}: {
  data: MainListPatient;
  showLabel: boolean;
}) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const numberOfRegularConsults = data?.numberOfRegularConsults;
  const isMarkedAsOffboarding = data?.offboardingData !== null;
  const hasAnythingBeenProcessed =
    data?.offboardingData?.hasSentLastWireMessageToPatient ||
    data?.offboardingData?.hasSentTreatmentSummaryToPraktijkData ||
    data?.offboardingData?.hasSentWireConversationExportToPraktijkdata;

  if (!data) return <></>;

  if (hasAnythingBeenProcessed) {
    return (
      <div>
        <p className="font-bold">
          {t("case-load-manager-offboarding-main-list-col-feedback-title")}
        </p>
        <div className="">
          <div className="space-y-1 mt-2">
            <OffboardingStepStatus
              label={t(
                "case-load-manager-offboarding-main-list-col-feedback-treatment-plan-to-pd"
              )}
              isSuccess={
                data.offboardingData!.hasSentTreatmentSummaryToPraktijkData
              }
            />
            <OffboardingStepStatus
              label={t(
                "case-load-manager-offboarding-main-list-col-feedback-wire-message"
              )}
              isSuccess={data.offboardingData!.hasSentLastWireMessageToPatient}
            />
            <OffboardingStepStatus
              label={t(
                "case-load-manager-offboarding-main-list-col-feedback-wire-export"
              )}
              isSuccess={
                data.offboardingData!
                  .hasSentWireConversationExportToPraktijkdata
              }
            />
          </div>
        </div>
      </div>
    );
  }

  if (isMarkedAsOffboarding) {
    return (
      <div className="flex flex-col">
        <div className="p-2 rounded-lg bg-green-100 text-green-600 inline-block">
          <p>{t("case-load-manager-offboarding-next-consult-is-last")}</p>
        </div>
        <UpdateOffboardingPatient
          id={data.offboardingData!.id}
          originalTreatmentSummary={data.offboardingData!.treatmentSummary}
          originalSendRequestToGoogleReviewToPatient={
            data.offboardingData!.sendRequestToGoogleReviewToPatient
          }
        />
        <UndoMarkAsOffboarding id={data.offboardingData!.id} />
      </div>
    );
  }

  if (!data.isSoonToBeDischarged) {
    return <></>;
  }

  return (
    <div>
      <p className={showLabel ? "block" : "hidden"}>
        {t("case-load-manager-offboarding-main-button-caption", {
          count: numberOfRegularConsults,
        })}
      </p>
      <Button
        onClick={() => {
          setIsOpen(true);
        }}
        className="mt-1"
      >
        {t("case-load-manager-offboarding-main-button-label")}
      </Button>

      <CreateOffboarding
        currentWeekCaseLoadEntryId={data.id}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </div>
  );
};

const OffboardingStepStatus = ({
  label,
  isSuccess,
}: {
  label: string;
  isSuccess: boolean;
}) => {
  if (isSuccess) {
    return (
      <div className="flex items-center space-x-2 text-xs">
        <div className="w-3 h-3">
          <CircleCheckBig className="w-3 h-3" />
        </div>
        <p>{label}</p>
      </div>
    );
  } else {
    return (
      <div className="flex items-center space-x-2 text-xs text-red-600">
        <div className="w-3 h-3">
          <Ban className="w-3 h-3" />
        </div>
        <p>{label}</p>
      </div>
    );
  }
};
