import { ColumnDef } from "@tanstack/react-table";
import { statuses } from "../../../constants";
import { ReferredPatient } from "../../../types";
import { MessageCircleQuestion } from "lucide-react";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../../../../../components/ui/hover-card";
import { getRejectionReason } from "../../../referralDetails/reject/utils";

const getTextColorClass = (
  color: "" | "red" | "blue" | "purple" | "pink" | "rose" | "green"
) => {
  switch (color) {
    case "red":
      return "text-red-500 dark:text-red-400";
    case "blue":
      return "text-blue-500 dark:text-blue-400";
    case "purple":
      return "text-purple-500 dark:text-purple-400";
    case "pink":
      return "text-pink-500 dark:text-pink-400";
    case "rose":
      return "text-rose-500 dark:text-rose-400";
    case "green":
      return "text-green-600 dark:text-green-400";
    default:
      return "";
  }
};

const getBackgroundColorClass = (
  color: "" | "red" | "blue" | "purple" | "pink" | "rose" | "green"
) => {
  switch (color) {
    case "red":
      return "bg-red-100 dark:bg-red-950";
    case "blue":
      return "bg-blue-200 dark:bg-blue-950";
    case "purple":
      return "bg-purple-100 dark:bg-purple-950";
    case "pink":
      return "bg-pink-100 dark:bg-pink-950";
    case "rose":
      return "bg-rose-100 dark:bg-rose-950";
    case "green":
      return "bg-green-100 dark:bg-green-950";
    default:
      return "bg-slate-200 dark:bg-slate-900";
  }
};

export const StatusColName = "Status";

export const StatusCol: ColumnDef<ReferredPatient> = {
  id: StatusColName,
  accessorKey: "status",
  size: 200,
  minSize: 10,
  header: ({ column }) => {
    return <p>Status</p>;
  },
  cell: ({ row }) => {
    const status = statuses.find(
      (status) => status.value === row.original.status
    );

    if (!status) {
      return null;
    }

    const textColorClass = getTextColorClass(status.color);
    const backgroundColorClass = getBackgroundColorClass(status.color);

    return (
      <div
        className={`inline-flex items-center space-x-1 rounded-full text-xs py-1 px-2 ${backgroundColorClass} ${textColorClass}`}
      >
        <div className="h-4 w-4">
          {status.icon && (
            <status.icon className={`mr-2 h-4 w-4 ${textColorClass}`} />
          )}
        </div>
        <span className="font-medium">{status.label}</span>

        {status.value === "Rejected" && (
          <HoverCard openDelay={200}>
            <HoverCardTrigger asChild>
              <div className="pb-1 h-4 w-4 cursor-help">
                <MessageCircleQuestion className="h-4 w-4 opacity-50 hover:opacity-100" />
              </div>
            </HoverCardTrigger>
            <HoverCardContent
              className="w-[24rem] space-y-2"
              style={{ zIndex: 99999 }}
              side="right"
              align="end"
            >
              <p>
                Rejected by{" "}
                {row.original.rejectedByClient ? "client" : "iPractice"}.
                Reason:{" "}
                {getRejectionReason(
                  row.original.status,
                  row.original.rejectedByClient,
                  row.original.rejectionReasonClient,
                  row.original.rejectionReasonIPractice,
                  row.original.justificationForOtherRejectionReason
                )}
              </p>
            </HoverCardContent>
          </HoverCard>
        )}
      </div>
    );
  },
  filterFn: (row, id, value) => {
    return value.includes(row.getValue(id));
  },
};
