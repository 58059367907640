import {
  ArrowDownIcon,
  CheckCircledIcon,
  CrossCircledIcon,
} from "@radix-ui/react-icons";
import { ArchiveIcon, ArrowRightIcon, ArrowUpIcon, CircleIcon, Clock } from "lucide-react";
import { DutchFlag, GbFlag } from "../../shared/Flags";
import { Language, PatientRelevance } from "./types";

export const statuses: {
  value: string;
  label: string;
  icon: any;
  color: "" | "red" | "blue" | "purple" | "pink" | "rose" | "green" | "grey";
}[] = [
  {
    value: "New",
    label: "New",
    icon: CircleIcon,
    color: "",
  },
  {
    value: "Open",
    label: "Open",
    icon: CircleIcon,
    color: "",
  },
  {
    value: "ReachOut",
    label: "Reach Out",
    icon: Clock,
    color: "blue",
  },
  {
    value: "RegisterPatientAndIntake",
    label: "Register patient & schedule intake",
    icon: Clock,
    color: "purple",
  },
  {
    value: "AwaitingPatientApproval",
    label: "Awaiting patient approval",
    icon: Clock,
    color: "pink",
  },
  {
    value: "AwaitingIWelcomeEmailSend",
    label: "iWelcome email to be sent",
    icon: Clock,
    color: "rose",
  },
  {
    value: "Closed",
    label: "Closed",
    icon: CheckCircledIcon,
    color: "green",
  },
  {
    value: "Rejected",
    label: "Rejected",
    icon: CrossCircledIcon,
    color: "red",
  },
  {
    value: "Cancelled",
    label: "Cancelled",
    icon: CrossCircledIcon,
    color: "red",
  },
  {
    value: "Archived",
    label: "Archived",
    icon: ArchiveIcon,
    color: "grey",
  },
];

export const relevances: {
  label: string;
  value: PatientRelevance;
  icon?: any;
}[] = [
  {
    label: "Unspecified",
    value: "Unspecified",
  },
  {
    label: "Low",
    value: "Low",
    icon: ArrowDownIcon,
  },
  {
    label: "Medium",
    value: "Medium",
    icon: ArrowRightIcon,
  },
  {
    label: "High",
    value: "High",
    icon: ArrowUpIcon,
  },
];

export const howDidYouHearAboutUsAnswers = [
  "ZorgDomein",
  "PD mail",
  "OpenUp",
  "Heraanemlding",
  "Gemeente Utrecht",
  "Inkomend",
  "Particulier",
  "Transfer",
  "Lentis Groningen",
  "CZ ADHD zorgbemiddeling",
  "Verwijzing na Transfer screening",
  "KNVB",
  "Verwijzing na ADHD screening",
  "VGZ ADHD screening",
  "Post",
];



export const languages: Language[] = [
  { code: "nl-NL", flag: <DutchFlag /> },
  { code: "en-US", flag: <GbFlag /> },
];

export const genders: string[] = [
  "Male",
  "Female",
  "Other",
];

export const countries: string[] = ["NL", "DE", "GB", "BE", "FR", "ES"];
