import locations from "./locationEnum";
import statusEnum from "./statusEnum";
import languageEnum from "./languageEnum";
import genderEnum from "./genderEnum";
import teamEnum from "./teamEnum";
import emdrEnum from "./emdrEnum";
import experienceEnum from "./experienceEnum";
import schemaTherapyEnum from "./schemaTherapyEnum";
const tags = {
  STATUS: [
    statusEnum.ACTIVE_ACCEPTING_CLIENTS,
    statusEnum.ACTIVE_NOT_ACCEPTING_CLIENTS,
    statusEnum.INACTIVE,
    statusEnum.ARCHIVED,
  ],
  LANGUAGE: [languageEnum.EN, languageEnum.NL],
  LOCATION: [
    locations.ONLINE,
    locations.AMSTERDAM,
    locations.UTRECHT,
    locations.ROTTERDAM,
    locations.GRONINGEN,
    locations.EINDHOVEN,
    locations.NIJMEGEN,
    locations.DEN_HAAG,
    locations.ALKMAAR,
  ],
  TEAM: [
    teamEnum.team_1,
    teamEnum.team_2,
    teamEnum.team_3,
    teamEnum.team_4,
    teamEnum.team_5,
    teamEnum.team_6,
    teamEnum.team_7,
    teamEnum.team_8,
    teamEnum.team_9,
    teamEnum.team_10,
    teamEnum.team_11,
    teamEnum.team_12,
    teamEnum.team_13,
    teamEnum.team_14,
    teamEnum.team_15,
  ],
  GENDER: [genderEnum.WOMAN, genderEnum.MAN],
  EMDR: [emdrEnum.YES, emdrEnum.NO],
  GZ: [experienceEnum.BASIS, experienceEnum.GZ],
  SchemaTherapy: [schemaTherapyEnum.YES, schemaTherapyEnum.NO],
};

export default tags;
