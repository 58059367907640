import { EllipsisVertical, Loader2, Trash2, X } from "lucide-react";
import { useGetAssignedPatient } from "../../hooks";
import { DossierLinks } from "./dossierLinks";
import { RemovePatientButton } from "./removePatientButton";
import { Remarks } from "./remarks";
import { getSimpleFormattedDateTime } from "../../../utils";
import { OffboardingButton } from "../../../offboardingPatients/offBoardingButton";
import { NeedsCheckinCheckbox } from "../../checkin/needsCheckinCheckbox";
import { SupportCheckboxes } from "../../supportPatient/supportCheckboxes";
import { YourComments } from "../../psychologistComments/yourComments";
import { useTranslation } from "react-i18next";
import { AlertIndicator } from "../../alerts/alertIndicator";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../../components/ui/popover";

export const Header = ({
  clientId,
  onClose,
}: {
  clientId: string;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { data, isFetching } = useGetAssignedPatient(clientId);

  if (!data) return <></>;

  const renderRegularConsultPsychologists = () => {
    if (data.nameOfConsultationRoomPsychologists.length === 0)
      return <p className="opacity-30">N/A</p>;

    return (
      <ul className="list-inside list-disc">
        {data.nameOfConsultationRoomPsychologists.map((psychologist) => (
          <li key={psychologist} className="text-xs">
            {psychologist}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <main>
      <div>
        <div className="mb-4 pb-2 border-b flex items-center justify-between w-full">
          <div className="flex items-center space-x-4">
            <p className="text-lg">
              {data.epdPatientId}.{data.epdFileId}
            </p>
            <DossierLinks clientId={clientId} />
          </div>
          <section className="flex items-center space-x-4">
            <div className="w-5 h-5" onClick={() => onClose()}>
              <X className="w-5 h-5 cursor-pointer opacity-75 hover:opacity-100" />
            </div>
          </section>
        </div>
        <div className="w-full flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <h1 className="text-3xl font-bold">{data.name}</h1>
            <Popover>
              <PopoverTrigger style={{ zIndex: 99999 }} asChild>
                <div className="w-4 h-4 cursor-pointer">
                  <EllipsisVertical className="w-4 h-4" />
                </div>
              </PopoverTrigger>
              <PopoverContent
                style={{ zIndex: 99999 }}
                className="min-w-fit"
                align="start"
                side="bottom"
              >
                <RemovePatientButton clientId={clientId} onRemoval={onClose} />
              </PopoverContent>
            </Popover>
          </div>
          {isFetching && (
            <Loader2 className="w-4 h-4 opacity-70 animate-spin" />
          )}
        </div>
        <Remarks clientId={clientId} />
      </div>

      <div className="flex items-start justify-between space-x-6 py-4">
        <section className="w-1/3 space-y-4">
          <div className="p-3 border rounded-lg space-y-3">
            <NeedsCheckinCheckbox data={data} onBlur={() => {}} />
            <SupportCheckboxes data={data} onBlur={() => {}} />
          </div>

          {data.alerts.filter((x) => !x.hasMarkedAsCompleted).length > 0 ? (
            <div className="mt-2 p-2 border border-dashed rounded-lg border-red-400 text-red-600 bg-red-50 flex items-start space-x-2">
              <AlertIndicator data={data} showLabel />
            </div>
          ) : (
            <></>
          )}
        </section>

        <section className="w-1/3 space-y-1">
          <div className="flex items-center space-x-2">
            <p className="font-bold">Wire handle:</p>
            <p className="">{data.wireUserName ?? "-"}</p>
          </div>

          <div className="pt-2 flex items-center space-x-2">
            <p className="font-bold">
              {t("case-load-manager-column-last-chat-consult")}:
            </p>
            <p className="">
              {data.lastRegularConsultOn
                ? getSimpleFormattedDateTime(
                    data.lastRegularConsultOn.toString()
                  )
                : "-"}
            </p>
          </div>

          <div className="flex items-center space-x-2">
            <p className="font-bold">
              {t("case-load-manager-column-next-regular-consult")}:
            </p>
            <p className="">
              {data.nextRegularConsultOn
                ? getSimpleFormattedDateTime(
                    data.nextRegularConsultOn.toString()
                  )
                : "-"}
            </p>
          </div>
          <div className="flex items-center space-x-2">
            <p className="font-bold">
              {t("case-load-manager-column-number-of-regular-consults")}:
            </p>
            <p className="">{data.numberOfRegularConsults}</p>
          </div>

          <div className="flex flex-col items-start">
            <p className="font-bold">
              {t("case-load-manager-column-regular-consult-psychologists")}:
            </p>
            <p className="">{renderRegularConsultPsychologists()}</p>
          </div>

          <div className="pt-2 flex flex-col">
            <OffboardingButton data={data} showLabel={false} />
          </div>
        </section>

        <section className="w-1/3">
          <h2 className="font-bold pb-2">
            {t("case-load-manager-column-your-comments")}
          </h2>
          <YourComments data={data} />
        </section>
      </div>
    </main>
  );
};
