import { CircleCheckBig, CircleSlash } from "lucide-react";
import { OnboardPatientDetails } from "../../types";
import { useTranslation } from "react-i18next";
import { SendTreatmentStartMessage } from "./sendTreatmentStartMessage";

export const TreatmentStartMessageStep = ({
  onboardingPatient,
}: {
  onboardingPatient: OnboardPatientDetails;
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={`p-2 border rounded-lg ${
        onboardingPatient.treatmentStartStandardMessageSent
          ? "bg-green-100 text-green-600"
          : "bg-slate-50"
      }`}
    >
      <div className="flex items-center space-x-2">
        <div className="h-4 w-4">
          {onboardingPatient.treatmentStartStandardMessageSent ? (
            <CircleCheckBig className="h-4 w-4" />
          ) : (
            <CircleSlash className="h-4 w-4" />
          )}
        </div>
        <p className="font-bold">
          {t("case-load-manager-onboarding-details-treatment-start-caption")}
        </p>
      </div>
      <p className="pl-6">
        {onboardingPatient.treatmentStartStandardMessageSent
          ? t("case-load-manager-onboarding-details-treatment-start-value-sent")
          : t(
              "case-load-manager-onboarding-details-treatment-start-value-not-yet-sent"
            )}
      </p>

      <SendTreatmentStartMessage onboardingPatient={onboardingPatient} />
    </div>
  );
};
