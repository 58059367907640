import { useUpdateKopModel } from "../../hooks";
import { toast } from "sonner";
import { Save } from "lucide-react";
import { OnboardPatientDetails } from "../../types";
import { Button } from "../../../../../components/ui/button";
import { useTranslation } from "react-i18next";

export const SaveDraftKopModelButton = ({
  isDisabled,
  onboardingPatient,
  complaints,
  circumstances,
  personalStyle,
  requestForHelp,
  questionsForPreIntake,
  myCommentsForDiagnosisAndTreatment,
}: {
  isDisabled: boolean;
  onboardingPatient: OnboardPatientDetails;
  complaints: string;
  circumstances: string;
  personalStyle: string;
  requestForHelp: string;
  questionsForPreIntake: string;
  myCommentsForDiagnosisAndTreatment: string;
}) => {
  const { t } = useTranslation();

  const { mutate: updateKopModel, isLoading: isSavingKopModel } =
    useUpdateKopModel(() => {
      toast("KOP-model saved", {
        description: "Your changes have been saved.",
      });
    });

  return (
    <Button
      variant="outline"
      className="flex items-center space-x-2 px-6"
      disabled={isDisabled || isSavingKopModel}
      onClick={async () =>
        await updateKopModel({
          id: onboardingPatient.id,
          complaints,
          circumstances,
          personalStyle,
          requestForHelp,
          questionsForPreIntake,
          myCommentsForDiagnosisAndTreatment,
        })
      }
    >
      <div className="h-4 w-4">
        <Save className="h-4 w-4" />
      </div>
      <p>
        {isSavingKopModel
          ? t("case-load-manager-onboarding-kop-model-saving-button")
          : t("case-load-manager-onboarding-kop-model-save-button")}
      </p>
    </Button>
  );
};
