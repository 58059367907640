import { ReplySuggestions } from "./suggestions/reply-suggestions";
import { ResourceSuggestions } from "./suggestions/resource-suggestions";
import { ReactNode } from "react";
import { BaseMessage, ResourceSuggestion } from "./types";
import {
  useReplySuggestions,
  useResourceSuggestions,
} from "./suggestions/use-suggestions";

export const REPLY_SUGGESTIONS_PROMPT = `\
  You are an assistant to a therapist. Your job is to assist the therapist by suggesting good follow-up questions and replies based on the conversation between the therapist and the user. 

  Given the following chat conversation and the last message sent by the user, suggest 3 pertinent follow-up messages or questions that are relevant to this conversation. 

  Important: you must respond in the same language that is used in the conversation. 
  
  # Examples 
  ### Conversation
  Therapist: Hello. How may I help you?
  User: Hi again
  Therapist: Do you want to talk about how you're currently feeling?
  User: Yes. I'm having trouble sleping
  User: Sleeping

  ### Output
  You must reply in JSON format. Use the following schema to structure your response:
  
  \`\`\`json
  {
    "replySuggestions": [
      "I'm sorry to hear that. Can you tell me more about how you're not sleeping well?",
      "It sounds like you're having a hard time. What do you think is causing your sleep problems?",
      "How long have you been experiencing trouble with sleep?"
    ]
  }\`\`\`
`;

export function AiRecommendations({
  onReplySuggestionSelected,
  onResourceSuggestionSelected,
}: {
  onReplySuggestionSelected: (suggestion: string) => void;
  onResourceSuggestionSelected: (resource: ResourceSuggestion) => void;
}) {
  const { data: replySuggestions, isLoading: isLoadingReplySuggestions } =
    useReplySuggestions(
      {
        messages: [],
        userId: "patient2",
      },
      REPLY_SUGGESTIONS_PROMPT
    );

  const { data: resourceSuggestions, isLoading: isLoadingResourceSuggestions } =
    useResourceSuggestions({
      messages: [],
      userId: "patient2",
    });

  return (
    <main>
      {/* <section className="flex justify-between">
        <div className="ml-auto flex gap-3">
          <button
            className="rounded-xl border border-transparent px-2 py-1 text-sm hover:border-blue-100"
            // onClick={refetchSuggestions}
          >
            <IconChartTimelineShimmer />
          </button>
          {headerSlot && headerSlot}
        </div>
      </section> */}

      <section className="relative flex flex-grow flex-col overflow-x-hidden">
        <ReplySuggestions
          loadingReplySuggestions={isLoadingReplySuggestions}
          onSuggestedReplyClick={(value) => onReplySuggestionSelected(value)}
          replySuggestions={replySuggestions ?? []}
        />

        <hr className="border-slate-100 my-10 w-full h-px border" />

        <ResourceSuggestions
          loadingResourceSuggestions={isLoadingResourceSuggestions}
          onSuggestedResourceClick={(resource: ResourceSuggestion) =>
            onResourceSuggestionSelected(resource)
          }
          resourceSuggestions={resourceSuggestions ?? []}
        />
      </section>
      <p></p>
    </main>
  );
}

function IconChartTimelineShimmer({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4997 6.66667C16.2497 6.66667 15.583 7.83333 15.9163 8.75L12.9163 11.75C12.6663 11.6667 12.333 11.6667 12.083 11.75L9.91634 9.58333C10.2497 8.66667 9.58301 7.5 8.33301 7.5C7.16634 7.5 6.41634 8.66667 6.74968 9.58333L2.91634 13.3333C1.99967 13.0833 0.833008 13.75 0.833008 15C0.833008 15.9167 1.58301 16.6667 2.49967 16.6667C3.66634 16.6667 4.41634 15.5 4.08301 14.5833L7.83301 10.75C8.08301 10.8333 8.41634 10.8333 8.66634 10.75L10.833 12.9167C10.583 13.75 11.2497 15 12.4997 15C13.7497 15 14.4163 13.8333 14.083 12.9167L17.083 9.91667C17.9997 10.1667 19.1663 9.5 19.1663 8.33333C19.1663 7.41667 18.4163 6.66667 17.4997 6.66667ZM12.4997 7.5L13.2497 5.75L14.9997 5L13.2497 4.25L12.4997 2.5L11.7497 4.25L9.99967 5L11.7497 5.75L12.4997 7.5ZM2.91634 9.16667L3.33301 7.5L4.99967 7.08333L3.33301 6.66667L2.91634 5L2.49967 6.66667L0.833008 7.08333L2.49967 7.5L2.91634 9.16667Z"
        fill="currentColor"
      />
    </svg>
  );
}
