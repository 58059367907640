import axios from "axios";
import { QueryClient, useMutation, useQuery } from "react-query";
import {
  CaseLoadMainListMetadata,
  AutomaticBilling,
  AutomaticBillingSortingColumns,
  CaseLoadSortingColumns,
  MainListPatient,
  SelectablePatient,
  PatientDetails,
} from "../types";
import { queryClient } from "../../../react-query/provider";
import { Pagination } from "../../../shared/pagination";
import { isDateInPastWeeks } from "./../utils";
import moment from "moment";

export const refetchCaseLoadEntries = () => {
  [
    ["case-load-manager", "my-case-load"],
    ["case-load-manager", "my-progress"],
  ].map((qk) => {
    queryClient.invalidateQueries({
      queryKey: qk,
    });
    queryClient.refetchQueries({
      queryKey: qk,
    });
  });
};

export const useGetAssignedPatients = (
  date: Date,
  pageSize: number,
  skip: number,
  showOnlyEntriesWithAlerts: boolean,
  excludeNoCheckinNeededPatients: boolean,
  excludeAlreadySupportedPatients: boolean,
  showOnlySoonOffboardingPatients: boolean,
  searchTerm: string,
  columnToSortOn: CaseLoadSortingColumns,
  orderByAsc: boolean,
  onSuccess: (data: {
    data: MainListPatient[];
    metaData: CaseLoadMainListMetadata;
    pagination: Pagination;
  }) => void
) => {
  const query = useQuery<{
    data: MainListPatient[];
    metaData: CaseLoadMainListMetadata;
    pagination: Pagination;
  }>(
    [
      "case-load-manager",
      "my-case-load",
      date.toString(),
      pageSize,
      skip,
      showOnlyEntriesWithAlerts,
      excludeNoCheckinNeededPatients,
      excludeAlreadySupportedPatients,
      showOnlySoonOffboardingPatients,
      searchTerm,
      columnToSortOn,
      orderByAsc,
    ],
    async () => {
      const res = await axios.get(
        `caseloadmanager/my-case-load?` +
          `period=${date.toISOString()}` +
          `&pageSize=${pageSize}` +
          `&skip=${skip}` +
          `&showOnlyActiveCaseLoad=false` + // temporary ignore ${showOnlyActiveCaseLoad}
          `&showOnlyEntriesWithAlerts=${showOnlyEntriesWithAlerts}` +
          `&excludeNoCheckinNeededPatients=${excludeNoCheckinNeededPatients}` +
          `&excludeAlreadySupportedPatients=${excludeAlreadySupportedPatients}` +
          `&showOnlySoonOffboardingPatients=${showOnlySoonOffboardingPatients}` +
          `&searchTerm=${searchTerm}` +
          `&orderBy=${columnToSortOn}` +
          `&orderByAsc=${orderByAsc}`
      );
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      onSuccess: onSuccess,
    }
  );

  return query;
};

export const isGetAssignedPatientsFetching = (queryClient: QueryClient) => {
  const queries = queryClient.getQueryCache();
  const filteredQueries = queries.getAll().filter(
    // if we don't know the query key, we can rely that it must contain 'my-case-load' and more than 2 items
    (x) =>
      x.queryKey.includes("case-load-manager") &&
      x.queryKey.includes("my-case-load") &&
      x.queryKey.length > 2
  );

  const mainQuery = filteredQueries.length > 0 ? filteredQueries[0] : null;
  return mainQuery?.state?.isFetching ?? false;
};

export const useGetAssignedPatient = (id: string) => {
  const query = useQuery<PatientDetails>(
    ["case-load-manager", "my-case-load", "client-details", id],
    async () => {
      const res = await axios.get(`caseloadmanager/my-case-load/${id}`);
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  return query;
};

export const useFetchProgress = (isEnabled: boolean) => {
  const query = useQuery<{
    completed: number;
    total: number;
  }>(
    ["case-load-manager", "my-progress"],
    async () => {
      const res = await axios.get(
        `caseloadmanager/my-progress?showOnlyActiveCaseLoad=false` // temporarily ignore ${showOnlyActiveCaseLoad}
      );
      return res.data;
    },
    {
      enabled: isEnabled,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  return query;
};

export const useSearchPatients = (searchTerm: string) => {
  const query = useQuery<SelectablePatient[]>(
    ["case-load-manager", "search-patients", searchTerm],
    async () => {
      const res = await axios.get(
        `caseloadmanager/patients?searchTerm=${searchTerm}`
      );
      return res.data;
    },
    {
      enabled: searchTerm.length >= 3,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useGetPatientRemarks = (patientId: string | undefined) => {
  const query = useQuery<string[]>(
    ["case-load-manager", "patient-remarks", patientId ?? "N/A"],
    async () => {
      const res = await axios.get(
        `caseloadmanager/patients/${patientId}/remarks`
      );
      return res.data;
    },
    {
      enabled: patientId != undefined,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useAddPatientManually = () => {
  const mutation = useMutation({
    mutationFn: async ({ patientId }: { patientId: string }) => {
      const res = await axios.post(`caseloadmanager/my-case-load/add`, {
        patientId,
      });
      return res.data;
    },
    onSuccess: () => refetchCaseLoadEntries(),
  });

  return mutation;
};

export const useRemoveCaseLoadEntry = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({ caseLoadId }: { caseLoadId: string }) => {
      const res = await axios.delete(
        `caseloadmanager/my-case-load/${caseLoadId}`
      );

      return res.data;
    },
    onSuccess: () => {
      onSuccess();
      refetchCaseLoadEntries();
    },
  });

  return mutation;
};

export const useRemoveCaseLoadEntries = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({
      caseLoadEntryIds,
    }: {
      caseLoadEntryIds: string[];
    }) => {
      const res = await axios.post(`caseloadmanager/my-case-load/mass-delete`, {
        caseLoadEntryIds,
      });

      return res.data;
    },
    onSuccess: () => {
      onSuccess();
      refetchCaseLoadEntries();
    },
  });

  return mutation;
};

export const useToggleSupportedPatientViaChat = () => {
  const mutation = useMutation({
    mutationFn: async ({
      caseLoadId,
      value,
    }: {
      caseLoadId: string;
      value: boolean;
    }) => {
      const res = await axios.post(
        `caseloadmanager/my-case-load/${caseLoadId}/toggle-supported-patient-via-chat`,
        { value }
      );
      return res.data;
    },
    onSuccess: () => refetchCaseLoadEntries(),
  });

  return mutation;
};

export const useToggleSupportedPatientViaVideoCall = () => {
  const mutation = useMutation({
    mutationFn: async ({
      caseLoadId,
      value,
    }: {
      caseLoadId: string;
      value: boolean;
    }) => {
      const res = await axios.post(
        `caseloadmanager/my-case-load/${caseLoadId}/toggle-supported-patient-via-video-call`,
        { value }
      );
      return res.data;
    },
    onSuccess: () => refetchCaseLoadEntries(),
  });

  return mutation;
};

export const useMassToggleSupportedPatients = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({
      caseLoadEntryIds,
      value,
    }: {
      caseLoadEntryIds: string[];
      value: boolean;
    }) => {
      const res = await axios.post(
        `caseloadmanager/my-case-load/mass-toggle-supported-patients`,
        {
          caseLoadEntryIds,
          value,
        }
      );
      return res.data;
    },
    onSuccess: () => {
      refetchCaseLoadEntries();
      onSuccess();
    },
  });

  return mutation;
};

export const useUpdatePatientRequiresSupport = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({
      caseLoadId,
      checkInNeeded,
      justificationIfNo,
    }: {
      caseLoadId: string;
      checkInNeeded: boolean;
      justificationIfNo: string | undefined;
    }) => {
      const res = await axios.post(
        `caseloadmanager/my-case-load/${caseLoadId}/patient-requires-support`,
        {
          checkInNeeded,
          justificationIfNo,
        }
      );
      return res.data;
    },
    onSuccess: () => {
      onSuccess();
      refetchCaseLoadEntries();
    },
  });

  return mutation;
};

export const useMassUpdatePatientsRequireSupportState = (
  onSuccess: () => void
) => {
  const mutation = useMutation({
    mutationFn: async ({
      caseLoadEntryIds,
      checkInNeeded,
      justificationIfNo,
    }: {
      caseLoadEntryIds: string[];
      checkInNeeded: boolean;
      justificationIfNo: string | undefined;
    }) => {
      const res = await axios.post(
        `caseloadmanager/my-case-load/mass-update-patients-require-support`,
        {
          caseLoadEntryIds,
          checkInNeeded,
          justificationIfNo,
        }
      );
      return res.data;
    },
    onSuccess: () => {
      onSuccess();
      refetchCaseLoadEntries();
    },
  });

  return mutation;
};

export const useSaveSinglePsychologistComment = () => {
  const mutation = useMutation({
    mutationFn: async ({
      caseLoadId,
      comment,
    }: {
      caseLoadId: string;
      comment: string | undefined;
    }) => {
      const res = await axios.post(
        `caseloadmanager/my-case-load/${caseLoadId}/comment`,
        {
          comment,
        }
      );
      return res.data;
    },
    onSuccess: () => {
      refetchCaseLoadEntries();
    },
  });

  return mutation;
};

export const useSaveMultiplePsychologistComment = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({
      caseLoadEntryIds,
      comment,
    }: {
      caseLoadEntryIds: string[];
      comment: string | undefined;
    }) => {
      const res = await axios.post(
        `caseloadmanager/my-case-load/mass-update-comments`,
        {
          caseLoadEntryIds,
          comment,
        }
      );
      return res.data;
    },
    onSuccess: () => {
      onSuccess();
      refetchCaseLoadEntries();
    },
  });

  return mutation;
};

export const useUpdateTreatment = () => {
  const mutation = useMutation({
    mutationFn: async ({
      caseLoadId,
      treatment,
    }: {
      caseLoadId: string;
      treatment: string | undefined;
    }) => {
      const res = await axios.post(
        `caseloadmanager/my-case-load/${caseLoadId}/treatment`,
        {
          treatment,
        }
      );
      return res.data;
    },
    onSuccess: () => {
      refetchCaseLoadEntries();
    },
  });

  return mutation;
};

export const useResolveAlerts = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({ caseLoadEntryId }: { caseLoadEntryId: string }) => {
      const res = await axios.post(
        `caseloadmanager/my-case-load/${caseLoadEntryId}/alerts/resolve`,
        {}
      );
      return res.data;
    },
    onSuccess: () => {
      onSuccess();
      refetchCaseLoadEntries();
    },
  });

  return mutation;
};

export const useResolveAllAlerts = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async () => {
      const res = await axios.post(
        `caseloadmanager/my-case-load/alerts/resolve-all`,
        {}
      );
      return res.data;
    },
    onSuccess: () => {
      onSuccess();
      refetchCaseLoadEntries();
    },
  });

  return mutation;
};

export const useGetAllPaginatedAutomaticBillings = (
  date: Date,
  pageSize: number,
  skip: number,
  searchTerm: string,
  columnToSortOn: AutomaticBillingSortingColumns,
  orderByAsc: boolean
) => {
  const cacheInMinutes = 1000 * 60 * 0.5; // 0.5 min cache
  const isInPast = isDateInPastWeeks(date);

  const query = useQuery<{
    data: AutomaticBilling[];
    pagination: Pagination;
  }>(
    [
      "case-load-manager",
      "automatic-billings",
      "all",
      date.toString(),
      searchTerm,
      pageSize,
      skip,
      columnToSortOn,
      orderByAsc ? "asc" : "desc",
    ],
    async () => {
      const res = await axios.get(
        `automaticbilling/my-automatic-bills?period=${date.toISOString()}` +
          `&pageSize=${pageSize}` +
          `&skip=${skip}` +
          `&searchTerm=${searchTerm}` +
          `&orderBy=${columnToSortOn}` +
          `&orderByAsc=${orderByAsc}`
      );
      return res.data;
    },
    {
      cacheTime: isInPast ? undefined : cacheInMinutes,
      refetchInterval: isInPast ? undefined : cacheInMinutes, // we only want to refetch if the date is for the current week
    }
  );

  return query;
};

export const useGetAutomaticBillingsForPatient = (
  patientId: string,
  date: Date,
  isEnabled: boolean
) => {
  const query = useQuery<AutomaticBilling[]>(
    ["case-load-manager", "automatic-billings", patientId, date.toString()],
    async () => {
      const res = await axios.get(
        `automaticbilling/my-automatic-bills/patients/${patientId}?period=${date.toISOString()}`
      );
      return res.data;
    },
    {
      enabled: isEnabled,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useSaveAbsence = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({
      startDate,
      endDate,
    }: {
      startDate: Date;
      endDate: Date;
    }) => {
      const res = await axios.post(
        `caseloadmanager/my-case-load/create-absence`,
        {
          startDate: moment(startDate).utc(true).toISOString(),
          endDate: moment(endDate).utc(true).toISOString(),
        }
      );
      return res.data;
    },
    onSuccess: () => {
      onSuccess();
      refetchCaseLoadEntries();
    },
  });

  return mutation;
};

export const useStopAbsence = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async () => {
      const res = await axios.post(
        `caseloadmanager/my-case-load/stop-absence`,
        {}
      );
      return res.data;
    },
    onSuccess: () => {
      onSuccess();
      refetchCaseLoadEntries();
    },
  });

  return mutation;
};
