import { useState } from "react";
import { Button } from "../../../../../components/ui/button";
import { Loader2, SquarePen } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../../components/ui/dialog";
import { Textarea } from "../../../../../components/ui/textarea";
import {
  useGetPersonalizedKopTemplate,
  useUpdatePersonalizedKopTemplate,
} from "../../hooks";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";

export const MyKopModelTemplateButton = () => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const [preIntakeQuestions, setPreIntakeQuestions] = useState("");
  const [complaints, setComplains] = useState("");
  const [circumstances, setCircumstances] = useState("");
  const [personalStyle, setPersonalStyle] = useState("");
  const [
    myCommentsForDiagnosisAndTreatment,
    setMyCommentsForDiagnosisAndTreatment,
  ] = useState("");
  const [requestForHelp, setRequestForHelp] = useState("");

  useGetPersonalizedKopTemplate((data) => {
    setPreIntakeQuestions(data?.questionsForPreIntake ?? "");
    setComplains(data?.complaints ?? "");
    setCircumstances(data?.circumstances ?? "");
    setPersonalStyle(data?.personalStyle ?? "");
    setRequestForHelp(data?.requestForHelp ?? "");
    setMyCommentsForDiagnosisAndTreatment(
      data?.myCommentsForDiagnosisAndTreatment ?? ""
    );
  });

  const { mutate: save, isLoading: isSaving } =
    useUpdatePersonalizedKopTemplate(() => {
      toast("Template update", {
        description: "Your personal KOP model template has been updated.",
      });
      setIsOpen(false);
    });

  return (
    <>
      <Button
        variant="outline"
        className="flex items-center space-x-2"
        onClick={() => setIsOpen(true)}
      >
        <div className="w-4 h-4">
          <SquarePen className="w-4 h-4" />
        </div>
        <p>{t("case-load-manager-onboarding-kop-model-template-button")}</p>
      </Button>

      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="min-w-[50vw] max-h-[80vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>KOP Model Personal Template</DialogTitle>
          </DialogHeader>

          <section>
            <div className="mt-6">
              <p className="font-bold">
                {t(
                  "case-load-manager-onboarding-details-kop-model-caption-preintake-questions"
                )}
              </p>
              <Textarea
                className="border-1-4 mt-1 bg-gray-50/50 dark:bg-slate-800"
                value={preIntakeQuestions}
                onChange={(e) => setPreIntakeQuestions(e.target.value)}
                rows={3}
              />
            </div>

            <div className="mt-4 flex space-x-4 items-start">
              <div className="w-1/3">
                <p className="font-bold">Klachten</p>
                <Textarea
                  className="border-1-4 mt-1 bg-gray-50/50 dark:bg-slate-800"
                  value={complaints}
                  onChange={(e) => setComplains(e.target.value)}
                  rows={5}
                />
              </div>
              <div className="w-1/3">
                <p className="font-bold">Omstandigheden</p>
                <Textarea
                  className="border-1-4 mt-1 bg-gray-50/50 dark:bg-slate-800"
                  value={circumstances}
                  onChange={(e) => setCircumstances(e.target.value)}
                  rows={5}
                />
              </div>

              <div className="w-1/3">
                <p className="font-bold">Persoonlijke stijl</p>
                <Textarea
                  className="border-1-4 mt-1 bg-gray-50/50"
                  value={personalStyle}
                  onChange={(e) => setPersonalStyle(e.target.value)}
                  rows={5}
                />
              </div>
            </div>

            <div className="mt-4">
              <p className="font-bold">Hulpvraag</p>
              <Textarea
                className="border-1-4 mt-1 bg-gray-50/50 dark:bg-slate-800"
                value={requestForHelp}
                onChange={(e) => setRequestForHelp(e.target.value)}
                rows={3}
              />
            </div>

            <div className="mt-4">
              <p className="font-bold">
                Eigen ideeën over diagnose, behandeling, andere opmerkingen
              </p>
              <Textarea
                className="border-1-4 mt-1 bg-gray-50/50 dark:bg-slate-800"
                value={myCommentsForDiagnosisAndTreatment}
                onChange={(e) =>
                  setMyCommentsForDiagnosisAndTreatment(e.target.value)
                }
                rows={3}
              />
            </div>
          </section>

          <DialogFooter className="gap-2 flex items-center justify-end">
            <Button
              variant="outline"
              onClick={() => setIsOpen(false)}
              className="px-4"
            >
              {t("case-load-manager-onboarding-kop-model-template-close")}
            </Button>
            <Button
              onClick={async (e) => {
                e.preventDefault();
                await save({
                  complaints,
                  circumstances,
                  personalStyle,
                  requestForHelp,
                  questionsForPreIntake: preIntakeQuestions,
                  myCommentsForDiagnosisAndTreatment,
                });
              }}
              className="px-4 flex items-center space-x-2"
            >
              {isSaving && <Loader2 className="w-4 h-4 animate-spin" />}
              <p>{t("case-load-manager-onboarding-kop-model-template-save")}</p>
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
