import { useState } from "react";
import { Button } from "../../../../components/ui/button";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { Loader2 } from "lucide-react";
import { useRemoveCaseLoadEntry } from "../hooks";
import { toast } from "sonner";

export const InactiveDossierOfCaseLoadEntry = ({
  caseLoadId,
  patientName,
}: {
  caseLoadId: string;
  patientName: string;
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const { mutate: removeCaseLoadEntry, isLoading: isRemoving } =
    useRemoveCaseLoadEntry(() => {
      setIsOpen(false);

      toast(
        t("case-load-manager-remove-inactive-dossier-confirmation-toast-title"),
        {
          description: t(
            "case-load-manager-remove-inactive-dossier-confirmation-toast-desc",
            {
              name: patientName,
            }
          ),
        }
      );
    });

  return (
    <div className="flex flex-col items-center justify-center space-y-2 text-center">
      <p>{t("case-load-manager-alert-closed-dossier-desc")}</p>
      <Button
        variant="destructive"
        onClick={(e) => {
          setIsOpen(true);
        }}
      >
        {t("case-load-manager-alert-closed-dossier-button")}
      </Button>

      <Dialog
        open={isOpen}
        onOpenChange={(newVal) => {
          setIsOpen(newVal);
        }}
      >
        <DialogContent className="w-[40vw]">
          <DialogHeader>
            <DialogTitle>
              {t("case-load-manager-remove-inactive-dossier-dialog-title")}
            </DialogTitle>
            <DialogDescription>
              {t("case-load-manager-remove-inactive-dossier-dialog-desc")}
            </DialogDescription>
          </DialogHeader>

          <DialogFooter className="flex items-center space-x-2">
            <Button
              variant="outline"
              className="w-1/2"
              onClick={() => setIsOpen(false)}
            >
              {t(
                "case-load-manager-remove-inactive-dossier-dialog-cancel-button"
              )}
            </Button>
            <Button
              variant="destructive"
              onClick={async () => {
                if (!isRemoving) {
                  await removeCaseLoadEntry({
                    caseLoadId,
                  });
                }
              }}
              className="w-1/2"
            >
              {isRemoving ? (
                <div className="flex items-center space-x-1">
                  <Loader2 className="w-4 h-4 animate-spin" />
                  <p>
                    {t(
                      "case-load-manager-remove-inactive-dossier-dialog-removing-button"
                    )}
                  </p>
                </div>
              ) : (
                <p>
                  {t(
                    "case-load-manager-remove-inactive-dossier-dialog-remove-button"
                  )}
                </p>
              )}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};
