import { Switch } from "../../../../../components/ui/switch";

export const DeviationsOnlyFilter = ({
  isChecked,
  onCheckedChange,
}: {
  isChecked: boolean;
  onCheckedChange: (newValue: boolean) => void;
}) => {
  return (
    <div className="flex items-center space-x-2">
      <Switch
        checked={isChecked}
        onCheckedChange={(x) => onCheckedChange(x)}
        id="deviations-only"
      />
      <label htmlFor="deviations-only">Show deviations only</label>
    </div>
  );
};
