import { useTranslation } from "react-i18next";
import { SelectablePatient } from "../../types";

export const EmptyResult = ({
  isLoading,
  searchTerm,
  patients,
}: {
  isLoading: boolean;
  searchTerm: string;
  patients: SelectablePatient[] | undefined;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {searchTerm.length >= 3 &&
        !isLoading &&
        (patients ?? []).length === 0 && (
          <div className="flex justify-center p-4 opacity-80">
            {t("case-load-manager-no-results-found")}.
          </div>
        )}
    </>
  );
};
