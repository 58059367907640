import { ColumnDef } from "@tanstack/react-table";
import { MainListPatient } from "../../../types";
import { SortableCell } from "../../../../../components/ui/table";
import { getSimpleFormattedDateTime } from "../../../utils";
import { useTranslation } from "react-i18next";

export const LastChatConsultOnColName = "Last Chat Consult";

export const LastChatConsultOnCol: ColumnDef<MainListPatient> = {
  id: LastChatConsultOnColName,
  accessorKey: "lastChatConsultOn",
  size: 150,
  minSize: 150,
  header: ({ column }) => {
    const { t } = useTranslation();
    return (
      <SortableCell
        column={column}
        label={t("case-load-manager-column-last-chat-consult")}
      />
    );
  },
  cell: ({ getValue }) => {
    const dateAsString = getValue()?.toString();

    if (!dateAsString) {
      return <></>;
    }

    return <p>{getSimpleFormattedDateTime(dateAsString)}</p>;
  },
};
