import { Button } from "../../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../components/ui/popover";
import { Calendar } from "../../../../components/ui/calendar";
import { Checkbox } from "../../../../components/ui/checkbox";
import { CalendarIcon, Save } from "lucide-react";
import { useState } from "react";
import { getSimpleFormattedDate } from "../../../caseLoadCockpit/utils";
import { IntakeValueSelector } from "./IntakeValueSelector";
import { useCreateIntakeDeviation } from "../../hooks";
import { Row } from "./IntakeLimitsTable";
import moment from "moment";

export const AddDeviationButton = ({
  row,
  hasIndefiniteIntakeAlready,
}: {
  row: Row;
  hasIndefiniteIntakeAlready: boolean;
}) => {
  const { mutate: save, isLoading: isSaving } = useCreateIntakeDeviation();

  const getNextMonday = () => {
    const today = new Date();
    const nextMonday = new Date(today.getTime());
    nextMonday.setDate(today.getDate() + ((1 + 7 - today.getDay()) % 7));
    return nextMonday;
  };

  const getNextFriday = (from: Date) => {
    const result = new Date(from);
    result.setDate(result.getDate() + 4);
    return result;
  };

  const [isIndefinite, setIsIndefinite] = useState(false);
  const [startDate, setStartDate] = useState(getNextMonday());
  const [endDate, setEndDate] = useState<Date | undefined>(
    getNextFriday(getNextMonday())
  );
  const [numberOfIntakes, setNumberOfIntakes] = useState(3);

  const isSaveDisabled = () => {
    if (isIndefinite) {
      return false;
    }

    return startDate > endDate!;
  };

  return (
    <Dialog
      onOpenChange={() => {
        // reset
        setIsIndefinite(false);
        setStartDate(getNextMonday());
        setEndDate(getNextFriday(getNextMonday()));
        setNumberOfIntakes(3);
      }}
    >
      <DialogTrigger asChild>
        <Button className="px-3 max-h-[30px] m-0 text-xs">
          + Add Deviation
        </Button>
      </DialogTrigger>

      <DialogContent className="p-8 min-w-[600px]">
        <DialogHeader>
          <DialogTitle className="text-2xl">Create new deviation</DialogTitle>
        </DialogHeader>
        <main>
          <div className="flex justify-between items-start space-x-2">
            <section className="w-1/2">
              <p className="pb-1">Start date:</p>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant={"outline"}
                    className="w-[240px] pl-3 text-left font-normal"
                  >
                    <span>{getSimpleFormattedDate(startDate)}</span>
                    <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="z-[999999]" align="start">
                  <Calendar
                    mode="single"
                    toDate={undefined}
                    selected={startDate}
                    defaultMonth={startDate}
                    onSelect={(_, d) => {
                      setStartDate(d);
                    }}
                    initialFocus
                    disabled={(d) => {
                      // none in past
                      if (d < new Date()) return true;

                      // mondays are OK to select
                      if (d.getDay() == 1) return false;

                      // rest is disabled
                      return true;
                    }}
                  />
                </PopoverContent>
              </Popover>
            </section>

            <section className="w-1/2">
              <p className="pb-1">End date:</p>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant={isSaveDisabled() ? "destructive" : "outline"}
                    disabled={isIndefinite}
                    className="w-[240px] pl-3 text-left font-normal"
                  >
                    <span>
                      {endDate ? getSimpleFormattedDate(endDate) : ""}
                    </span>
                    <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="z-[999999]" align="start">
                  <Calendar
                    mode="single"
                    toDate={undefined}
                    fromDate={startDate}
                    defaultMonth={endDate || startDate}
                    selected={endDate}
                    onSelect={(_, d) => {
                      setEndDate(d);
                    }}
                    initialFocus
                    disabled={(d) => {
                      // none in past
                      if (d < new Date()) return true;

                      // fridays are OK to select
                      if (d.getDay() == 5) return false;

                      // rest is disabled
                      return true;
                    }}
                  />
                </PopoverContent>
              </Popover>

              <div className="pt-2 flex items-start space-x-2">
                <Checkbox
                  id="isIndefinite"
                  checked={isIndefinite}
                  disabled={hasIndefiniteIntakeAlready}
                  className="mt-px"
                  onCheckedChange={() => {
                    const newValue = !isIndefinite;

                    if (newValue) {
                      setEndDate(undefined);
                    } else {
                      setEndDate(getNextFriday(startDate));
                    }

                    setIsIndefinite(newValue);
                  }}
                />
                <div>
                  <label
                    htmlFor="neverShowAgain"
                    className={`text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 ${
                      isIndefinite && "font-bold"
                    }`}
                  >
                    Indefinite{" "}
                  </label>
                  {hasIndefiniteIntakeAlready && (
                    <p className="text-xs pl-1 opacity-80">
                      The user already has an indefinite deviation saved.
                    </p>
                  )}
                </div>
              </div>
            </section>
          </div>
          <div className="pt-2 mt-4 border-t flex items-end justify-between">
            <div>
              <p className="pb-1">Max number of intakes per day:</p>
              <IntakeValueSelector
                value={numberOfIntakes}
                onChange={(x) => setNumberOfIntakes(x)}
              />
            </div>

            <Button
              className="flex items-center space-x-2"
              disabled={isSaveDisabled() || isSaving}
              onClick={async () => {
                await save({
                  epdEmployeeId: row.epdEmployeeId,
                  start: moment(startDate).utc(true).toISOString(),
                  end: !endDate
                    ? undefined
                    : moment(endDate).utc(true).toISOString(),
                  isIndefinite,
                  numberOfIntakes,
                });
              }}
            >
              <Save className="h-4 w-4" />
              <p>{isSaving ? "Saving..." : "Save"}</p>
            </Button>
          </div>
        </main>
      </DialogContent>
    </Dialog>
  );
};
