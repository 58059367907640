import { Trash2 } from "lucide-react";
import { useGetAssignedPatient } from "../../hooks";
import { useState } from "react";
import { RemovePatientDialog } from "../../removePatient/removePatientDialog";
import { useTranslation } from "react-i18next";

export const RemovePatientButton = ({
  clientId,
  onRemoval,
}: {
  clientId: string;
  onRemoval: () => void;
}) => {
  const { t } = useTranslation();
  const { data } = useGetAssignedPatient(clientId);
  const [isRemovePatientConfirmationOpen, setIsRemovePatientConfirmationOpen] =
    useState(false);

  if (!data) return <></>;

  return (
    <>
      <div
        className="w-full flex items-center space-x-2 text-red-600 cursor-pointer"
        onClick={() => setIsRemovePatientConfirmationOpen(true)}
      >
        <div className="w-5 h-5">
          <Trash2 className="w-5 h-5" />
        </div>
        <p className="hover:underline">
          {t("case-load-manager-row-actions-remove-patient")}
        </p>
      </div>

      <RemovePatientDialog
        isOpen={isRemovePatientConfirmationOpen}
        setIsOpen={setIsRemovePatientConfirmationOpen}
        caseLoadEntryId={clientId}
        patient={data}
        onRemoval={onRemoval}
      />
    </>
  );
};
