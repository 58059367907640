import React from "react";
import { AppBar, Toolbar, Hidden, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import InputIcon from "@mui/icons-material/Input";
import Typography from "@mui/material/Typography";
import useStores from "../../useStores";
import { styled } from "@mui/system";

const StyledAppBar = styled(AppBar)(() => ({
  boxShadow: "none",
  display: "block",
}));

const StyledSignOutButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(3),
}));

const StyledTopBarTypography = styled(Typography)(({ theme }) => ({
  color: "#fff",
  fontWeight: 500,
  fontSize: "20px",
  letterSpacing: "-0.24px",
  lineHeight: "32px",
  paddingLeft: "12px",
}));

const Topbar = ({ onSidebarOpen, ...rest }) => {
  const { authStore } = useStores();

  return (
    <StyledAppBar {...rest}>
      <Toolbar>
        <IconButton color="inherit" onClick={onSidebarOpen} size="large">
          <MenuIcon />
        </IconButton>
        <StyledTopBarTypography>iPractice Dashboard</StyledTopBarTypography>
        <div style={{ flexGrow: 1 }} />
        <Hidden lgDown>
          <StyledSignOutButton
            color="inherit"
            onClick={() => authStore.logOut()}
            size="large"
          >
            <InputIcon />
          </StyledSignOutButton>
        </Hidden>
      </Toolbar>
    </StyledAppBar>
  );
};

export default Topbar;
