import { TableCell, TableRow } from "../../../../components/ui/table";
import { IntakeValueSelector } from "./IntakeValueSelector";
import { hasDeviation } from "./utils";
import { Textarea } from "../../../../components/ui/textarea";
import { Button } from "../../../../components/ui/button";
import { useState } from "react";
import { Loader2, Save, Trash2, Undo } from "lucide-react";
import { AddDeviationButton } from "./AddDeviationButton";
import { Row } from "./IntakeLimitsTable";
import { getSimpleFormattedDate } from "../../../caseLoadCockpit/utils";
import {
  useAddIntakeDeviationComment,
  useDeleteIntakeDeviation,
  useEditCurrentWeekIntakeDeviation,
} from "../../hooks";

export const IntakeLimitRow = ({ data }: { data: Row }) => {
  const [modifiedComment, setModifiedComment] = useState<string | undefined>(
    data.comments
  );
  let hasChanged = (data.comments ?? "") !== (modifiedComment ?? "");

  const { mutate: addComment, isLoading: isAddingComment } =
    useAddIntakeDeviationComment();

  const { mutate: deleteDeviation, isLoading: isDeletingDeviation } =
    useDeleteIntakeDeviation();

  const { mutate: editCurrentWeekDeviation } =
    useEditCurrentWeekIntakeDeviation();

  const getDisplayName = (fullName: string) => {
    const splitName = fullName.split(" ");

    const firstName = splitName[0];
    const lastNames = splitName.slice(1).join(" ");

    return `${lastNames} ${firstName}`;
  };

  return (
    <TableRow
      key={data.epdEmployeeId}
      className={`${hasDeviation(data) ? "bg-amber-50 hover:bg-amber-50" : ""}`}
    >
      <TableCell className="w-1/6">
        {getDisplayName(data.employeeName)}
      </TableCell>
      <TableCell className="w-1/5">{data.locations.join(", ")}</TableCell>
      <TableCell className="w-[60px]">
        {data.isCurrentWeekPartOfIndefinite ||
        data.futureWeeks.some(
          (f) => new Date(f.start) < new Date() && f.isIndefinite
        ) ? (
          <div>
            <p>
              {data.isCurrentWeekPartOfIndefinite
                ? data.currentWeekNumberOfIntakes
                : data.futureWeeks.filter(
                    (f) => new Date(f.start) < new Date() && f.isIndefinite
                  )[0].numberOfIntakes}
            </p>
            <p className="pt-1 text-xs opacity-80">
              Ongoing indefinite deviation.
            </p>
          </div>
        ) : (
          <IntakeValueSelector
            value={data.currentWeekNumberOfIntakes}
            onChange={async (newValue) => {
              await editCurrentWeekDeviation({
                epdEmployeeId: data.epdEmployeeId,
                numberOfIntakes: newValue,
              });
            }}
          />
        )}
      </TableCell>
      <TableCell className="w-1/4">
        <div className="flex items-center justify-between">
          {data.futureWeeks.length == 0 ? (
            <>
              <div>
                <p>No deviation</p>
                <p className="opacity-80 text-xs">Standard 3 intake/day</p>
                <div className="pt-1">
                  <AddDeviationButton
                    row={data}
                    hasIndefiniteIntakeAlready={false}
                  />
                </div>
              </div>
            </>
          ) : (
            <ul>
              {data.futureWeeks.map((f) => {
                return (
                  <li className="text-sm flex items-center space-x-2">
                    <p>
                      <span className="font-medium">
                        {getSimpleFormattedDate(f.start)} -{" "}
                        {f.isIndefinite
                          ? "Indefinite"
                          : getSimpleFormattedDate(f.end!)}
                        :
                      </span>{" "}
                      {f.numberOfIntakes} intake(s)/day
                    </p>
                    <div className="h-4 w-4">
                      <Trash2
                        className="h-4 w-4 cursor-pointer text-red-400 dark:text-slate-400 hover:text-red-600 hover:dark:text-red-500"
                        onClick={async () => {
                          await deleteDeviation({
                            epdEmployeeId: data.epdEmployeeId,
                            startDate: f.start,
                            endDate: f.end,
                            isIndefinite: f.isIndefinite,
                          });
                        }}
                      />
                    </div>
                  </li>
                );
              })}
              <div className="pt-1">
                <AddDeviationButton
                  hasIndefiniteIntakeAlready={data.futureWeeks.some(
                    (f) => f.isIndefinite
                  )}
                  row={data}
                />
              </div>
            </ul>
          )}
        </div>
      </TableCell>
      <TableCell className="w-1/4">
        <Textarea
          value={modifiedComment ?? ""}
          onChange={(e) => setModifiedComment(e.target.value)}
          disabled={isAddingComment}
        />
        {hasChanged && (
          <div className="flex gap-2">
            <Button
              className="py-0 w-full flex items-center gap-2 text-sm h-8 mt-2"
              disabled={!hasChanged}
              onClick={async () => {
                await addComment({
                  epdEmployeeId: data.epdEmployeeId,
                  comment: modifiedComment ?? "",
                });
              }}
            >
              {isAddingComment ? (
                <>
                  <Loader2 className="w-4 h-4 animate-spin" />
                  <p>Saving...</p>
                </>
              ) : (
                <>
                  <Save className="w-4 h-4" />
                  <p>Save</p>
                </>
              )}
            </Button>
            <Button
              variant="outline"
              className="py-0 w-full flex items-center gap-2 text-sm h-8 mt-2"
              disabled={!hasChanged}
              onClick={() => setModifiedComment(data.comments)}
            >
              <Undo className="w-4 h-4" />
              <p>Undo</p>
            </Button>
          </div>
        )}
      </TableCell>
    </TableRow>
  );
};
