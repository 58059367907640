import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../components/ui/popover";
import { Button } from "../../../../components/ui/button";
import { SlidersHorizontal } from "lucide-react";
import { ColumnVisibilityPicker } from "../../columns/columnVisibilityPicker";
import { PageSizePicker } from "../../../../components/ui/page-size-picker";
import {
  ReferredPatient,
  HealthcareInsurerContractFilter,
  TransferReferralFilter,
  ViewType,
} from "../../types";
import { Table } from "@tanstack/react-table";
import { statuses } from "../../constants";
import { DataTableFacetedFilter } from "./facetedFilter";
import { StatusColName } from "../columns/distinctCols/statusCol";
import { NextStepColName } from "../columns/distinctCols/nextStepCol";
import { LocationColName } from "../columns/distinctCols/locationCol";
import { HealthCareInsurerColName } from "../columns/distinctCols/healthcareInsurerCol";
import { useGetEpdLocations, useGetHealthcareInsurers } from "../../hooks";
import { Cross2Icon } from "@radix-ui/react-icons";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "../../../../components/ui/toggle-group";
import { getAllNextSteps } from "../../referralDetails/nextStep";

export const Customize = ({
  table,
  viewType,
  pageSize,
  setPageSize,
  registrationStatuses,
  setRegistrationStatuses,
  nextSteps,
  setNextSteps,
  locationIds,
  setLocationIds,
  healthcareInsurerIds,
  setHealthcareInsurerIds,
  transferFilter,
  setTransferFilter,
  contractFilter,
  setContractFilter,
}: {
  table: Table<ReferredPatient>;
  viewType: ViewType;
  pageSize: number;
  setPageSize: (newSize: number) => void;
  registrationStatuses: string[];
  setRegistrationStatuses: (newStatuses: string[]) => void;
  nextSteps: string[];
  setNextSteps: (newStatuses: string[]) => void;
  locationIds: string[];
  setLocationIds: (newIds: string[]) => void;
  healthcareInsurerIds: string[];
  setHealthcareInsurerIds: (newIds: string[]) => void;
  transferFilter: TransferReferralFilter;
  setTransferFilter: (newValue: TransferReferralFilter) => void;
  contractFilter: string;
  setContractFilter: (newValue: HealthcareInsurerContractFilter) => void;
}) => {
  const { data: epdLocations } = useGetEpdLocations();
  const { data: healthcareInsurers } = useGetHealthcareInsurers(true);

  const uniqueConcerns = healthcareInsurers
    ? healthcareInsurers
        .map((insurer) => insurer.concern)
        .filter((value, index, self) => self.indexOf(value) === index)
        .filter((value) => value.trim() !== "")
    : [];

  const locations = (epdLocations || []).map((location) => ({
    label: location.name,
    value: location.id,
  }));

  const numberOfFiltersInUse = table
    .getState()
    .columnFilters.map((x) => x.value as string[])
    .flatMap((x) => x)
    .filter((x) => x.trim() != "").length;

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" className="flex items-center gap-2 w-40">
          <SlidersHorizontal className="w-4 h-4" />
          <p>Customize</p>
          {numberOfFiltersInUse > 0 && (
            <div className="text-xs w-4 h-4 rounded-full bg-ipractice-blue text-center text-white">
              {numberOfFiltersInUse}
            </div>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-6 min-w-[24rem]" side="bottom" align="end">
        <div className="grid gap-4">
          <div className="space-y-2">
            <h4 className="font-bold text-lg leading-none">
              Customization & filters
            </h4>
            <p className="text-sm text-muted-foreground">
              Set filters and customize your view of the patient referrals.
            </p>
          </div>

          <div>
            <div className="w-full flex flex-col items-center space-y-2">
              {numberOfFiltersInUse > 0 && (
                <div className="flex justify-end w-full -mt-4">
                  <Button
                    variant="destructive"
                    onClick={() => {
                      table.resetColumnFilters();
                      setLocationIds([]);
                      setRegistrationStatuses([]);
                      setNextSteps([]);
                      setHealthcareInsurerIds([]);
                      setContractFilter(HealthcareInsurerContractFilter.All);
                      setTransferFilter(TransferReferralFilter.All);
                    }}
                    className="h-8 flex justify-end px-2 lg:px-3"
                  >
                    Reset Filters
                    <Cross2Icon className="h-4 w-4" />
                  </Button>
                </div>
              )}

              {table.getColumn(StatusColName) && (
                <DataTableFacetedFilter
                  column={table.getColumn(StatusColName)}
                  title="Status"
                  options={statuses}
                  filters={registrationStatuses}
                  setFilters={setRegistrationStatuses}
                />
              )}
              {table.getColumn(NextStepColName) && (
                <DataTableFacetedFilter
                  column={table.getColumn(NextStepColName)}
                  title="Next Step(s)"
                  options={getAllNextSteps().map((x) => ({
                    label: x.text,
                    value: x.value,
                  }))}
                  filters={nextSteps}
                  setFilters={setNextSteps}
                />
              )}
              {table.getColumn(LocationColName) && (
                <DataTableFacetedFilter
                  column={table.getColumn(LocationColName)}
                  title="Location"
                  options={locations}
                  filters={locationIds}
                  setFilters={setLocationIds}
                />
              )}
              {table.getColumn(HealthCareInsurerColName) && (
                <DataTableFacetedFilter
                  column={table.getColumn(HealthCareInsurerColName)}
                  title="Healthcare Insurer"
                  options={
                    uniqueConcerns
                      ?.toSorted((a, b) => a.localeCompare(b))
                      ?.map((d) => ({
                        label: d,
                        value: d,
                      })) || []
                  }
                  filters={healthcareInsurerIds}
                  setFilters={setHealthcareInsurerIds}
                />
              )}
              <div className="w-full">
                <ToggleGroup
                  type="single"
                  value={contractFilter}
                  onValueChange={(x: HealthcareInsurerContractFilter) => {
                    setContractFilter(x);
                  }}
                  className="w-full flex items-center justify-center border rounded-lg p-1"
                >
                  <ToggleGroupItem
                    className="h-8 w-1/2"
                    value={HealthcareInsurerContractFilter.Contracted}
                  >
                    <p>{HealthcareInsurerContractFilter.Contracted}</p>
                  </ToggleGroupItem>
                  <ToggleGroupItem
                    className="h-8 w-1/2"
                    value={HealthcareInsurerContractFilter.Uncontracted}
                  >
                    <p>{HealthcareInsurerContractFilter.Uncontracted}</p>
                  </ToggleGroupItem>
                </ToggleGroup>
              </div>
              <div className="w-full">
                <ToggleGroup
                  type="single"
                  value={transferFilter.toString()}
                  onValueChange={(x: string) => {
                    setTransferFilter(
                      x == "" ? TransferReferralFilter.All : Number(x)
                    );
                  }}
                  className="w-full flex items-center justify-center border rounded-lg p-1"
                >
                  <ToggleGroupItem
                    className="h-8 w-1/2"
                    value={TransferReferralFilter.OnlyTransfer.toString()}
                  >
                    <p>Only Transfers</p>
                  </ToggleGroupItem>
                  <ToggleGroupItem
                    className="h-8 w-1/2"
                    value={TransferReferralFilter.OnlyNonTransfer.toString()}
                  >
                    <p>Only Non-Transfers</p>
                  </ToggleGroupItem>
                </ToggleGroup>
              </div>
            </div>
          </div>

          <div className="grid gap-2">
            <div className="grid grid-cols-3 items-center gap-4">
              <p>Columns</p>
              <ColumnVisibilityPicker table={table} viewType={viewType} />
            </div>
          </div>
          <div className="grid gap-2">
            <div className="grid grid-cols-3 items-center gap-4">
              <p>Page Size</p>
              <PageSizePicker
                pageSize={pageSize}
                setPageSize={(newPageSize) => {
                  setPageSize(newPageSize);
                  table.resetRowSelection();
                }}
              />
            </div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
