import { useGetAssignedPatient } from "../../hooks";

export const DossierLinks = ({ clientId }: { clientId: string }) => {
  const { data } = useGetAssignedPatient(clientId);

  if (!data) return <></>;

  return (
    <>
      <p>•</p>
      <p
        className="text-link hover:underline cursor-pointer"
        onClick={() =>
          window.open(
            `https://start.praktijkdata.nl/app/clienten/${data.epdPatientId}`,
            "_blank"
          )
        }
      >
        Open dossier in <span className="font-bold">PraktijkData</span>
      </p>
      <p>•</p>
      <p
        className="text-link hover:underline cursor-pointer"
        onClick={() =>
          window.open(
            `/patient/${data.patientId}/DossierGgz/${data.dossierId}/treatment`,
            "_blank"
          )
        }
      >
        Open dossier in <span className="font-bold">Dashboard</span>
      </p>
    </>
  );
};
