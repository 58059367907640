import { ColumnDef, SortingState } from "@tanstack/react-table";
import { AutomaticBilling } from "../types";
import { Mail } from "lucide-react";
import { SortableCell } from "../../../components/ui/table";
import { formatDateTime } from "./utils";
import { AutomaticBillingSortingColumns } from "../types";
import { useTranslation } from "react-i18next";

export const PatientColName = "Patient";
export const AppointmentColName = "Appointment";
export const GeneratedOnColName = "Generated On";
export const GeneratedByColName = "Generated By";

export const columns: ColumnDef<AutomaticBilling>[] = [
  {
    id: PatientColName,
    accessorKey: "name",
    minSize: 400,
    maxSize: 400,
    header: ({ column }) => {
      const { t } = useTranslation();

      return (
        <div className="ml-1">
          <SortableCell
            column={column}
            label={t("automatic-billing-column-patient")}
          />
        </div>
      );
    },
    cell: ({ row }) => {
      const userName = row.original.name;
      const email = row.original.email;

      return (
        <div>
          <p className="font-bold">{userName}</p>
          <div className="flex items-center space-x-2 pt-2">
            <Mail className="w-4 h-4" />
            <p className="text-xs">{email}</p>
          </div>
        </div>
      );
    },
  },
  {
    id: AppointmentColName,
    accessorKey: "appointment",
    minSize: 350,
    maxSize: 350,
    header: ({ column }) => {
      const { t } = useTranslation();
      return (
        <SortableCell
          column={column}
          label={t("automatic-billing-column-appointment")}
        />
      );
    },
    cell: ({ row }) => {
      return (
        <div>
          <p>{formatDateTime(row.original.appointment)}</p>
        </div>
      );
    },
  },
  {
    id: GeneratedOnColName,
    accessorKey: "generatedOn",
    minSize: 350,
    maxSize: 350,
    header: ({ column }) => {
      const { t } = useTranslation();
      return (
        <SortableCell
          column={column}
          label={t("automatic-billing-column-generatedOn")}
        />
      );
    },
    cell: ({ row }) => {
      return (
        <div>
          <p>{formatDateTime(row.original.generatedOn)}</p>
        </div>
      );
    },
  },
  {
    id: GeneratedByColName,
    accessorKey: "generatedBy",
    minSize: 350,
    maxSize: 350,
    header: () => {
      const { t } = useTranslation();
      return <p>{t("automatic-billing-column-generatedBy")}</p>;
    },
    cell: ({ row }) => {
      return (
        <div>
          <p>{row.original.generatedBy}</p>
        </div>
      );
    },
  },
];

export const getSortingData = (
  state: SortingState
): {
  column: AutomaticBillingSortingColumns;
  isAsc: boolean;
} => {
  const defaultSorting = {
    column: AutomaticBillingSortingColumns.Patient,
    isAsc: true,
  };
  if (!state || state.length === 0) {
    return defaultSorting;
  }

  switch (state[0].id) {
    case PatientColName:
      return {
        column: AutomaticBillingSortingColumns.Patient,
        isAsc: !state[0].desc,
      };

    case AppointmentColName:
      return {
        column: AutomaticBillingSortingColumns.Appointment,
        isAsc: !state[0].desc,
      };

    case GeneratedOnColName:
      return {
        column: AutomaticBillingSortingColumns.GeneratedOn,
        isAsc: !state[0].desc,
      };

    default:
      return defaultSorting;
  }
};
