import { ColumnDef } from "@tanstack/react-table";
import { ReferredPatient } from "../../../types";
import { useTranslation } from "react-i18next";
import { Cake, Copy } from "lucide-react";
import { getSimpleFormattedDate } from "../../../../caseLoadCockpit/utils";

export const PatientColName = "Patient";

export const PatientCol: ColumnDef<ReferredPatient> = {
  id: PatientColName,
  accessorKey: "name",
  size: 175,
  minSize: 100,
  header: () => {
    return <p>Patient</p>;
  },
  cell: ({ row }) => {
    const userName = row.original.name;
    const dateOfBirth = row.original.dateOfBirth;

    if (row.original.isDuplicate) {
      return (
        <div>
          <p className="font-bold">{userName}</p>
          {dateOfBirth && (
            <div className="flex items-center space-x-2">
              <Cake className="w-4 h-4" />
              <p className="text-xs">{getSimpleFormattedDate(dateOfBirth)}</p>
            </div>
          )}
          <div className="inline-flex items-center space-x-2 px-2 py-1 font-medium text-amber-800 dark:text-amber-300 bg-amber-100 dark:bg-amber-900 rounded text-xs mt-2">
            <div className="h-4 w-4">
              <Copy className="h-4 w-4" />
            </div>
            <p className="text-xs">Possibly duplicate</p>
          </div>
        </div>
      );
    }

    return (
      <>
        <p className="font-bold">{userName}</p>
        {dateOfBirth && (
          <div className="pt-p1 flex items-center space-x-2">
            <Cake className="w-4 h-4" />
            <p className="text-xs">{getSimpleFormattedDate(dateOfBirth)}</p>
          </div>
        )}
      </>
    );
  },
};
