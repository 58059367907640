import { getCurrentMondayAndFriday } from "../../../../caseLoadCockpit/utils";
import { useState } from "react";
import { useGetWeeklyInflowPerLocationReport } from "../../hooks";
import { getWeek } from "date-fns";
import { ReferralsPerLocationTable } from "./tables/referralsPerLocationTable";

export const InflowByLocationReport = () => {
  const { monday } = getCurrentMondayAndFriday();
  const [viewType, setViewType] = useState<"week" | "month">("week");
  const [selectedDate, setSelectedDate] = useState<Date>(monday);
  const [locationIds, setLocationIds] = useState<string[]>([]);
  const [healthcareInsurerIds, setHealthcareInsurerIds] = useState<string[]>(
    []
  );

  const { data, isLoading } = useGetWeeklyInflowPerLocationReport();

  return (
    <main>
      <section className="w-full flex items-center justify-between">
        <div className="w-1/2">
          <h1 className="text-2xl font-bold">
            Inflow by location report{" "}
            <span className="font-medium">- CRM / Patient Referral</span>
          </h1>
          <p>The number referrals received (ZorgDomein & manually created) for each city per week</p>
        </div>
      </section>

      <section className="mt-12">
        <ReferralsPerLocationTable data={data} />
      </section>
    </main>
  );
};
