import { isPeriodInPastWeeks } from "../../utils";
import { Textarea } from "../../../../components/ui/textarea";
import { Button } from "../../../../components/ui/button";
import { useState } from "react";
import { useUpdateTreatment } from "../hooks";
import { useTranslation } from "react-i18next";
import { MainListPatient } from "../../types";
import { Loader2, Save, Undo } from "lucide-react";

export const TreatmentComment = ({ data }: { data: MainListPatient }) => {
  const { t } = useTranslation();
  const defaultValue = data.treatment;
  const [modifiedValue, setModifiedValue] = useState<string | undefined>(
    defaultValue
  );

  let hasChanged = (defaultValue ?? "") !== (modifiedValue ?? "");

  const isInPast = isPeriodInPastWeeks(data.period);

  const { mutate: save, isLoading: isSaving } = useUpdateTreatment();

  return (
    <>
      <Textarea
        value={modifiedValue ?? ""}
        className="text-xs"
        onChange={(e) => setModifiedValue(e.target.value)}
        disabled={isSaving || isInPast}
        rows={4}
      />
      {hasChanged && (
        <div className="flex gap-2">
          <Button
            className="py-0 w-full flex items-center gap-2 text-sm h-8 mt-2"
            disabled={!hasChanged}
            onClick={async () => {
              await save({
                caseLoadId: data.id,
                treatment: modifiedValue,
              });
            }}
          >
            {isSaving ? (
              <>
                <Loader2 className="w-4 h-4 animate-spin" />
                <p>{t("case-load-manager-saving")}</p>
              </>
            ) : (
              <>
                <Save className="w-4 h-4" />
                <p>{t("case-load-manager-save")}</p>
              </>
            )}
          </Button>
          <Button
            variant="outline"
            className="py-0 w-full flex items-center gap-2 text-sm h-8 mt-2"
            disabled={!hasChanged}
            onClick={() => {
              setModifiedValue(defaultValue);
            }}
          >
            <Undo className="w-4 h-4" />
            <p>{t("case-load-manager-undo")}</p>
          </Button>
        </div>
      )}
    </>
  );
};
