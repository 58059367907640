import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import {
  Settings as SettingsIcon,
  Globe,
  LogOut,
  Moon,
  Sun,
  RefreshCwOff,
  ChevronRight,
  RefreshCw,
} from "lucide-react";
import { useTranslation } from "react-i18next";
import { DutchFlag, GbFlag } from "../../shared/Flags";
import useStores from "../../useStores";
import { useDarkMode } from "../../theme/useDarkMode";
import { useContext, useState } from "react";
import { NavBarOpenStateContext } from "../NavBar/NavBarOpenStateContext";

export const SettingsMenu = () => {
  const { i18n, t } = useTranslation();
  const { authStore } = useStores();
  const [colorTheme, setColorTheme] = useDarkMode();
  const { isNavBarOpen } = useContext(NavBarOpenStateContext)!;
  const [isUsingOldDesign, setIsUsingOldDesign] = useState(
    localStorage.getItem("force-old-design") === "true"
  );

  return (
    <li className="my-[3px] p-2">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div className="flex items-center justify-between w-full hover:text-blue-500 cursor-pointer">
            <div className="flex items-center space-x-2 w-fit">
              <div className="w-5 h-5">
                <SettingsIcon className="w-5 h-5" />
              </div>
              <p className={`${isNavBarOpen ? "visible" : "hidden"}`}>
                Configuratie
              </p>
            </div>

            {isNavBarOpen && (
              <div className="w-4 h-4">
                <ChevronRight className="w-4 h-4" />
              </div>
            )}
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className="w-72"
          style={{ zIndex: 99999 }}
          side="right"
          align="end"
        >
          <DropdownMenuGroup>
            <DropdownMenuSub>
              <DropdownMenuSubTrigger>
                <Globe className="mr-2 h-4 w-4" />
                <span>{t("profile-action-menu-change-language")}</span>
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent className="min-w-32">
                  <DropdownMenuItem
                    className="cursor-pointer flex items-center space-x-2"
                    onClick={async (e) => {
                      e.preventDefault();
                      localStorage.setItem("preferred-lang", "nl");
                      await i18n.changeLanguage("nl");
                    }}
                  >
                    <DutchFlag />
                    <span
                      className={`${
                        i18n.language === "nl" ? "font-bold" : "font-normal"
                      }`}
                    >
                      Nederlands
                    </span>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    className="cursor-pointer flex items-center space-x-2"
                    onClick={async (e) => {
                      e.preventDefault();
                      localStorage.setItem("preferred-lang", "en");
                      await i18n.changeLanguage("en");
                    }}
                  >
                    <GbFlag />
                    <span
                      className={`${
                        i18n.language === "en" ? "font-bold" : "font-normal"
                      }`}
                    >
                      English
                    </span>
                  </DropdownMenuItem>
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            className="cursor-pointer"
            onClick={() => {
              const newValue = !isUsingOldDesign;
              setIsUsingOldDesign(newValue);

              if (newValue) {
                localStorage.setItem("force-old-design", "true");
              } else {
                localStorage.removeItem("force-old-design");
              }

              window.location.reload();
            }}
          >
            {isUsingOldDesign ? (
              <RefreshCw className="mr-2 h-4 w-4" />
            ) : (
              <RefreshCwOff className="mr-2 h-4 w-4" />
            )}
            {isUsingOldDesign
              ? "Switch to the new design"
              : "Switch to the old design"}
          </DropdownMenuItem>
          <DropdownMenuItem
            disabled
            onClick={(e) => {
              e.preventDefault();

              // @ts-ignore todo, figure this out:
              setColorTheme(
                colorTheme == undefined || colorTheme == "light"
                  ? "dark"
                  : "light"
              );
            }}
          >
            <Moon
              className={`mr-2 h-4 w-4 ${
                colorTheme == undefined || colorTheme === "light"
                  ? "visible"
                  : "hidden"
              }`}
            />
            <Sun
              className={`mr-2 h-4 w-4 ${
                colorTheme == undefined || colorTheme === "light"
                  ? "hidden"
                  : "visible"
              }`}
            />
            <span>Toggle dark mode</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            className="cursor-pointer"
            onClick={() => authStore.logOut()}
          >
            <LogOut className="mr-2 h-4 w-4" />
            <span>{t("profile-action-menu-log-out")}</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </li>
  );
};
