import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../components/ui/popover";
import { Button } from "../../../../components/ui/button";
import { CalendarIcon } from "lucide-react";
import { Calendar } from "../../../../components/ui/calendar";
import { useState } from "react";
import { getSimpleFormattedDate } from "../../../caseLoadCockpit/utils";
import { useDownloadCqiReport } from "./hooks";
import { toast } from "sonner";
import moment from "moment";

export default function CqiReport() {
  const [selectedDate, setSelectedDate] = useState(new Date(2022, 0, 1));

  const { mutate, isLoading } = useDownloadCqiReport((data) => {
    const url = window.URL.createObjectURL(
      new Blob([data], { type: "text/csv" })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `cqi_report_${new Date().toLocaleString()}.csv`
    );
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    link.remove();

    toast("Data downloaded", {
      description: "The CQi report has been downloaded.",
    });
  });

  return (
    <main className="p-24">
      <div className="flex item-start justify-between w-full">
        <section>
          <h1 className="font-bold text-4xl">CQi Report</h1>
          <p className="pt-2">Export CQi data for reporting purposes.</p>
        </section>
      </div>

      <div className="mt-6 flex items-end space-x-2">
        <CalendarFilter
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
        <Button
          className="px-4"
          onClick={async () =>
            await mutate({
              fromDate: moment(selectedDate).utc(true).toISOString(),
            })
          }
        >
          {isLoading ? "Downloading..." : "Download"}
        </Button>
      </div>
    </main>
  );
}

const CalendarFilter = ({
  selectedDate,
  setSelectedDate,
}: {
  selectedDate: Date;
  setSelectedDate: (newDate: Date) => void;
}) => {
  const [isDateSelectorOpen, setIsDateSelectorOpen] = useState(false);

  return (
    <div>
      <p className="font-medium pb-1">From</p>
      <Popover open={isDateSelectorOpen} onOpenChange={setIsDateSelectorOpen}>
        <PopoverTrigger asChild>
          <Button
            className="px-6 flex items-center space-x-2"
            variant="outline"
          >
            <span>{getSimpleFormattedDate(selectedDate)}</span>
            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="z-[999999]" align="start">
          <Calendar
            mode="single"
            toDate={new Date()}
            defaultMonth={selectedDate}
            selected={selectedDate}
            onSelect={(_, d) => {
              setSelectedDate(d);
              setIsDateSelectorOpen(false);
            }}
            initialFocus
          />
        </PopoverContent>
      </Popover>
    </div>
  );
};
