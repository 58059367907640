import { ColumnDef } from "@tanstack/react-table";
import { ReferredPatient } from "../../../types";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectGroup,
  SelectValue,
} from "../../../../../components/ui/select";
import { useState } from "react";
import { useUpdateTodaysManualStatus } from "../../../hooks";
import { useTranslation } from "react-i18next";

export const ManualStatusColName = "Today's Status";

export const ManualStatusCol: ColumnDef<ReferredPatient> = {
  id: ManualStatusColName,
  accessorKey: "todayStatus",
  size: 250,
  minSize: 10,
  header: () => {
    const { t } = useTranslation();
    return <p>{t("crm-daily-activity-tracker-col-caption")}</p>;
  },
  cell: ({ row }) => {
    const { t } = useTranslation();

    const [value, setValue] = useState(
      row.original.todayStatus?.toString() ?? "NotCalledYet"
    );

    const { mutate: save, isLoading: isSaving } = useUpdateTodaysManualStatus();

    return (
      <Select
        value={value}
        disabled={isSaving}
        onValueChange={async (x: string) => {
          setValue(x);
          await save({
            referralId: row.original.id,
            value: x,
          });
        }}
      >
        <SelectTrigger className="w-full">
          <SelectValue />
        </SelectTrigger>
        <SelectContent className={`z-[9999999] max-h-[30vh]`}>
          <SelectGroup>
            <SelectItem value={"NotCalledYet"}>
              {t("crm-daily-activity-tracker-not-called-yet")}
            </SelectItem>
            <SelectItem value={"CalledButWithoutResponse"}>
              {t("crm-daily-activity-tracker-called-but-without-response")}
            </SelectItem>
            <SelectItem value={"CalledAndActionNeeded"}>
              {t("crm-daily-activity-tracker-called-and-action-needed")}
            </SelectItem>
            <SelectItem value={"NotCalledDueToNoActionNeed"}>
              {t("crm-daily-activity-tracker-not-called-due-to-no-action-need")}
            </SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
    );
  },
};
