import React, { useState } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { observer } from "mobx-react";
import RouteWithLayout from "./layouts/RouteWithLayout";
import { Main } from "./layouts/Main/Main";
import MainOld from "./layouts/Main/MainOld";
import useStores from "./useStores";
import dossierType from "./utils/constants/dossierType";
import Loading from "./shared/Loading";
import configureI18n from "./i18n";
import { useGetAuthorizations } from "./shared/hooks/useGetAuthorizations";
import { useGetTranslations } from "./shared/hooks/useGetTranslations";
import { ApplicationInitializing } from "./shared/ApplicationInitializing";

// Pages
import WelcomePage from "./pages/welcome/WelcomePage";
import WelcomePageOld from "./pages/welcome/WelcomePageOld";
import CreatePatientPage from "./pages/createPatient";
import CreateDossierGgzPage from "./pages/createDossierGgz";
import CreateDossierTransferPage from "./pages/createDossierTransfer";
import DossierGgzsPage from "./pages/dossierGgzs";
import DossierTransfersPage from "./pages/dossierTransfers";
import DossierOpenUpsPage from "./pages/dossierOpenUps";
import CalendarPage from "./pages/calendar";
import PatientsPage from "./pages/patients";
import PatientPage from "./pages/patient";
import OnboardingPage from "./pages/onboarding";
import DossierGgzPage from "./pages/dossierGgz";
import DossierTransferPage from "./pages/dossierTransfer";
import DossierOpenUpPage from "./pages/dossierOpenUp";
import AdminPage from "./pages/admin";
import UpdatePsychologistTagsPage from "./pages/psychologists";
import CaseLoadCockpit from "./pages/caseLoadCockpit/index.tsx";
import CustomerRelationshipManager from "./pages/customerRelationshipManager/index.tsx";
import CaseLoadManagerAdmin from "./pages/caseLoadCockpit/admin/index.tsx";
import CalendarAlgorithmAdmin from "./pages/calendar/admin/index.tsx";
import AkwaGgzReportGenerationAdmin from "./pages/akwaGgzReport/admin/index.tsx";
import ManageNews from "./pages/admin/news/manageNews";
import CrmConfigAdmin from "./pages/customerRelationshipManager/admin/configuration/index.tsx";
import CrmStatisticsAdmin from "./pages/customerRelationshipManager/admin/statistics/index.tsx";
import CrmUpcomingReminders from "./pages/customerRelationshipManager/admin/upcomingReminders/index.tsx";
import BigRegisterAdmin from "./pages/bigRegisterReport/index.tsx";
import SendQuestionnairesToPraktijkdataAdmin from "./pages/questionnaires/admin/sendToPraktijkData";
import CohortStudyReportAdmin from "./pages/questionnaires/admin/cohortStudyReport";
import CqiReportAdmin from "./pages/questionnaires/admin/cqiReport";
import GermanClients from "./pages/iPracticeGermany/clients/index.tsx";
import PreIntakeReport from "./pages/preIntake/report";
import EmptyIntakeSpotsReport from "./pages/emptySpotsIntakeReport/report";
import UnauthorizedPage from "./pages/errors/unauthorized";
import NotFoundPage from "./pages/errors/notFound";

const AuthorizedTranslatedRouter = () => {
  const { authStore } = useStores();

  // we can't only rely on react-query's 'isLoading' here -> we do extra steps in i18n's config after the data is loaded
  // (populating i18n's internal 'store' with the translations that takes a couple ms)
  const [translationsLoaded, setTranslationsLoaded] = useState(false);

  const {
    data: authorization,
    isLoading: isLoadingAuthorization,
    isError: isAuthorizationError,
  } = useGetAuthorizations();

  const { data: translations, isLoading: isLoadingTranslations } =
    useGetTranslations();

  if (!isLoadingTranslations) {
    configureI18n(translations, setTranslationsLoaded);
  }

  if (isLoadingAuthorization || isLoadingTranslations) {
    return <ApplicationInitializing />;
  }

  if (isAuthorizationError) {
    return <UnauthorizedPage />;
  }

  if (!translationsLoaded) {
    return <ApplicationInitializing />;
  }

  authStore.setAuthorization(authorization);

  const ObservableRouter = observer(Router);

  return <ObservableRouter authStore={authStore} />;
};

const Router = ({ authStore }) => {
  if (authStore.username === undefined) {
    return (
      <div
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading fullWidth withSpinner large />
      </div>
    );
  }

  const isUsingOldDesign = localStorage.getItem("force-old-design") === "true";

  const Layout = isUsingOldDesign ? MainOld : Main;

  return (
    <BrowserRouter>
      <RouteWithLayout
        component={isUsingOldDesign ? WelcomePageOld : WelcomePage}
        exact
        layout={Layout}
        path="/"
      />

      <RouteWithLayout
        component={isUsingOldDesign ? WelcomePageOld : WelcomePage}
        exact
        layout={Layout}
        path="/welcome"
      />

      <RouteWithLayout
        layout={Layout}
        component={PatientsPage}
        exact
        path="/patients"
      />
      <RouteWithLayout
        layout={Layout}
        component={PatientPage}
        exact
        path="/patient/:patientId/"
      />
      <RouteWithLayout
        layout={Layout}
        component={CreatePatientPage}
        exact
        path="/create-patient"
      />

      <RouteWithLayout
        layout={Layout}
        component={DossierGgzsPage}
        exact
        path="/dossiers-ggz"
      />
      <RouteWithLayout
        layout={Layout}
        component={DossierGgzPage}
        exact
        path={`/patient/:patientId/${dossierType.GGZ}/:dossierId/`}
      />
      <RouteWithLayout
        layout={Layout}
        component={DossierGgzPage}
        exact
        path={`/patient/:patientId/${dossierType.ADHD}/:dossierId/`}
      />
      <RouteWithLayout
        layout={Layout}
        component={DossierGgzPage}
        exact
        path={`/patient/:patientId/${dossierType.GGZ}/:dossierId/:tab`}
      />
      <RouteWithLayout
        layout={Layout}
        component={DossierGgzPage}
        exact
        path={`/patient/:patientId/${dossierType.ADHD}/:dossierId/:tab`}
      />
      <RouteWithLayout
        layout={Layout}
        component={CreateDossierGgzPage}
        exact
        path="/patient/:patientId/dossier-ggz/create"
      />

      <RouteWithLayout
        layout={Layout}
        component={DossierTransfersPage}
        exact
        path="/dossiers-transfer"
      />
      <RouteWithLayout
        layout={Layout}
        component={DossierTransferPage}
        exact
        path={`/patient/:patientId/${dossierType.TRANSFER}/:dossierId`}
      />
      <RouteWithLayout
        layout={Layout}
        component={CreateDossierTransferPage}
        exact
        path="/patient/:patientId/dossier-transfer/create"
      />

      <RouteWithLayout
        layout={Layout}
        component={DossierOpenUpsPage}
        exact
        path="/dossiers-open-up-deprecated"
      />
      <RouteWithLayout
        layout={Layout}
        component={DossierOpenUpPage}
        exact
        path={`/patient/:patientId/${dossierType.OPEN_UP}-deprecated/:dossierId`}
      />

      <RouteWithLayout
        layout={Layout}
        component={CalendarPage}
        exact
        path="/calendar"
      />

      <RouteWithLayout
        layout={Layout}
        component={OnboardingPage}
        exact
        path="/onboarding"
      />

      <RouteWithLayout
        layout={Layout}
        component={CaseLoadCockpit}
        exact
        path="/case-load"
      />

      <RouteWithLayout
        layout={Layout}
        component={CustomerRelationshipManager}
        exact
        path="/patient-referrals"
      />

      <RouteWithLayout
        layout={Layout}
        component={GermanClients}
        exact
        path="/germany/clients"
      />

      <RouteWithLayout
        layout={Layout}
        component={PreIntakeReport}
        exact
        path="/reports/pre-intake"
      />

      <RouteWithLayout
        layout={Layout}
        component={EmptyIntakeSpotsReport}
        exact
        path="/reports/empty-intake-spots"
      />

      <RouteWithLayout
        layout={Layout}
        component={AdminPage}
        exact
        path="/admin/users/roles"
      />
      <RouteWithLayout
        layout={Layout}
        component={UpdatePsychologistTagsPage}
        exact
        path="/admin/users/tags"
      />
      <RouteWithLayout
        layout={Layout}
        component={ManageNews}
        exact
        path="/admin/news"
      />

      <RouteWithLayout
        layout={Layout}
        component={CalendarAlgorithmAdmin}
        exact
        path="/admin/calendar-algorithm-admin"
      />
      <RouteWithLayout
        layout={Layout}
        component={CaseLoadManagerAdmin}
        exact
        path="/admin/case-load-manager"
      />
      <RouteWithLayout
        layout={Layout}
        component={AkwaGgzReportGenerationAdmin}
        exact
        path="/admin/reports/akwa-ggz"
      />
      <RouteWithLayout
        layout={Layout}
        component={BigRegisterAdmin}
        exact
        path="/admin/reports/big-register"
      />
      <RouteWithLayout
        layout={Layout}
        component={CohortStudyReportAdmin}
        exact
        path="/admin/reports/cohort-study"
      />
      <RouteWithLayout
        layout={Layout}
        component={CqiReportAdmin}
        exact
        path="/admin/reports/cqi"
      />

      <RouteWithLayout
        layout={Layout}
        component={CrmConfigAdmin}
        exact
        path="/admin/patient-referrals/config"
      />

      <RouteWithLayout
        layout={Layout}
        component={CrmStatisticsAdmin}
        exact
        path="/reports/patient-referrals/statistics"
      />

      <RouteWithLayout
        layout={Layout}
        component={CrmUpcomingReminders}
        exact
        path="/admin/patient-referrals/upcoming-reminders"
      />

      <RouteWithLayout
        layout={Layout}
        component={SendQuestionnairesToPraktijkdataAdmin}
        exact
        path="/admin/send-questionnaires-to-praktijkdata"
      />

      {/* <RouteWithLayout  layout={Layout} component={NotFoundPage} path="*" exact={false} /> */}
    </BrowserRouter>
  );
};

export default AuthorizedTranslatedRouter;
