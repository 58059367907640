import { ColumnDef } from "@tanstack/react-table";
import { ReferredPatient } from "../../../types";
import { getSimpleFormattedDateTime } from "../../../../caseLoadCockpit/utils";
import { useTranslation } from "react-i18next";

export const PreIntakeOnColName = "Pre-Intake Date";

export const PreIntakeOnCol: ColumnDef<ReferredPatient> = {
  id: PreIntakeOnColName,
  accessorKey: "preIntakeOn",
  size: 175,
  minSize: 100,
  header: ({ column }) => {
    const { t } = useTranslation();
    return <p>Pre-Intake Date</p>;
  },
  cell: ({ getValue }) => {
    const dateAsString = getValue()?.toString();

    if (!dateAsString) {
      return <></>;
    }

    return <p>{getSimpleFormattedDateTime(dateAsString)}</p>;
  },
};
