export const formatDateTime = (dateTimeString: Date | string) => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: "short",
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  const dateTime = new Date(dateTimeString);

  const day = new Intl.DateTimeFormat("en", { day: "numeric" }).format(
    dateTime
  );
  const formattedDay =
    day +
    (day.endsWith("1") && day !== "11"
      ? "st"
      : day.endsWith("2") && day !== "12"
      ? "nd"
      : day.endsWith("3") && day !== "13"
      ? "rd"
      : "th");

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  const formattedTime = new Intl.DateTimeFormat("en", timeOptions).format(
    dateTime
  );

  const formattedDate = new Intl.DateTimeFormat("en", options).format(dateTime);

  return `${formattedDate.replace("{day}", formattedDay)}: ${formattedTime}`;
};
