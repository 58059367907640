import { useQuery } from "react-query";
import axios from "axios";
import { useState } from "react";
import { Loader2 } from "lucide-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import { PsychologistFilter } from "../psychologistFilter";

type ResultPerEmployee = {
  epdId: number;
  workingSlots: number[];
  occupiedSlots: number[];
  predictedOccupiedSlots: number[];
  predictedOccupancyCurrentTimeBlock: number[];
  predictedOccupancyUntilWeek16: number[];
  availableSlots: number[];
  availableSlotsCorrectedForVacation: number[];
  trajectories: number[];
  vacations: number[];
  alternativeSlots: number[];
  dossiers: {
    dossierId: number;
    values: number[];
  }[];
};

export const AlgorithmOutput = () => {
  const [selectedPsy, setSelectedPsy] = useState("");

  const { data, isFetching } = useQuery<ResultPerEmployee>(
    ["calendar-admin", "output", selectedPsy],
    async () => {
      const res = await axios.get(
        `calendaradmin/algorithm-output/${selectedPsy}`
      );
      return res.data;
    },
    {
      enabled: selectedPsy !== "",
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <div>
      <h1 className="text-xl font-bold">
        View the occupancy prediction algorithm's output
      </h1>
      <p>
        Every few minutes, this data gets automatically refreshed to the latest
        algorithm calculation's results.
      </p>

      <PsychologistFilter
        selectedPsy={selectedPsy}
        setSelectedPsy={setSelectedPsy}
      />

      {isFetching && (
        <div className="flex items-center space-x-2 p-4">
          <Loader2 className="w-4 h-4 animate-spin" />
          <p>Loading result...</p>
        </div>
      )}

      {data && (
        <Table className="mt-8 border rounded">
          <TableHeader>
            <TableRow>
              <TableHead className="w-1/4">Entry</TableHead>
              <TableHead>Period 1</TableHead>
              <TableHead>Period 2</TableHead>
              <TableHead>Period 3</TableHead>
              <TableHead>Period 4</TableHead>
              <TableHead>Period 5</TableHead>
              <TableHead>Period 6</TableHead>
              <TableHead>Period 7</TableHead>
              <TableHead>Period 8</TableHead>
              <TableHead>Period 9</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <Row label="Working slots" values={data.workingSlots} />
            <Row label="Occupied slots" values={data.occupiedSlots} />
            <Row
              label="Predicted occupied slots"
              values={data.predictedOccupiedSlots}
            />
            <Row
              label="Predicted Occupancy current time block"
              values={data.predictedOccupancyCurrentTimeBlock}
            />
            <Row
              label="Predicted Occupancy until week 16"
              values={data.predictedOccupancyUntilWeek16}
            />
            <Row label="Available slots" values={data.availableSlots} />
            <Row
              label="Available slots, corrected for vacation"
              values={data.availableSlotsCorrectedForVacation}
            />
            <Row label="Trajectories" values={data.trajectories} />
            <Row label="Vacations" values={data.vacations} />
            <Row label="Alternative slots" values={data.alternativeSlots} />
            {data.dossiers?.map((dossier) => (
              <Row
                label={`Dossier ${dossier.dossierId}`}
                values={dossier.values}
              />
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

const Row = ({ label, values }: { label: string; values: number[] }) => {
  return (
    <TableRow>
      <TableCell>{label}</TableCell>
      <TableCell>{values[0]}</TableCell>
      <TableCell>{values[1]}</TableCell>
      <TableCell>{values[2]}</TableCell>
      <TableCell>{values[3]}</TableCell>
      <TableCell>{values[4]}</TableCell>
      <TableCell>{values[5]}</TableCell>
      <TableCell>{values[6]}</TableCell>
      <TableCell>{values[7]}</TableCell>
      <TableCell>{values[8]}</TableCell>
    </TableRow>
  );
};
