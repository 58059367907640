import { useTranslation } from "react-i18next";

export const Loader = ({ isLoading }: { isLoading: boolean }) => {
  const { t } = useTranslation();

  return (
    <>
      {isLoading && (
        <div className="flex justify-center p-4 opacity-80">
          {t("case-load-manager-loading")}
        </div>
      )}
    </>
  );
};
