import React, { useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import { styled } from "@mui/system";
import ListItem from "@mui/material/ListItem";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const StyledListItem = styled(ListItem)(() => ({
  display: "block",
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: "64px",
}));

const StyledListItemLeaf = styled(ListItem)(() => ({
  display: "flex",
  paddingTop: 0,
  paddingBottom: 0,
}));

const StyledButton = styled(Button)(({ theme }) => {
  return {
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    color: theme.palette.grey[800],
    "&.active": {
      color: theme.palette.primary.main,
      display: "flex",
      alignItems: "center",
      marginRight: theme.spacing(1),
    },
  };
});

const StyledButtonLeaf = styled(Button)(({ theme }) => ({
  buttonLeaf: {
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightRegular,
    "&.depth-0": {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
}));

const StyledExpandLessIcon = styled(ExpandLessIcon)(() => ({
  marginLeft: "auto",
  height: 16,
  width: 16,
}));

const StyledExpandMoreIcon = styled(ExpandMoreIcon)(() => ({
  marginLeft: "auto",
  height: 16,
  width: 16,
}));

const StyledLabel = styled("span")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginLeft: "auto",
}));

function NavItem({
  title,
  href,
  depth,
  children,
  icon: Icon,
  className,
  open: openProp,
  label: Label,
  ...rest
}) {
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  if (children) {
    return (
      <StyledListItem {...rest} disableGutters key={title}>
        <StyledButton onClick={handleToggle} style={style}>
          {Icon && <Icon />}
          {title}
          {open ? (
            <StyledExpandLessIcon color="inherit" />
          ) : (
            <StyledExpandMoreIcon color="inherit" />
          )}
        </StyledButton>
        <Collapse in={open}>{children}</Collapse>
      </StyledListItem>
    );
  }

  return (
    <StyledListItemLeaf {...rest} disableGutters key={title}>
      <StyledButton component={RouterLink} exact to={href}>
        <div style={{ display: "flex", alignItems: "center" }}></div>
        {Icon && (
          <div
            style={{
              opacity: "0.8",
              marginRight: "8px",
              width: "24px",
              height: "24px",
            }}
          >
            <Icon />
          </div>
        )}
        {title}
        {Label && <StyledLabel>{Label}</StyledLabel>}
      </StyledButton>
    </StyledListItemLeaf>
  );
}

NavItem.defaultProps = {
  open: false,
};

export default NavItem;
