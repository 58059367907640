import React, { useEffect } from 'react';
import {
 CardHeader, Divider, CardContent, Grid, Button, InputAdornment, CardActions, TextField, Card
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { observer } from "mobx-react";
import useStores from "../../useStores";
import Loading from "../../shared/Loading";
import dossierType from "../../utils/constants/dossierType";

const CreateDossierTransfer = ({ patientId }) => {
    const { dossiersStore, patientStore } = useStores();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        patientStore.fetchPatient(patientId);

        return function cleanup() {
            patientStore.reset();
        };
    }, [patientStore, patientId]);

    if (patientStore.patient === null || typeof patientStore.patient === 'undefined') {
        return <Loading />;
    }

    return (
      <Card>
        <Formik
          enableReinitialize
          initialValues={{
                    epdPatientId: '',
                    epdFileId: 1,
                    bsn: patientStore.patient.bsn ? patientStore.patient.bsn : '',
                }}
          onSubmit={(values, { resetForm, setSubmitting }) => {
                    values.patientId = patientId;
                    dossiersStore.createDossierTransfer(values)
                        .then(
                            (createdDossier) => {
                                enqueueSnackbar('Dossier aangemaakt', { variant: 'success' });
                                resetForm();
                                history.push(`/patient/${patientId}/${dossierType.TRANSFER}/${createdDossier.id}`);
                            },
                            (error) => {
                                enqueueSnackbar(error.response.data, { variant: 'error' });
                                setSubmitting(false);
                            }
                        );
                }}
          validationSchema={Yup.object().shape({
                    epdPatientId: Yup.number()
                        .integer()
                        .positive()
                        .required(),
                    epdFileId: Yup.number()
                        .integer()
                        .positive()
                        .required(),
                    bsn: Yup.string()
                        .required()
                        .min(8, "Het burger service nummer moet minimaal 8 nummers hebben.")
                        .max(9, "Het burger service nummer moet maximaal 9 nummers hebben."),
                })}
        >
          {(props) => {
                    const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = props;
                    return (
                      <form
                        autoComplete="off"
                        noValidate
                      >
                        <CardHeader title="Transfer dossier aanmaken" />
                        <Divider />
                        <CardContent>
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                required
                                label="Cliënt nr."
                                margin="dense"
                                name="epdPatientId"
                                onChange={handleChange}
                                value={values.epdPatientId}
                                variant="outlined"
                                type="number"
                                onBlur={handleBlur}
                                error={(errors.epdPatientId && touched.epdPatientId)}
                                helperText={(errors.epdPatientId && touched.epdPatientId) ? errors.epdPatientId : "Client nummer uit praktijkdata"}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                label="Dossier nr."
                                InputProps={{
                                       startAdornment: <InputAdornment position="start">
                                                        {values.epdPatientId}
                                                        .
                                                       </InputAdornment>,
                                                   }}
                                margin="dense"
                                name="epdFileId"
                                onChange={handleChange}
                                required
                                value={values.epdFileId}
                                variant="outlined"
                                type="number"
                                onBlur={handleBlur}
                                error={(errors.epdFileId && touched.epdFileId)}
                                helperText={(errors.epdFileId && touched.epdFileId) ? errors.epdFileId : "Dossier nummer uit praktijkdata"}
                              />
                            </Grid>

                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                required
                                label="BSN"
                                margin="dense"
                                name="bsn"
                                onChange={handleChange}
                                value={values.bsn}
                                variant="outlined"
                                onBlur={handleBlur}
                                error={(errors.bsn && touched.bsn)}
                                helperText={(errors.bsn && touched.bsn) && errors.bsn}
                                InputLabelProps={{ shrink: values.bsn !== '' }}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                        <Divider />
                        <CardActions>
                          <Button
                            color="primary"
                            variant="contained"
                            disabled={isSubmitting}
                            onClick={handleSubmit}
                          >
                            Aanmaken
                          </Button>
                        </CardActions>
                      </form>
                    );
                }}
        </Formik>
      </Card>
    );
};

export default observer(CreateDossierTransfer);
