// React
import React from "react";
import { createRoot } from "react-dom/client";
import "./styles.css";

// Misc
import { configure } from "mobx";
import * as Sentry from "@sentry/browser";
import * as moment from "moment";
import "moment/locale/nl";

// Providers
import {
  ThemeProvider,
  StyledEngineProvider,
  CssBaseline,
} from "@mui/material";
import { queryClient } from "./react-query/provider";
import { QueryClientProvider } from "react-query";
import { SnackbarProvider } from "notistack";
import theme from "./theme";

// Initializations
import AxiosSetup, { sentryDontLog } from "./AxiosSetup";
import AuthorizedTranslatedRouter from "./Router";
import { Toaster } from "./components/ui/sonner";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://69271d9da8e24bed8a8c4eaf0ac5ce48@sentry.io/1528159",
    ignoreErrors: [sentryDontLog],
    release: process.env.REACT_APP_GIT_COMMIT_HASH,
  });
}

configure({ enforceActions: "always" }); // https://mobx.js.org/best/actions.html

moment.locale(localStorage.getItem("preferred-lang") ?? "nl");

const App = () => {
  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider>
            <AxiosSetup />
            <QueryClientProvider client={queryClient}>
              <AuthorizedTranslatedRouter />
            </QueryClientProvider>
            <Toaster />
          </SnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

const root = createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
