import { getSimpleFormattedDate } from "../../../../../caseLoadCockpit/utils";
import { useGetInflowTrend } from "../../../hooks";
import { Skeleton } from "../../../../../../components/ui/skeleton";
import { Area, AreaChart, CartesianGrid, XAxis } from "recharts";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../../../../../components/ui/chart";

const TrendsChart = () => {
  const { data, isLoading } = useGetInflowTrend();

  if (isLoading)
    return <Skeleton className="w-full h-[250px] rounded mt-2 bg-slate-100" />;

  const formattedData = data!.map((d) => ({
    date: getSimpleFormattedDate(d.date),
    count: d.count,
  }));

  return (
    <ChartContainer
      config={{
        count: {
          label: "Count",
        },
      }}
      className="mx-auto max-h-[250px]"
    >
      <AreaChart
        accessibilityLayer
        data={formattedData}
        margin={{
          left: 12,
          right: 12,
        }}
      >
        <CartesianGrid vertical={false} />
        <XAxis dataKey="date" tickLine={true} axisLine={true} tickMargin={8} />
        <ChartTooltip
          cursor={false}
          content={<ChartTooltipContent indicator="line" />}
        />
        <Area
          dataKey="count"
          type="natural"
          fill="#0088FE"
          fillOpacity={0.4}
          stroke="#0088FE"
        />
      </AreaChart>
    </ChartContainer>
  );
};

export default TrendsChart;
