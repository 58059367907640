import * as React from "react";
import {
  Column,
  ColumnDef,
  Header,
  HeaderGroup,
  flexRender,
} from "@tanstack/react-table";
import { Button } from "./button";
import { ChevronUp, ChevronDown, ChevronsUpDown } from "lucide-react";
import { ScrollArea } from "./scroll-area";
import { useDrag, useDrop } from "react-dnd";
import { cn } from "../../lib/utils";

export type ScrollableTableHeight = {
  overflowManagedExternally?: boolean;
  limitHeight?: boolean;
  maxHeight?:
    | "max-h-[20vh]"
    | "max-h-[30vh]"
    | "max-h-[40vh]"
    | "max-h-[50vh]"
    | "max-h-[60vh]"
    | "max-h-[70vh]"
    | "max-h-[80vh]";
};

const Table = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement> & ScrollableTableHeight
>(({ className, limitHeight, maxHeight, overflowManagedExternally, ...props }, ref) => {
  const CustomTable = () => {
    return (
      <table
        ref={ref}
        className={cn("w-full caption-bottom text-sm", className)}
        {...props}
      />
    );
  };

  // todo
  if (overflowManagedExternally) {
    return <CustomTable />;
  }

  if (!limitHeight) {
    return (
      <div className="relative w-full overflow-auto">
        <CustomTable />
      </div>
    );
  }

  return (
    <ScrollArea className={`${maxHeight ?? "max-h-[500px]"} overflow-y-auto`}>
      <CustomTable />
    </ScrollArea>
  );
});
Table.displayName = "Table";

const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead
    ref={ref}
    className={cn("sticky top-0 [&_tr]:border-b", className)}
    {...props}
  />
));
TableHeader.displayName = "TableHeader";

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tbody
    ref={ref}
    className={cn("[&_tr:last-child]:border-0", className)}
    {...props}
  />
));
TableBody.displayName = "TableBody";

const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={cn(
      "border-t bg-muted/50 font-medium [&>tr]:last:border-b-0",
      className
    )}
    {...props}
  />
));
TableFooter.displayName = "TableFooter";

const TableRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement>
>(({ className, ...props }, ref) => (
  <tr
    ref={ref}
    className={cn(
      "border-b transition-colors bg-slate-50 hover:bg-slate-100 dark:bg-slate-950 dark:hover:bg-slate-900 data-[state=selected]:bg-muted",
      className
    )}
    {...props}
  />
));
TableRow.displayName = "TableRow";

const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      "h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0 bg-background dark:bg-slate-900",
      className
    )}
    {...props}
  />
));
TableHead.displayName = "TableHead";

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  any
  // React.ThHTMLAttributes<HTMLTableCellElement>
>(({ className, size, ...props }, ref) => {
  return (
    <td
      ref={ref}
      style={{
        width: size,
      }}
      className={cn(
        "p-2 align-middle [&:has([role=checkbox])]:pr-0 pl-4",
        className
      )}
      {...props}
    />
  );
});
TableCell.displayName = "TableCell";

const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={cn("mt-4 text-sm text-muted-foreground", className)}
    {...props}
  />
));
TableCaption.displayName = "TableCaption";

const SortableCell = <TColumn extends unknown>({
  column,
  label,
  center,
}: {
  column: Column<TColumn, unknown>;
  label: string;
  center?: boolean;
}) => {
  const getIcon = () => {
    const sortInfo = column.getIsSorted();

    switch (sortInfo) {
      case "asc":
        return <ChevronDown className="h-3 w-3  text-blue-500" />;
      case "desc":
        return <ChevronUp className="h-3 w-3 text-blue-500" />;
      default:
        return <ChevronsUpDown className="h-3 w-3" />;
    }
  };

  return (
    <div className={`${center && "flex justify-center"}`}>
      <Button
        className={`flex items-center whitespace-normal gap-2 text-left h-fit ${
          center ? "ml-3" : "-ml-4"
        }`}
        variant="ghost"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
      >
        <p>{label}</p>
        <div className="w-3 h-3">{getIcon()}</div>
      </Button>
    </div>
  );
};

const ColumnResizer = ({ header }: { header: Header<any, unknown> }) => {
  if (header.column.getCanResize() === false) return <></>;

  return (
    <div
      {...{
        onMouseDown: header.getResizeHandler(),
        onTouchStart: header.getResizeHandler(),
        className: `absolute top-0 right-0 cursor-col-resize w-1 hover:w-2 h-full bg-gray-50 dark:bg-slate-800 hover:bg-gray-300 hover:bg-slate-700 mr-1`,
        style: {
          userSelect: "none",
          touchAction: "none",
        },
      }}
    />
  );
};

interface DraggableColProps<T> {
  col: Column<T>;
  header: Header<T, unknown>;
  headerGroup: HeaderGroup<T>;
  columnOrder: {
    id: string;
    index: number;
  }[];
  columns: ColumnDef<T>[];
  setColumns: (newColumns: ColumnDef<T>[]) => void;
}

const DraggableCol = <T,>({
  col,
  header,
  headerGroup,
  columnOrder,
  columns: mutableColumns,
  setColumns: setMutableColumns,
}: DraggableColProps<T>) => {
  const reorderCol = (draggedColIndex: number, targetColIndex: number) => {
    let newColumns = [...mutableColumns];

    // newColumns contains "hidden" columns.
    // when we reorder, we should only reorder the visible columns
    // but newColumns SHOULD contain still the hidden columns too, we just "jump over" those
    const visibleColIds = headerGroup.headers.map((h) => h.id);

    let visibleColumns = newColumns.filter((col) => {
      // @ts-ignore - we know ID exists, type definitions lags behind
      return visibleColIds.includes(col.id);
    });

    let draggedCol = visibleColumns.splice(draggedColIndex, 1)[0];
    visibleColumns.splice(targetColIndex, 0, draggedCol);

    // merge the reordered visible columns back into newColumns (~re-add hidden columns)
    const res = newColumns.map((col) =>
      // @ts-ignore - we know ID exists, type definitions lags behind
      visibleColIds.includes(col.id) ? visibleColumns.shift() : col
    );

    setMutableColumns(res as ColumnDef<T>[]);
  };

  const [, dropRef] = useDrop({
    accept: "column",
    drop: (draggedCol: Column<any>) => {
      const draggedColIndex = columnOrder.filter(
        (x) => x.id === draggedCol.columnDef.id
      )[0].index;
      const colIndex = columnOrder.filter((x) => x.id === col.columnDef.id)[0]
        .index;
      reorderCol(draggedColIndex, colIndex);
    },
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => col,
    type: "column",
  });

  return (
    <TableHead
      ref={previewRef}
      className={`relative overflow-auto break-words ${
        isDragging ? "opacity-50" : "opacity-100"
      }`}
      style={{
        width: header.getSize(),
      }}
    >
      <TableCell ref={dropRef} className="pl-0">
        <div ref={dragRef} className="cursor-move">
          {flexRender(header.column.columnDef.header, header.getContext())}
        </div>
        <ColumnResizer header={header} />
      </TableCell>
    </TableHead>
  );
};

export {
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  TableCaption,
  SortableCell,
  ColumnResizer,
  DraggableCol,
};
