import {
  useConfirmPraktijkDataPatientApproval,
  useManuallySyncPraktijkDataPatientApproval,
} from "../../hooks";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { Input } from "../../../../components/ui/input";
import { Button } from "../../../../components/ui/button";
import { useState } from "react";
import { toast } from "sonner";
import { ReferralDetails } from "../../types";
import { CircleCheckBig } from "lucide-react";
import { Separator } from "../../../../components/ui/separator";
import { MedebehandelaarsWarning } from "./medebehandelaarsWarning";

const title = "Approve patient";

export const ApprovePatientRow = ({
  referral,
  stepNumber,
}: {
  referral: ReferralDetails;
  stepNumber: number;
}) => {
  // awaiting patient registration
  if (referral.status === "RegisterPatientAndIntake") {
    return <AwaitingRegisterPatientAndIntakeRow stepNumber={stepNumber} />;
  }

  // already submitted, waiting praktijkdata approval
  if (referral.status === "AwaitingPatientApproval") {
    return (
      <AwaitingApprovalRegisterPatientRow
        referral={referral}
        stepNumber={stepNumber}
      />
    );
  }

  return (
    <ReadOnlyApprovePatientRow referral={referral} stepNumber={stepNumber} />
  );
};

const AwaitingRegisterPatientAndIntakeRow = ({
  stepNumber,
}: {
  stepNumber: number;
}) => {
  return (
    <div>
      <p className="text-lg font-medium leading-none">
        {stepNumber}. {title}
      </p>
      <Separator className="my-1" />

      <p className="opacity-70">
        Please complete the previous steps, before approving the patient.
      </p>
    </div>
  );
};

const AwaitingApprovalRegisterPatientRow = ({
  referral,
  stepNumber,
}: {
  referral: ReferralDetails;
  stepNumber: number;
}) => {
  const [failureDialogVisible, setFailureDialogVisible] = useState(false);

  const { mutate: save, isLoading: isSaving } =
    useConfirmPraktijkDataPatientApproval(
      () => {
        toast("Patient updated", {
          description: "The patient has been synchronized with Praktijkdata",
        });
      },
      () => {
        setFailureDialogVisible(true);
      }
    );

  return (
    <div>
      <p className="text-lg font-medium leading-none">
        {stepNumber}. {title}
      </p>
      <Separator className="my-1" />

      <div className="text-xs space-y-2">
        <p className="w-full">
          The patient is awaiting approval in Praktijkdata. Please navigate in
          Praktijkdata to Cliënten →{" "}
          <a
            href="https://start.praktijkdata.nl/app/clienten/aanmeldingen"
            target="_blank"
            className="text-link hover:underline cursor-pointer"
          >
            Aanmeldingen
          </a>
          , and convert the registration into a dossier.
        </p>

        <MedebehandelaarsWarning
          intakeBy={referral.intakeByPsychologistName!}
          preIntakeBy={referral.preIntakeByPsychologistName!}
        />
      </div>

      <Button
        className="mt-2 flex items-center space-x-2 px-4"
        onClick={async () => {
          if (!isSaving) {
            await save({ referralId: referral.id });
          }
        }}
      >
        <div className="h-4 w-4">
          <CircleCheckBig className="h-4 w-4" />
        </div>
        <p>{isSaving ? "Saving..." : "Patient is approved in Praktijkdata"}</p>
      </Button>

      <ManualSyncDialog
        referralId={referral.id}
        open={failureDialogVisible}
        setOpen={setFailureDialogVisible}
      />
    </div>
  );
};

const ReadOnlyApprovePatientRow = ({
  referral,
  stepNumber,
}: {
  referral: ReferralDetails;
  stepNumber: number;
}) => {
  return (
    <div>
      <p className="text-lg font-medium leading-none">
        {stepNumber}. {title}
      </p>
      <Separator className="my-1" />

      <div className="flex items-center space-x-2 opacity-70 text-xs">
        <div className="h-4 w-4">
          <CircleCheckBig className="h-4 w-4" />
        </div>
        <p>
          Patient is approved, dossier{" "}
          <span className="font-bold">
            {referral.epdPatientId}.{referral.epdFileId}
          </span>{" "}
          created.
        </p>
      </div>
    </div>
  );
};

const ManualSyncDialog = ({
  referralId,
  open,
  setOpen,
}: {
  referralId: string;
  open: boolean;
  setOpen: (newValue: boolean) => void;
}) => {
  const [epdPatientId, setEpdPatientId] = useState(0);
  const [showFailureAgain, setShowFailureAgain] = useState(false);

  const { mutate: save, isLoading: isSaving } =
    useManuallySyncPraktijkDataPatientApproval(
      () => {
        toast("Patient updated", {
          description: "The patient has been synchronized with Praktijkdata",
        });
        setOpen(false);
      },
      () => {
        // set setShowFailureAgain to true for 5 sec:
        setShowFailureAgain(true);
        setTimeout(() => {
          setShowFailureAgain(false);
        }, 5000);
      }
    );

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="w-[30vw]">
        <DialogHeader>
          <DialogTitle>Patient not found</DialogTitle>
          <DialogDescription>
            <p>
              The system couldn't identify the patient in Praktijkdata. Are you
              sure it's approved? If so, you may manually input the client's
              number from Praktijkdata and try again:
            </p>
          </DialogDescription>

          <main className="py-4">
            <label htmlFor="epdPatientId" className="pb-1 font-medium">
              Patient number
            </label>
            <Input
              type="number"
              id="epdPatientId"
              placeholder="Patient number"
              autoFocus
              value={epdPatientId}
              onChange={(event) => {
                setEpdPatientId(Number(event.target.value));
              }}
            />

            {showFailureAgain && (
              <div className="mt-4">
                <p className="w-full px-4 py-2 rounded-lg text-red-500 bg-red-100 dark:bg-red-800 dark:text-red-400">
                  Unfortunately, the patient could not be found in Praktijkdata.
                  Please check the patient number and try again.
                </p>
              </div>
            )}
          </main>
        </DialogHeader>
        <DialogFooter>
          <Button
            variant="outline"
            className="w-1/2"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            className="w-1/2"
            onClick={async (e) => {
              // todo: figure out why the hell does isAlertOpen set to be false when clicking here
              // do we rerender the component because we call the param 'onSelectTimeslot'?
              e.preventDefault();

              await save({ referralId, epdPatientId });
            }}
          >
            {isSaving ? "Saving..." : "Save"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
