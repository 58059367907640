import React, { useEffect } from "react";
import moment from "moment";
import { styled } from "@mui/system";
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  TablePagination,
} from "@mui/material";
import { observer } from "mobx-react";
import Button from "@mui/material/Button";
import { Link, useHistory } from "react-router-dom";
import useStores from "../../useStores";
import TableRowPointer from "../../shared/TableRowPointer";
import getInitials from "../../utils/getInitials";
import dossierType from "../../utils/constants/dossierType";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const StyledCardContent = styled(CardContent)(() => ({
  padding: 0,
}));

const StyledNameContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  marginRight: theme.spacing(2),
  fontSize: "15px",
}));

const StyledCardActions = styled(CardActions)(() => ({
  justifyContent: "flex-end",
}));

const PatientsTable = () => {
  const history = useHistory();
  const { patientsStore } = useStores();

  useEffect(() => {
    patientsStore.fetchPatients();
  }, [patientsStore]);

  const handlePageChange = (event, changedPage) => {
    patientsStore.setPage(changedPage);
  };

  return (
    <Card>
      <StyledCardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Naam</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Datum aangemaakt</TableCell>
              <TableCell>Aantal dossiers</TableCell>
              <TableCell align="right">Acties</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {patientsStore.patients.map((patient) => {
              const dossiers = patient.dossiers ? patient.dossiers : [];

              return (
                <TableRowPointer
                  hover
                  key={patient.id}
                  onClick={() => history.push(`/patient/${patient.id}`)}
                >
                  <TableCell>
                    <StyledNameContainer>
                      <StyledAvatar>
                        {getInitials(
                          `${patient.firstName} ${patient.lastName}`
                        )}
                      </StyledAvatar>
                      {`${patient.firstName} ${patient.lastName}`}
                    </StyledNameContainer>
                  </TableCell>
                  <TableCell>{patient.email}</TableCell>
                  <TableCell>
                    {moment(patient.createdAt).format("LLL")}
                  </TableCell>
                  <TableCell>
                    iPractice:{" "}
                    {
                      dossiers.filter((x) =>
                        [dossierType.GGZ, dossierType.ADHD].includes(x.$type)
                      ).length
                    }
                    <br />
                    Transfer:{" "}
                    {
                      dossiers.filter((x) => x.$type === dossierType.TRANSFER)
                        .length
                    }
                    <br />
                    OpenUp:{" "}
                    {
                      dossiers.filter((x) => x.$type === dossierType.OPEN_UP)
                        .length
                    }
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      color="primary"
                      component={Link}
                      size="small"
                      to={`/patient/${patient.id}`}
                      variant="outlined"
                    >
                      Bekijk
                    </Button>
                  </TableCell>
                </TableRowPointer>
              );
            })}
          </TableBody>
        </Table>
      </StyledCardContent>
      <StyledCardActions>
        <TablePagination
          component="div"
          count={patientsStore.count}
          page={patientsStore.page}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
          onPageChange={handlePageChange}
        />
      </StyledCardActions>
    </Card>
  );
};

export default observer(PatientsTable);
