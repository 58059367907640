import { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { CaseLoadOverview } from "./caseLoadOverview/overview";
import { ProgressChart } from "./progress/chart";
import { AbsenceHeader } from "./caseLoadOverview/absence/absenceHeader";
import { AlertHeader } from "./caseLoadOverview/alerts/alertHeader";
import { CaseLoadAlert } from "./types";
import { KopModelStatus } from "./onboardPatients/types";
import {
  getCurrentMondayAndFriday,
  getOnlyCaseLoadEntriesWithAlertsFromLocalStorage,
} from "./utils";
import { NavBarOpenStateContext } from "../../layouts/NavBar/NavBarOpenStateContext";
import {
  HorizontalTab,
  HorizontalTabHeader,
  HorizontalTabContent,
} from "../../components/ui/horizontalTab";
import { CaseloadStatistics } from "./userStatistics/caseloadStatistics";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { OnboardPatients } from "./onboardPatients";
import { useGetOnboardingPatients } from "./onboardPatients/hooks";
import { useIsEnabled } from "../../feature-management/useIsEnabled";

const CaseLoadCockpit = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { isNavBarOpen } = useContext(NavBarOpenStateContext)!;

  const { data: isOpAutomationEnabled } = useIsEnabled(
    "EnableOnlinePsychologistOnAndOffBoardingAutomation"
  );

  const { data: onboardingPatients } = useGetOnboardingPatients();

  const countOfOnboardingPatients =
    onboardingPatients?.data?.filter(
      (x) => !x.treatmentStartStandardMessageSent
    )?.length ?? 0;

  const [alerts, setAlerts] = useState<
    {
      caseLoadEntryId: string;
      alerts: CaseLoadAlert[];
    }[]
  >([]);

  const [absence, setAbsence] = useState<{
    startDate: string;
    endDate: string;
  } | null>(null);

  const [onlyCaseLoadsWithAlert, setOnlyCaseLoadsWithAlert] = useState(
    getOnlyCaseLoadEntriesWithAlertsFromLocalStorage()
  );

  const { monday } = getCurrentMondayAndFriday();

  const getDefaultSelectedDate = () => {
    const fromUrl = searchParams.get("period");
    return fromUrl ? new Date(fromUrl!) : monday;
  };

  const [date, setDate] = useState<Date>(getDefaultSelectedDate());

  const onChangeDate = (newDate: Date) => {
    const parsedDate = moment(newDate).utc(true).toDate();

    if (parsedDate.getDate() == monday.getDate()) {
      searchParams.delete("period"); // cleaner url, if we delete, rather than put this week's monday there
    } else {
      searchParams.set("period", parsedDate.toISOString());
    }

    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });

    setDate(parsedDate);
  };

  const getWrapperClass = () => {
    const isOldDesign = localStorage.getItem("force-old-design") == "true";
    if (!isOldDesign) return "";

    return isNavBarOpen ? "-ml-12" : "ml-0";
  };

  return (
    <main className={`px-6 ${getWrapperClass()}`}>
      <AbsenceHeader absence={absence} />
      <AlertHeader
        hasAbsence={absence != null}
        alerts={alerts}
        onlyCaseLoadsWithAlert={onlyCaseLoadsWithAlert}
        setOnlyCaseLoadsWithAlert={setOnlyCaseLoadsWithAlert}
      />

      <div className="mt-24 relative w-full">
        <div className="pt-2">
          <HorizontalTab>
            <div className="w-full mb-12">
              <div className="flex items-center ml-3">
                <HorizontalTabHeader index={0}>
                  {t("case-load-manager-my-case-load-tab-title")}
                </HorizontalTabHeader>
                {isOpAutomationEnabled && (
                  <HorizontalTabHeader index={1}>
                    <>
                      <p>{t("case-load-manager-onboarding-tab-title")}</p>

                      <div
                        className={`h-4 bg-ipractice-blue absolute top-0 right-[20px] text-xs text-white rounded-full flex items-center justify-center ${
                          countOfOnboardingPatients &&
                          countOfOnboardingPatients > 0
                            ? "visible"
                            : "hidden"
                        } ${countOfOnboardingPatients > 10 ? "w-5" : "w-4"}`}
                      >
                        {countOfOnboardingPatients}
                      </div>
                    </>
                  </HorizontalTabHeader>
                )}

                <HorizontalTabHeader index={isOpAutomationEnabled ? 2 : 1}>
                  {t("case-load-manager-statistics-tab-title")}
                </HorizontalTabHeader>
              </div>
              <HorizontalTabContent index={0}>
                <CaseLoadOverview
                  date={date}
                  onChangeDate={onChangeDate}
                  setAbsence={setAbsence}
                  setAlerts={setAlerts}
                  onlyCaseLoadsWithAlert={onlyCaseLoadsWithAlert}
                  setOnlyCaseLoadsWithAlert={setOnlyCaseLoadsWithAlert}
                />
              </HorizontalTabContent>
              {isOpAutomationEnabled && (
                <HorizontalTabContent index={1}>
                  <OnboardPatients />
                </HorizontalTabContent>
              )}
              <HorizontalTabContent index={isOpAutomationEnabled ? 2 : 1}>
                <div className="p-4">
                  <CaseloadStatistics />
                </div>
              </HorizontalTabContent>
            </div>
          </HorizontalTab>
        </div>

        <div className="z-0 absolute top-0 -mt-[70px] w-full flex justify-end">
          <ProgressChart date={date} />
        </div>
      </div>
    </main>
  );
};

export default CaseLoadCockpit;
