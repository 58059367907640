import { useGetQuestionnairesOfDossiers } from "./hooks";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import { getSimpleFormattedDateTime } from "../../../caseLoadCockpit/utils";
import { FinishedIndicator } from "./questionStateIndicators/finishedIndicator";
import { UnFinishedIndicator } from "./questionStateIndicators/unFinishedIndicator";
import { SendToPraktijkDataButton } from "./sendToPraktijkDataButton";

export const QuestionnairesOfDossier = ({
  epdPatientId,
  epdFileId,
}: {
  epdPatientId: number | null;
  epdFileId: number;
}) => {
  const { data, isLoading, isError } = useGetQuestionnairesOfDossiers(
    epdPatientId,
    epdFileId
  );

  if (!epdPatientId) {
    return <></>;
  }

  const renderTableBody = () => {
    if (isError) {
      return (
        <TableRow>
          <TableCell colSpan={5}>
            Something went wrong - if you are sure the Client number is correct,
            please ping the dev team with the Client number{" "}
          </TableCell>
        </TableRow>
      );
    }

    if (isLoading) {
      return (
        <TableRow>
          <TableCell colSpan={5}>Loading...</TableCell>
        </TableRow>
      );
    }

    if (!data || data.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={5}>No questionnaires found.</TableCell>
        </TableRow>
      );
    }

    return data.map((d) => {
      return (
        <TableRow
          key={d.id}
          className={
            d.isFinished
              ? "bg-green-50/50 hover:bg-green-50"
              : "bg-red-50/50 hover:bg-red-50"
          }
        >
          <TableCell>{d.questionnaireType}</TableCell>
          <TableCell>
            {d.isFinished ? <FinishedIndicator /> : <UnFinishedIndicator />}
          </TableCell>
          <TableCell>
            {d.isFinished ? getSimpleFormattedDateTime(d.finishedOn!) : "-"}
          </TableCell>
          <TableCell>{getSimpleFormattedDateTime(d.createdOn)}</TableCell>
          <TableCell className="flex justify-end">
            {d.isFinished ? (
              <SendToPraktijkDataButton questionnaireId={d.id} />
            ) : (
              <></>
            )}
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <section>
      <Table className="mt-8 border rounded">
        <TableHeader>
          <TableRow>
            <TableHead>Questionnaire Type</TableHead>
            <TableHead>Is Finished?</TableHead>
            <TableHead>Finished On</TableHead>
            <TableHead>Created On</TableHead>
            <TableHead></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>{renderTableBody()}</TableBody>
      </Table>
    </section>
  );
};
