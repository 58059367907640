import { Save } from "lucide-react";
import { Button } from "../../../../components/ui/button";
import { useTranslation } from "react-i18next";
import { useSaveAppointmentTypePerDossierTypeConfig } from "../hooks";
import { CurrentAppointmentPerDossierTypeConfig } from "../../types";
import { areArraysEqual } from "../../../../lib/utils";
import { toast } from "sonner";

export const SaveButton = ({
  originalData,
  mutatedData,
}: {
  originalData: CurrentAppointmentPerDossierTypeConfig[];
  mutatedData: CurrentAppointmentPerDossierTypeConfig[];
}) => {
  const { mutate: save, isLoading: isSaving } =
    useSaveAppointmentTypePerDossierTypeConfig(() => {
      toast(
        t(
          "case-load-manager-admin-panel-auto-bill-config-save-success-toast-title"
        ),
        {
          description: t(
            "case-load-manager-admin-panel-auto-bill-config-save-success-toast-desc"
          ),
        }
      );
    });
  const { t } = useTranslation();

  const isChanged = () => {
    const mappedOriginalData = originalData.map((x) => ({
      test: `${x.epdDossierType.epdId}-${x.epdAppointmentType?.epdId ?? ""}`,
    }));

    const mappedMutatedData = mutatedData.map((x) => ({
      test: `${x.epdDossierType.epdId}-${x.epdAppointmentType?.epdId ?? ""}`,
    }));

    return !areArraysEqual(mappedOriginalData, mappedMutatedData, "test");
  };

  return (
    <Button
      onClick={async () => {
        await save({
          mapping: mutatedData.map((x) => {
            return {
              appointmentTypeId: x.epdAppointmentType?.id ?? null,
              dossierTypeId: x.epdDossierType.id,
            };
          }),
        });
      }}
      className="px-12"
      disabled={!isChanged()}
    >
      <div className="flex items-center space-x-2">
        <Save className="w-4 h-4" />
        <p>
          {isSaving
            ? t("case-load-manager-saving")
            : t("case-load-manager-save")}
        </p>
      </div>
    </Button>
  );
};
