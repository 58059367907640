import { Checkbox } from "../../../../../components/ui/checkbox";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useToggleSupportedPatientViaVideoCall } from "../../hooks";
import { ConfirmVideoCalledWithDialog } from "../confirmationDialogs/videoCalledWithConfirm";
import { ConfirmUncheckVideoCalledWithDialog } from "../confirmationDialogs/undoVideoCalledWithConfirm";

export const VideoCallCheckBox = ({
  caseLoadId,
  supported,
  toggle,
  onBlur,
  isInPast,
  isInFuture,
  isCheckinNeeded,
  justificationIfCheckinNotNeeded,
  showLastVideoCallConsultWarning,
}: {
  caseLoadId: string;
  supported: boolean;
  toggle: () => void;
  onBlur: () => void;
  isInPast: boolean;
  isInFuture: boolean;
  isCheckinNeeded: boolean;
  justificationIfCheckinNotNeeded: string | null;
  showLastVideoCallConsultWarning: boolean;
}) => {
  const { mutate: save, isLoading: isSaving } =
    useToggleSupportedPatientViaVideoCall();

  const onToggle = () => {
    save({ caseLoadId, value: !supported });
    toggle();
  };

  const { t } = useTranslation();
  const [isConfirmCheckInDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [
    isConfirmRemoveCheckInDialogOpen,
    setIsConfirmRemoveCheckInDialogOpen,
  ] = useState(false);

  const shouldNeverShowConfirmCheckInDialog =
    localStorage.getItem(
      "caseLoadManagerTurnVideoCalledWithOnWarningNeverShowAgain"
    ) === "true";

  if (!isCheckinNeeded) {
    return <p className="opacity-70">{justificationIfCheckinNotNeeded}</p>;
  }

  return (
    <div className="flex items-center space-x-2">
      <Checkbox
        id="videoCalledWith"
        checked={supported}
        onCheckedChange={() => {
          // we are unchecking
          if (supported) {
            setIsConfirmRemoveCheckInDialogOpen(true);
            return;
          }

          if (showLastVideoCallConsultWarning) {
            setIsConfirmDialogOpen(true);
          } else if (shouldNeverShowConfirmCheckInDialog) {
            onToggle();
          } else {
            setIsConfirmDialogOpen(true);
          }
        }}
        onBlur={onBlur}
        disabled={!isCheckinNeeded || isSaving || isInPast || isInFuture}
      />
      <label
        htmlFor="videoCalledWith"
        className={`text-sm text-left leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 ${
          supported && "font-bold"
        }`}
      >
        {isSaving
          ? t("case-load-manager-saving")
          : t("case-load-manager-videoconsult-label")}
      </label>
      <ConfirmVideoCalledWithDialog
        open={isConfirmCheckInDialogOpen}
        onOpenChange={setIsConfirmDialogOpen}
        showLastVideoCallConsultWarning={showLastVideoCallConsultWarning}
        onConfirmed={(neverShowAgain) => {
          localStorage.setItem(
            "caseLoadManagerTurnVideoCalledWithOnWarningNeverShowAgain",
            neverShowAgain.toString()
          );
          onToggle();
          setIsConfirmDialogOpen(false);
        }}
      />
      <ConfirmUncheckVideoCalledWithDialog
        open={isConfirmRemoveCheckInDialogOpen}
        onOpenChange={setIsConfirmRemoveCheckInDialogOpen}
        onConfirmed={() => {
          onToggle();
          setIsConfirmRemoveCheckInDialogOpen(false);
        }}
      />
    </div>
  );
};
