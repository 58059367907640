import { Header } from "./header/header";
import {
  HorizontalTab,
  HorizontalTabHeader,
  HorizontalTabContent,
  TabContext,
} from "../../../../components/ui/horizontalTab";
import { useContext, useState } from "react";
import { ResourceSuggestion } from "./aiRecommendations/types";
import { AiRecommendations } from "./aiRecommendations/aiRecommendations";
import { useGetAssignedPatient } from "../hooks";
import { Skeleton } from "../../../../components/ui/skeleton";
import { useIsEnabled } from "../../../../feature-management/useIsEnabled";
import { KopModel } from "./kopModel/kopModel";
import { TreatmentDetails } from "./treatmentDetails/treatmentDetails";

export const CaseLoadClientDetails = ({
  clientId,
  onClose,
}: {
  clientId: string;
  onClose: () => void;
}) => {
  const { isLoading } = useGetAssignedPatient(clientId);

  if (isLoading) {
    return (
      <div className="min-h-[90vh] min-w-full">
        <Skeleton className="h-14 w-full rounded" />
        <div className="mt-6 flex items-center justify-between space-x-6">
          <Skeleton className="h-44 w-1/2 rounded" />
          <Skeleton className="h-44 w-1/2 rounded" />
        </div>
        <Skeleton className="mt-6 h-72 w-full rounded" />
      </div>
    );
  }

  return (
    <main className="-mt-6 min-w-full max-h-[95vh]">
      <Header clientId={clientId} onClose={onClose} />

      <section className="pt-6">
        <HorizontalTab>
          <Tabs clientId={clientId} />
        </HorizontalTab>
      </section>
    </main>
  );
};

const Tabs = ({ clientId }: { clientId: string }) => {
  const { activeTab, setActiveTab } = useContext(TabContext)!;

  const { data: isCaseLoadManagerAiChatAssistantEnabled } = useIsEnabled(
    "EnableCaseLoadManagerAiChatAssistant"
  );

  const onReplySuggestionSelected = (suggestion: string) => {
    // todo
  };
  const onResourceSuggestionSelected = (resource: ResourceSuggestion) => {
    // todo
  };

  return (
    <div className="w-full mb-12">
      <div className="flex items-center ml-3">
        {isCaseLoadManagerAiChatAssistantEnabled && (
          <HorizontalTabHeader index={0}>Chat assistant</HorizontalTabHeader>
        )}
        <HorizontalTabHeader
          index={isCaseLoadManagerAiChatAssistantEnabled ? 1 : 0}
        >
          KOP-model
        </HorizontalTabHeader>
        <HorizontalTabHeader
          index={isCaseLoadManagerAiChatAssistantEnabled ? 2 : 1}
        >
          Treatment
        </HorizontalTabHeader>
      </div>

      {isCaseLoadManagerAiChatAssistantEnabled && (
        <HorizontalTabContent index={0}>
          <AiRecommendations
            onReplySuggestionSelected={onReplySuggestionSelected}
            onResourceSuggestionSelected={onResourceSuggestionSelected}
          />
        </HorizontalTabContent>
      )}
      <HorizontalTabContent
        index={isCaseLoadManagerAiChatAssistantEnabled ? 1 : 0}
      >
        <KopModel clientId={clientId} />
      </HorizontalTabContent>
      <HorizontalTabContent
        index={isCaseLoadManagerAiChatAssistantEnabled ? 2 : 1}
      >
        <TreatmentDetails clientId={clientId} />
      </HorizontalTabContent>
    </div>
  );
};
