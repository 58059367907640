import HomeIcon from "@mui/icons-material/Home";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PeopleIcon from "@mui/icons-material/People";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";

export default [
  {
    subheader: "",
    items: [
      {
        title: "Welkom",
        href: "/welcome",
        icon: HomeIcon,
      },
      {
        title: "Cliënt aanmaken",
        href: "/create-patient",
        icon: PersonAddIcon,
      },
      {
        title: "Cliënten",
        href: "/patients",
        icon: PeopleIcon,
      },
    ],
  },
  {
    subheader: "iPractice",
    items: [
      {
        title: "Praktijkdata Dossiers",
        href: "/dossiers-ggz",
        icon: PeopleIcon,
      },
      {
        title: "Onboarding",
        href: "/onboarding",
        icon: HowToRegIcon,
      },
      {
        title: "Kalender",
        href: "/calendar",
        icon: CalendarTodayIcon,
      },
      {
        title: "Mijn caseload",
        href: "/case-load",
        icon: AssignmentOutlinedIcon,
      },
    ],
  },
  {
    subheader: "Transfers",
    items: [
      {
        title: "Transfer Dossiers",
        href: "/dossiers-transfer",
        icon: PeopleIcon,
      },
    ],
  },
];
