import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../../components/ui/hover-card";
import roleNames from "../../utils/constants/roleNames";
import useStores from "../../useStores";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { styled } from "@mui/system";
import ListItem from "@mui/material/ListItem";
import Button, { ButtonProps } from "@mui/material/Button";

interface StyledButtonProps extends ButtonProps {
  isActive?: boolean;
}

const StyledButton = styled(Button)<StyledButtonProps>(
  ({ theme, isActive }) => ({
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    padding: "10px 8px",
    color: isActive ? theme.palette.primary.main : theme.palette.grey[800],
  })
);

const StyledListItem = styled(ListItem)(() => ({
  display: "block",
  paddingTop: 0,
  paddingBottom: 0,
}));

export const AdminMenu = () => {
  const { authStore } = useStores();

  if (!authStore.roles.includes(roleNames.ADMINISTRATOR)) {
    return <></>;
  }

  return (
    <div className="mt-6 pt-2 border-t border-gray-200">
      <HoverCard openDelay={200}>
        <HoverCardTrigger asChild>
          <StyledListItem disableGutters key="Admin">
            <StyledButton isActive={false}>
              <AdminPanelSettingsIcon />
              <p className="pl-2">Administratie</p>
            </StyledButton>
          </StyledListItem>
        </HoverCardTrigger>
        <HoverCardContent
          className="w-[24rem] space-y-2"
          style={{ zIndex: 99999 }}
          side="right"
          align="end"
        >
          <p>
            The admin menu on the old design is no longer maintained. Please
            switch to the new design, by pressing the three dots next to your
            username in the sidebar, and selecting "Switch to the new design".
          </p>
        </HoverCardContent>
      </HoverCard>
    </div>
  );
};
