import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { QuestionnaireOfDossier } from "./types";

export const useGetQuestionnairesOfDossiers = (
  epdPatientId: number | null,
  epdFileId: number
) => {
  const query = useQuery<QuestionnaireOfDossier[]>(
    [
      "questionnaires-of-dossier",
      "questionnaire-admin",
      epdPatientId,
      epdFileId,
    ],
    async () => {
      const res = await axios.get(
        `adminquestionlists/${epdPatientId}/${epdFileId}/questionnaires`
      );
      return res.data;
    },
    {
      enabled: epdPatientId !== null,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useSendQuestionnaireToPraktijkData = (
  questionListId: string,
  onSuccess: () => void,
  onError: () => void
) => {
  const mutation = useMutation({
    mutationFn: async () => {
      const res = await axios.post(
        `adminquestionlists/${questionListId}/send-to-praktijkdata`,
        {}
      );
      return res.data;
    },
    onSuccess: onSuccess,
    onError: onError,
  });

  return mutation;
};
