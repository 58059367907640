import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { CalendarIntakeDeviation } from "./types";
import { PsychologistIntakeAssignment } from "./admin/intakeLimit/types";
import { queryClient } from "../../react-query/provider";

export const useGetIntakeDeviationsForCalendar = (date: string) => {
  const query = useQuery<CalendarIntakeDeviation[]>(
    ["calendar", "intake-deviations", date],
    async () => {
      const res = await axios.get(`timeslots/intake-deviations?date=${date}`);
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useGetIntakeDeviationsForAdmin = () => {
  const query = useQuery<PsychologistIntakeAssignment[]>(
    ["admin", "intake-deviations"],
    async () => {
      const res = await axios.get(`calendaradmin/intake-deviations`);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useEditCurrentWeekIntakeDeviation = () => {
  const mutation = useMutation({
    mutationFn: async ({
      epdEmployeeId,
      numberOfIntakes,
    }: {
      epdEmployeeId: string;
      numberOfIntakes: number;
    }) => {
      const res = await axios.post(
        `calendaradmin/intake-deviation/current-week`,
        {
          epdEmployeeId,
          numberOfIntakes,
        }
      );

      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["admin", "intake-deviations"]);
      queryClient.refetchQueries(["admin", "intake-deviations"]);
    },
  });

  return mutation;
};

export const useCreateIntakeDeviation = () => {
  const mutation = useMutation({
    mutationFn: async ({
      epdEmployeeId,
      start,
      end,
      isIndefinite,
      numberOfIntakes,
    }: {
      epdEmployeeId: string;
      start: string;
      end: string | undefined;
      isIndefinite: boolean;
      numberOfIntakes: number;
    }) => {
      const res = await axios.post(`calendaradmin/intake-deviation `, {
        epdEmployeeId,
        start,
        end: end === undefined ? null : end,
        isIndefinite,
        numberOfIntakes,
      });

      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["admin", "intake-deviations"]);
      queryClient.refetchQueries(["admin", "intake-deviations"]);
    },
  });

  return mutation;
};

export const useDeleteIntakeDeviation = () => {
  const mutation = useMutation({
    mutationFn: async ({
      epdEmployeeId,
      startDate,
      endDate,
      isIndefinite,
    }: {
      epdEmployeeId: string;
      startDate: string;
      endDate: string | undefined;
      isIndefinite: boolean;
    }) => {
      const res = await axios.post(`calendaradmin/intake-deviation/remove`, {
        epdEmployeeId,
        startDate,
        endDate: endDate === undefined ? null : endDate,
        isIndefinite,
      });

      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["admin", "intake-deviations"]);
      queryClient.refetchQueries(["admin", "intake-deviations"]);
    },
  });

  return mutation;
};

export const useAddIntakeDeviationComment = () => {
  const mutation = useMutation({
    mutationFn: async ({
      epdEmployeeId,
      comment,
    }: {
      epdEmployeeId: string;
      comment: string;
    }) => {
      const res = await axios.post(`calendaradmin/intake-deviation/comment`, {
        epdEmployeeId,
        comment,
      });

      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["admin", "intake-deviations"]);
      queryClient.refetchQueries(["admin", "intake-deviations"]);
    },
  });

  return mutation;
};

export const useGetLocationTags = () => {
  const query = useQuery<string[]>(
    ["calendar", "user-tags", "locations"],
    async () => {
      const res = await axios.get(`calendaradmin/location-tags`);
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useRecalculateEmployeeOccupancyPredictions = (
  onSuccess: () => void
) => {
  return useMutation({
    mutationFn: async () => {
      const res = await axios.post(
        `calendaradmin/recalculate-employee-occupancy-predictions`
      );

      return res.data;
    },
    onSuccess: () => {
      onSuccess();
    },
  });
};
