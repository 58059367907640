import { howDidYouHearAboutUsAnswers } from "../../constants";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import { useState } from "react";
import { useUpdateHowDidThePatientHearAboutUs } from "../../hooks";

export const HowDidYouHearAboutUs = ({
  isReadOnly,
  referralId,
  value,
}: {
  isReadOnly: boolean;
  referralId: string;
  value: string | null;
}) => {
  const [selectedValue, setSelectedValue] = useState(value);

  const { mutate: save, isLoading: isSaving } =
    useUpdateHowDidThePatientHearAboutUs();

  return (
    <div>
      <p className="font-medium pb-1">How did we receive the referral?</p>
      <Select
        onValueChange={async (value) => {
          setSelectedValue(value);

          await save({ referralId, howDoYouHearAboutUs: value });
        }}
        defaultValue={selectedValue || undefined}
        disabled={isReadOnly || isSaving}
      >
        <SelectTrigger>
          <SelectValue placeholder="Select answer..." />
        </SelectTrigger>
        <SelectContent>
          {howDidYouHearAboutUsAnswers.sort().map((answer) => (
            <SelectItem value={answer} key={answer}>
              {answer}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};
