import { Table } from "@tanstack/react-table";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import { Button } from "../../../components/ui/button";
import { Columns3 } from "lucide-react";
import { RowSelectorColName } from "../caseLoadOverview/columns/distinctCols/rowSelectorCol";
import { OpenDetailsSheetColName } from "../caseLoadOverview/columns/distinctCols/openDetailsSheetCol";
import { useTranslation } from "react-i18next";
import { OffboardingColName } from "../caseLoadOverview/columns/distinctCols/offboardingCol";
import { CheckinNeededColName } from "../caseLoadOverview/columns/distinctCols/checkinNeededCol";
import { HasChattedWithColName } from "../caseLoadOverview/columns/distinctCols/hasChattedWithCol";
import { IntakeOnColName } from "../caseLoadOverview/columns/distinctCols/intakeOnCol";
import { IsPatientInsuredColName } from "../caseLoadOverview/columns/distinctCols/isPatientInsuredCol";
import { LastRegularConsultOnColName } from "../caseLoadOverview/columns/distinctCols/lastRegularConsultOnCol";
import { PatientColName } from "../caseLoadOverview/columns/distinctCols/patientCol";
import { PatientContactInfoColName } from "../caseLoadOverview/columns/distinctCols/patientContactInfoCol";
import { RemarksColName } from "../caseLoadOverview/columns/distinctCols/remarksCol";
import { YourCommentsColName } from "../caseLoadOverview/columns/distinctCols/yourCommentsCol";
import { AlertsColName } from "../caseLoadOverview/columns/distinctCols/alertsCol";
import { IsPatientContractedColName } from "../caseLoadOverview/columns/distinctCols/isPatientContractedCol";
import { NextRegularConsultOnColName } from "../caseLoadOverview/columns/distinctCols/nextRegularConsultOnCol";
import { LastChatConsultOnColName } from "../caseLoadOverview/columns/distinctCols/lastChatConsultOnCol";
import { NumberOfRegularConsultsColName } from "../caseLoadOverview/columns/distinctCols/numberOfRegularConsultsCol";
import { TreatmentColName } from "../caseLoadOverview/columns/distinctCols/treatmentCol";
import { RegularConsultPsychologistsColName } from "../caseLoadOverview/columns/distinctCols/regularConsultPsychologistsCol";
import {
  PatientColName as AutomaticBillingPatientColName,
  AppointmentColName,
  GeneratedOnColName,
  GeneratedByColName,
} from "../automaticBillings/columns";

export const ColumnVisibilityPicker = ({ table }: { table: Table<any> }) => {
  const { t } = useTranslation();

  const getTranslatedColumnName = (id: string) => {
    switch (id) {
      case CheckinNeededColName:
        return t("case-load-manager-column-needs-checkin");
      case OffboardingColName:
        return "Offboarding";
      case HasChattedWithColName:
        return t("case-load-manager-column-status");
      case IntakeOnColName:
        return t("case-load-manager-column-intake-date");
      case IsPatientInsuredColName:
        return t("case-load-manager-column-is-insured");
      case LastRegularConsultOnColName:
        return t("case-load-manager-column-last-regular-consult");
      case NumberOfRegularConsultsColName:
        return t("case-load-manager-column-number-of-regular-consults");
      case RegularConsultPsychologistsColName:
        return t("case-load-manager-column-regular-consult-psychologists");
      case PatientColName:
        return t("case-load-manager-column-patient");
      case PatientContactInfoColName:
        return t("case-load-manager-column-contact-information");
      case RemarksColName:
        return t("case-load-manager-column-remarks");
      case YourCommentsColName:
        return t("case-load-manager-column-your-comments");
      case IsPatientContractedColName:
        return t("case-load-manager-column-is-contracted");
      case NextRegularConsultOnColName:
        return t("case-load-manager-column-next-regular-consult");
      case LastChatConsultOnColName:
        return t("case-load-manager-column-last-chat-consult");
      case TreatmentColName:
        return t("case-load-manager-column-treatment");
      case AlertsColName:
        return t("case-load-manager-column-alerts");

      case AutomaticBillingPatientColName:
        return t("automatic-billing-column-patient");
      case AppointmentColName:
        return t("automatic-billing-column-appointment");
      case GeneratedOnColName:
        return t("automatic-billing-column-generatedOn");
      case GeneratedByColName:
        return t("automatic-billing-column-generatedBy");

      default:
        return id;
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          className="ml-auto flex items-center gap-2 w-52"
        >
          <Columns3 className="w-5 h-5" />
          <p>
            {t(
              "case-load-manager-customization-column-visibility-picker-button"
            )}
          </p>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="center"
        side="left"
        className="max-h-[16rem] overflow-y-auto"
      >
        {table
          .getAllColumns()
          .filter(
            (column) =>
              column.getCanHide() &&
              column.id !== RowSelectorColName && // "row selection" is not a "real" column, its checkbox/context menu, should be always visible
              column.id !== OpenDetailsSheetColName // "open details" is not a "real" column, its button that should be always visible
          )
          .map((column) => {
            return (
              <DropdownMenuCheckboxItem
                key={column.id}
                className="capitalize"
                checked={column.getIsVisible()}
                onCheckedChange={(value) => column.toggleVisibility(!!value)}
                onSelect={(e) => e.preventDefault()}
              >
                {getTranslatedColumnName(column.id)}
              </DropdownMenuCheckboxItem>
            );
          })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
