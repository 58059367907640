import { MainListPatient, PatientSupportType } from "../../types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../../components/ui/tooltip";
import { isGetAssignedPatientsFetching } from "../hooks";
import {
  isPeriodInPastWeeks,
  isPeriodInFutureWeeks,
  getSimpleFormattedDateTime,
} from "../../utils";
import { useQueryClient } from "react-query";
import { ChatCheckBox } from "./checkboxes/chatCheckBox";
import { VideoCallCheckBox } from "./checkboxes/videoCallCheckBox";
import { useIsEnabled } from "../../../../feature-management/useIsEnabled";

export const SupportCheckboxes = ({
  data,
  onBlur,
}: {
  data: MainListPatient;
  onBlur: () => void;
}) => {
  const { t } = useTranslation();
  const initialValue = data.hasChattedWith;
  const [value, setValue] = useState(initialValue);
  const isInPast = isPeriodInPastWeeks(data.period);
  const isInFuture = isPeriodInFutureWeeks(data.period);

  const queryClient = useQueryClient();
  const isFetching = isGetAssignedPatientsFetching(queryClient);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div
      className={`space-y-1 ${
        data.automaticBillingScheduledOn ? "mt-5" : "mt-0"
      }`}
    >
      <ChattedWithCell
        caseLoadId={data.id}
        supported={value}
        patientSupportType={data.patientSupportType}
        isFetching={isFetching}
        setAsHasChattedWithOn={data.setAsHasChattedWithOn}
        toggle={() => {
          setValue(!value);
        }}
        onBlur={onBlur}
        isInPast={isInPast}
        isInFuture={isInFuture}
        isCheckinNeeded={data.isCheckinNeeded}
        justificationIfCheckinNotNeeded={data.justificationIfCheckinNotNeeded}
        hasVideoCallConsultThisWeek={data.hasVideoCallConsultThisWeek}
        showLastVideoCallConsultWarning={data.numberOfVideoCallConsults >= 2}
      />
      {data.automaticBillingScheduledOn && (
        <div>
          <p
            className={`text-xs ${
              data.isAutomaticBillOutsideWorkingHours
                ? "text-red-500"
                : "text-gray-500"
            }`}
          >
            {t("case-load-manager-automatic-billing-scheduled-on")}{" "}
            {getSimpleFormattedDateTime(data.automaticBillingScheduledOn)}
          </p>
        </div>
      )}
    </div>
  );
};

const ChattedWithCell = ({
  caseLoadId,
  isFetching,
  supported,
  patientSupportType,
  toggle,
  onBlur,
  isInPast,
  isInFuture,
  isCheckinNeeded,
  justificationIfCheckinNotNeeded,
  setAsHasChattedWithOn,
  hasVideoCallConsultThisWeek,
  showLastVideoCallConsultWarning,
}: {
  caseLoadId: string;
  isFetching: boolean;
  supported: boolean;
  patientSupportType: PatientSupportType;
  toggle: () => void;
  onBlur: () => void;
  isInPast: boolean;
  isInFuture: boolean;
  isCheckinNeeded: boolean;
  justificationIfCheckinNotNeeded: string | null;
  setAsHasChattedWithOn?: string;
  hasVideoCallConsultThisWeek: boolean;
  showLastVideoCallConsultWarning: boolean;
}) => {
  const { t } = useTranslation();

  const { data: isVideoConsultSupportEnabled } = useIsEnabled(
    "EnableVideoConsultSupportFromCaseloadManager"
  );

  var options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  };

  const Wrapper = ({ children }: { children: JSX.Element }) => {
    if (supported && !isFetching) {
      return (
        <TooltipProvider delayDuration={100}>
          <Tooltip>
            <TooltipTrigger>{children}</TooltipTrigger>
            <TooltipContent style={{ zIndex: 99999 }}>
              <p>
                {patientSupportType == "Chat"
                  ? t("case-load-manager-chatted-recorded-on")
                  : t("case-load-manager-videocall-recorded-on")}{" "}
                {new Date(setAsHasChattedWithOn!).toLocaleString(
                  "en-GB",
                  options
                )}
                .
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    } else {
      return <>{children}</>;
    }
  };

  const hasChatSupport =
    supported &&
    (patientSupportType === "Chat" || patientSupportType === "Both");

  const hasVideoCallSupport =
    supported &&
    (patientSupportType === "VideoCall" || patientSupportType === "Both");

  // watch for isCheckinNeeded -> if its false, we already show in the Chat CheckBox the reason
  // meaning, if we were to show checkbox too, it'd also display the checkin-exclusion-reason,
  // ...duplicating the same thing that the Chat CheckBox shows
  const showVideoCallCheckBox =
    isVideoConsultSupportEnabled &&
    hasVideoCallConsultThisWeek &&
    isCheckinNeeded;

  return (
    <Wrapper>
      <div className="space-y-4">
        <ChatCheckBox
          caseLoadId={caseLoadId}
          supported={hasChatSupport}
          toggle={toggle}
          onBlur={onBlur}
          isInPast={isInPast}
          isInFuture={isInFuture}
          isCheckinNeeded={isCheckinNeeded}
          justificationIfCheckinNotNeeded={justificationIfCheckinNotNeeded}
        />
        {showVideoCallCheckBox ? (
          <VideoCallCheckBox
            caseLoadId={caseLoadId}
            supported={hasVideoCallSupport}
            toggle={toggle}
            onBlur={onBlur}
            isInPast={isInPast}
            isInFuture={isInFuture}
            isCheckinNeeded={isCheckinNeeded}
            justificationIfCheckinNotNeeded={justificationIfCheckinNotNeeded}
            showLastVideoCallConsultWarning={showLastVideoCallConsultWarning}
          />
        ) : (
          <></>
        )}
      </div>
    </Wrapper>
  );
};
