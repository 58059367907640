import {
  GetWeeklyPatientReferralStatisticsQueryResponse,
  RegistrationStatus,
} from "../../../../types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../../../components/ui/table";

export const ReferralsPerInsurersTable = ({
  data,
}: {
  data: GetWeeklyPatientReferralStatisticsQueryResponse | undefined;
}) => {
  const getReadableStatus = (status: string) => {
    switch (status) {
      case RegistrationStatus.ReachOut:
        return "Reach Out";
      case RegistrationStatus.RegisterPatientAndIntake:
        return "Register Patient & Intakes";
      case RegistrationStatus.AwaitingPatientApproval:
        return "Awaiting Patient Approval in PD";
      case RegistrationStatus.AwaitingIWelcomeEmailSend:
        return "Awaiting iWelcome Email Sending";
      default:
        return status;
    }
  };
  return (
    <div className="overflow-x-auto">
      <Table className="border min-w-[1200px] table-auto">
        <TableHeader>
          <TableRow>
            <TableHead className="min-w-[250px]">Insurer</TableHead>
            <TableHead className="w-[150px]">Total Count</TableHead>
            {Object.keys(RegistrationStatus).map((statusKey) => (
              <TableHead className="w-[150px]" key={statusKey}>
                {getReadableStatus(statusKey)}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {data && data.reportItems?.length > 0 ? (
            data.reportItems
              .toSorted((a, b) =>
                (a.healthcareInsurerConcern ?? "").localeCompare(
                  b.healthcareInsurerConcern ?? ""
                )
              )
              .map((d) => {
                return (
                  <TableRow key={d.healthcareInsurerConcern}>
                    <TableCell className="py-1 min-w-[250px]">
                      {d.healthcareInsurerConcern ?? "Unknown"}
                    </TableCell>
                    <TableCell className="py-1 min-w-[150px]">
                      {d.totalCount}
                    </TableCell>
                    {Object.keys(RegistrationStatus).map((statusKey) => (
                      <TableCell key={statusKey} className="py-1 min-w-[150px]">
                        {d.statusCounts[statusKey] || 0}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })
          ) : (
            <TableRow>
              <TableCell
                colSpan={Object.keys(RegistrationStatus).length + 2}
                className="py-1"
              >
                No data available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};
