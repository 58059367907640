import { useTranslation } from "react-i18next";
import { getSimpleFormattedDateTime } from "../../../utils";
import { OnboardPatientDetails } from "../../types";

export const IntakeDates = ({
  onboardingPatient,
}: {
  onboardingPatient: OnboardPatientDetails;
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex items-center space-x-2">
        <p className="font-bold">
          {t("case-load-manager-onboarding-details-pre-intake-caption-1")}
        </p>
        <p>
          {getSimpleFormattedDateTime(onboardingPatient.preIntakeOn)}{" "}
          <span className="italic">
            {t("case-load-manager-onboarding-details-pre-intake-caption-2")}
          </span>
        </p>
      </div>
      <div
        className={`flex items-center space-x-2 ${
          onboardingPatient.intakeOn ? "visible" : "hidden"
        }`}
      >
        <p className="font-bold">
          {t("case-load-manager-onboarding-details-intake-caption-1")}
        </p>
        <p>
          {getSimpleFormattedDateTime(onboardingPatient.intakeOn!)}{" "}
          <span className="italic">
            {t("case-load-manager-onboarding-details-intake-caption-2")}{" "}
            {onboardingPatient.intakePsychologistName}
          </span>
        </p>
      </div>
    </div>
  );
};
