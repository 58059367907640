import { BaseMessage } from "../types";
import axios from "axios";
import { UseQueryOptions, useQuery } from "react-query";

export const useReplySuggestions = (
  chat: { messages: BaseMessage[]; userId: string },
  prompt?: string
) => {
  const query = useQuery<string[]>(
    [
      "caseload-manager",
      "ai-recommendations",
      "reply-suggestions",
      chat.userId,
    ],
    async () => {
      // const res = await axios.get(`todo`);
      // return res.data;
      return [
        "This is a hardcoded, test reply suggestion just to see how it would look like on the UI.",
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Temporibus sapiente facere natus quas id voluptatem. Consequatur ratione debitis ipsum quas.",
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quo laborum, omnis consequatur, consectetur quam ab velit temporibus qui facere error, ad expedita soluta. Dignissimos sit tenetur obcaecati, voluptates at dolores officia labore libero alias dolore totam iure eum odit excepturi!",
      ];
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useResourceSuggestions = (chat: {
  messages: BaseMessage[];
  userId: string;
}) => {
  const query = useQuery<
    { description: string; link?: string; title: string }[]
  >(
    [
      "caseload-manager",
      "ai-recommendations",
      "resource-suggestions",
      chat.userId,
    ],
    async () => {
      // const res = await axios.get(`todo`);
      // return res.data;
      return [
        {
          description: "This is some random PDF from some random link.",
          link: "https://pdfobject.com/pdf/sample.pdf",
          title: "Sample PDF",
        },
        {
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis autem ea est ipsa esse. Accusantium exercitationem corporis vel, cum, officiis tempora quas dignissimos sit nostrum corrupti nihil voluptatibus hic id quam veniam autem, omnis praesentium nisi debitis. Error dicta aliquid laudantium omnis. Aliquam temporibus cumque omnis sunt, perspiciatis maiores dolorem quos culpa delectus in officia, molestiae saepe possimus cum molestias?",
          link: "https://www.google.com",
          title: "How to deal with grief as a young adult",
        },
        {
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia nulla magnam labore, autem, quidem dolore veniam earum asperiores itaque deserunt nostrum fugiat atque. Praesentium odit cumque cupiditate dignissimos! Id eius laboriosam ex recusandae blanditiis, mollitia similique qui saepe repellat. Dolore delectus corrupti eum accusantium a, architecto dolorum excepturi. Saepe placeat vel voluptatibus dignissimos nam rem, consectetur assumenda, ex tempora mollitia id adipisci rerum molestiae. Obcaecati officia aperiam explicabo accusamus quod delectus sapiente optio ad ipsam perspiciatis.",
          link: "https://www.google.com",
          title: "Title of the 2nd document",
        },
      ];
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};
