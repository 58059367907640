import { ColumnDef } from "@tanstack/react-table";
import { MainListPatient } from "../../types";
import { PatientCol } from "./distinctCols/patientCol";
import { PatientContactInfoCol } from "./distinctCols/patientContactInfoCol";
import { RemarksCol } from "./distinctCols/remarksCol";
import { YourCommentsCol } from "./distinctCols/yourCommentsCol";
import { CheckinNeededCol } from "./distinctCols/checkinNeededCol";
import { HasChattedWithCol } from "./distinctCols/hasChattedWithCol";
import { RowSelectorCol } from "./distinctCols/rowSelectorCol";
import { IntakeOnCol } from "./distinctCols/intakeOnCol";
import { LastRegularConsultOnCol } from "./distinctCols/lastRegularConsultOnCol";
import { NextRegularConsultOnCol } from "./distinctCols/nextRegularConsultOnCol";
import { LastChatConsultOnCol } from "./distinctCols/lastChatConsultOnCol";
import { IsPatientInsuredCol } from "./distinctCols/isPatientInsuredCol";
import { IsPatientContractedCol } from "./distinctCols/isPatientContractedCol";
import { AlertsCol } from "./distinctCols/alertsCol";
import { NumberOfRegularConsultsCol } from "./distinctCols/numberOfRegularConsultsCol";
import { RegularConsultPsychologistsCol } from "./distinctCols/regularConsultPsychologistsCol";
import { TreatmentCol } from "./distinctCols/treatmentCol";
import { OffboardingCol } from "./distinctCols/offboardingCol";
import { OpenDetailsSheetCol } from "./distinctCols/openDetailsSheetCol";

export const getColumns = (
  isOnAndOffBoardingEnabled: boolean,
  isCaseLoadDetailsViewEnabled: boolean,
  isInPast: boolean
): ColumnDef<MainListPatient>[] => {
  const result = [
    RowSelectorCol,
    PatientCol,
    PatientContactInfoCol,
    AlertsCol,
    RemarksCol,
    IntakeOnCol,
    LastRegularConsultOnCol,
    LastChatConsultOnCol,
    NextRegularConsultOnCol,
    NumberOfRegularConsultsCol,
    RegularConsultPsychologistsCol,
    IsPatientInsuredCol,
    IsPatientContractedCol,
    TreatmentCol,
    YourCommentsCol,
    CheckinNeededCol,
    HasChattedWithCol,
  ];

  if (isOnAndOffBoardingEnabled && !isInPast) {
    result.push(OffboardingCol);
  }

  if (isCaseLoadDetailsViewEnabled) {
    result.push(OpenDetailsSheetCol);
  }

  return result;
};
