import React, { useState } from "react";
import { styled } from "@mui/system";
import { useMediaQuery } from "@mui/material";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { NavBarOpenStateContext } from "../NavBar/NavBarOpenStateContext";

const StyledRoot = styled("div")(({ theme, openSidebar }) => ({
  paddingTop: 56,
  height: "100%",
  [theme.breakpoints.up("sm")]: {
    paddingTop: 64,
  },
  paddingLeft: openSidebar ? 260 : 0,
}));

const StyledMain = styled("main")(() => ({
  height: "100%",
  padding: "0.2rem 2rem",
}));

const Main = ({ children }) => {
  const isDesktop = useMediaQuery("(min-width:1200px)");

  const getDefaultIsSidebarOpen = () => {
    // on mobile devices, the sidebar is hidden by default
    if (!isDesktop) return false;

    // if the user has a preference, use it
    const hasPreference = localStorage.getItem("isSidebarVisible") != undefined;
    if (hasPreference)
      return localStorage.getItem("isSidebarVisible") === "true";

    // if the user has no preference, (and we know we're on a desktop screen by now), show the sidebar
    return true;
  };

  const [isNavBarOpen, setIsNavBarOpen] = useState(getDefaultIsSidebarOpen());

  const handleSidebarToggle = () => {
    const newVal = !isNavBarOpen;
    setIsNavBarOpen(newVal);
    localStorage.setItem("isSidebarVisible", newVal);
  };

  const forceCloseSidebar = () => {
    setIsNavBarOpen(false);
    localStorage.setItem("isSidebarVisible", false);
  };

  return (
    <NavBarOpenStateContext.Provider value={{ isNavBarOpen, setIsNavBarOpen }}>
      <StyledRoot openSidebar={isNavBarOpen}>
        <Topbar onSidebarOpen={handleSidebarToggle} />
        <Sidebar
          onClose={forceCloseSidebar}
          open={isNavBarOpen}
          variant={isDesktop ? "persistent" : "temporary"}
        />
        <StyledMain>{children}</StyledMain>
      </StyledRoot>
    </NavBarOpenStateContext.Provider>
  );
};

export default Main;
