import { ReferralDetails, ManualReferral } from "../types";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import { CheckIcon, Copy, Loader2, Paperclip, Save } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "../../../components/ui/popover";
import { cn } from "../utils";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../../../components/ui/command";
import { CaretSortIcon } from "@radix-ui/react-icons";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectItem,
} from "../../../components/ui/select";
import { languages, countries, genders } from "../constants";
import { UseMutationResult } from "react-query";
import { useGetHealthcareInsurers, useGetIsPatientDuplicate } from "../hooks";
import { ScrollArea } from "../../../components/ui/scroll-area";
import { useState } from "react";
import { RequiredField } from "./fields/requiredField";
import { howDidYouHearAboutUsAnswers } from "../constants";

export const PersonalInfo = ({
  isManualReferralInput,
  saveHook,
  saveRequestMapper,
  referral,
  withLanguage,
  withHealthcareInsurer,
}: {
  isManualReferralInput: boolean;
  saveHook: () => UseMutationResult<any, unknown, any, unknown>;
  saveRequestMapper: (data: ManualReferral) => any;
  referral?: ReferralDetails;
  withLanguage?: boolean;
  withHealthcareInsurer?: boolean;
}) => {
  const isReadOnly =
    referral?.status === "AwaitingPatientApproval" ||
    referral?.status === "Rejected" ||
    referral?.status === "Cancelled" ||
    referral?.status === "Archived" ||
    referral?.status === "Closed";

  const { data: healthCareInsurers, isLoading: isLoadingHealthCareInsurers } =
    useGetHealthcareInsurers(withHealthcareInsurer === true);

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const firstFile = files[0];
      form.setValue("file", firstFile);
    }
  };

  const { mutate: save, isLoading: isSaving } = saveHook();

  function onSubmit() {
    save(saveRequestMapper(form.getValues()));
  }

  const formSchema = z.object({
    firstName: z
      .string()
      .min(1, { message: "First Name must be at least 2 characters." })
      .max(30, { message: "First Name must not be longer than 30 characters." })
      .optional(),
    lastName: z
      .string()
      .min(2, { message: "Last Name must be at least 2 characters." })
      .max(30, { message: "Last Name must not be longer than 30 characters." })
      .optional(),
    mobilePhoneNumber: z
      .string()
      .min(10, { message: "Phone Number must be at least 10 digits." })
      .optional(),
    email: z.string().email({ message: "Email must be a valid email address." }).optional(),
    bsn: z.string().optional(),
    dateOfBirth: z.string().optional(),
    originalMessage: z.string().optional(),
    gender: z.string().optional(),
    senderOrganization: z.string().optional(),
    senderPractitioner: z.string().optional(),
    zorgdomeinNumber: z.string().optional(),
    zorgdomeinProductCode: z.string().optional(),
    referralLetter: z.string().optional(),
    file: z.any().optional(),
    address: z.object({
      street: z.string().min(1, { message: "Street must be filled" }).optional(),
      houseNumber: z.string().optional(),
      houseNumberAddition: z.string().optional(),
      postalCode: z.string().optional(),
      place: z.string().optional(),
      country: z
        .string()
        .length(2, {
          message: "Country must be filled (2 character country-code)",
        })
        .optional(),
      healthCareInsurerId: z.string().optional(),
      language: z.string().optional(),
    }),
    zorgDomeinCreationDate: z.string().optional(),
  });

  const defaultValues = {
    firstName: referral?.firstName ?? "",
    lastName: referral?.lastName ?? "",
    mobilePhoneNumber: referral?.mobilePhoneNumber ?? "",
    email: referral?.email ?? "",
    bsn: referral?.bsn ?? "",
    dateOfBirth: referral?.dateOfBirth ?? undefined,
    originalMessage: referral?.originalMessage ?? "",
    senderOrganization: referral?.senderOrganization ?? "",
    senderPractitioner: referral?.senderPractitioner ?? "",
    senderOrganizationAgbCode: referral?.senderOrganizationAgbCode ?? "",
    zorgdomeinNumber: referral?.zorgdomeinNumber ?? "",
    zorgdomeinProductCode: referral?.zorgdomeinProductCode ?? "",
    gender: referral?.gender ?? undefined,
    referralLetter: undefined,
    howDidYouHearAboutUs: referral?.howDidYouHearAboutUs ?? undefined,
    address: {
      streetName: referral?.address?.streetName ?? "",
      houseNumber: referral?.address?.houseNumber ?? "",
      houseNumberAddition: referral?.address?.houseNumberAddition ?? "",
      postalCode: referral?.address?.postalCode ?? "",
      place: referral?.address?.place ?? "",
      country: referral?.address?.country ?? "NL",
    },

    healthCareInsurerId: referral?.healthCareInsurer?.id ?? "",
    language: referral?.language ?? "nl-NL",
  };

  const form = useForm<ManualReferral>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues,
  });

  const { data: isDupe } = useGetIsPatientDuplicate(form.getValues().email, form.getValues().bsn);

  const [isHealthCareInsurerOpen, setIsHealthCareInsurerOpen] = useState(false);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex">
          <div className="flex-1 mr-8">
            {withHealthcareInsurer && (
              <FormField
                control={form.control}
                name="healthCareInsurerId"
                render={({ field }) => {
                  return (
                    <FormItem className="mb-2 flex flex-col">
                      <FormLabel className="pb-1">Healthcare insurer</FormLabel>
                      {isLoadingHealthCareInsurers ? (
                        <div className="w-full border px-4 pb-2 rounded-lg flex items-center space-x-2">
                          <Loader2 className="w-4 h-4 animate-spin" />
                          <p>Loading...</p>
                        </div>
                      ) : (
                        <Popover
                          open={isHealthCareInsurerOpen}
                          onOpenChange={setIsHealthCareInsurerOpen}
                          modal={true}
                        >
                          <PopoverTrigger asChild>
                            <FormControl>
                              <Button
                                variant="outline"
                                role="combobox"
                                disabled={isReadOnly}
                                className={cn(
                                  "w-full justify-between font-normal",
                                  !field.value && "text-muted-foreground"
                                )}
                              >
                                {field.value
                                  ? healthCareInsurers?.find((x) => x.id === field.value)?.name
                                  : "Select healthcare insurer.."}
                                <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                              </Button>
                            </FormControl>
                          </PopoverTrigger>
                          <PopoverContent
                            side="bottom"
                            align="start"
                            className="p-0 z-[99999999] w-[25vw] h-[30vh]"
                          >
                            <Command>
                              <CommandInput placeholder="Search healthcare insurer..." />
                              <CommandEmpty>No healthcare insurer found.</CommandEmpty>
                              <CommandGroup className="max-h-[500px] overflow-y-auto">
                                {healthCareInsurers
                                  ?.toSorted((a, b) => a.name.localeCompare(b.name))
                                  ?.map((x) => (
                                    <CommandItem
                                      value={x.name}
                                      key={x.id}
                                      onSelect={() => {
                                        form.setValue("healthCareInsurerId", x.id);

                                        setIsHealthCareInsurerOpen(false);
                                      }}
                                    >
                                      <CheckIcon
                                        className={cn(
                                          "mr-2 h-4 w-4",
                                          x.id === field.value ? "opacity-100" : "opacity-0"
                                        )}
                                      />
                                      {x.name}
                                    </CommandItem>
                                  ))}
                              </CommandGroup>
                            </Command>
                          </PopoverContent>
                        </Popover>
                      )}
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
            )}
            <FormField
              control={form.control}
              name="firstName"
              disabled={isReadOnly}
              render={({ field }) => (
                <FormItem className="mb-2">
                  <FormLabel>
                    First Name
                    <RequiredField />
                  </FormLabel>
                  <FormControl>
                    <Input placeholder="First Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastName"
              disabled={isReadOnly}
              render={({ field }) => (
                <FormItem className="mb-2">
                  <FormLabel>
                    Last Name
                    <RequiredField />
                  </FormLabel>
                  <FormControl>
                    <Input placeholder="Last Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="mobilePhoneNumber"
              disabled={isReadOnly}
              render={({ field }) => (
                <FormItem className="mb-2">
                  <FormLabel>
                    Mobile Phone Number
                    <RequiredField />
                  </FormLabel>
                  <FormControl>
                    <Input placeholder="Mobile Phone Number" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              disabled={isReadOnly}
              render={({ field }) => (
                <FormItem className="mb-2">
                  <FormLabel>
                    Email
                    <RequiredField />
                  </FormLabel>
                  <FormControl>
                    <Input placeholder="Email" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {isManualReferralInput && (
              <FormField
                control={form.control}
                name="zorgDomeinCreationDate"
                disabled={isReadOnly}
                render={({ field }) => {
                  return (
                    <FormItem className="mb-2">
                      <FormLabel>Referral Date</FormLabel>
                      <FormControl>
                        <Input
                          type="date"
                          placeholder="Select the date of the referral"
                          {...field}
                          value={field.value?.toString()?.slice(0, 10)}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
            )}
            <FormField
              control={form.control}
              name="bsn"
              disabled={isReadOnly}
              render={({ field }) => (
                <FormItem className="mb-2">
                  <FormLabel>BSN</FormLabel>
                  <FormControl>
                    <Input placeholder="BSN" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="dateOfBirth"
              disabled={isReadOnly}
              render={({ field }) => {
                return (
                  <FormItem className="mb-2">
                    <FormLabel>Date of Birth</FormLabel>
                    <FormControl>
                      <Input
                        type="date"
                        placeholder="Date of Birth"
                        {...field}
                        value={field.value?.toString()?.slice(0, 10)}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />

            <div className="grid grid-cols-3 gap-2">
              <FormField
                control={form.control}
                name="address.streetName"
                disabled={isReadOnly}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Street</FormLabel>
                    <FormControl>
                      <Input placeholder="Street" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="address.houseNumber"
                disabled={isReadOnly}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>House number</FormLabel>
                    <FormControl>
                      <Input placeholder="House number" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="address.houseNumberAddition"
                disabled={isReadOnly}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Addition</FormLabel>
                    <FormControl>
                      <Input placeholder="Addition" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className={`pt-2 grid ${withLanguage ? "grid-cols-2" : "grid-cols-3"} gap-2`}>
              <FormField
                control={form.control}
                name="address.postalCode"
                disabled={isReadOnly}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Postal code</FormLabel>
                    <FormControl>
                      <Input placeholder="Postal code" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="address.place"
                disabled={isReadOnly}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Place</FormLabel>
                    <FormControl>
                      <Input placeholder="Place" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="address.country"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Country</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value || undefined}
                      disabled={isReadOnly}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select the country" />
                      </SelectTrigger>
                      <SelectContent className="w-full z-[99999999]">
                        <SelectGroup>
                          {countries.map((country) => (
                            <SelectItem key={country} value={country}>
                              {country}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {withLanguage && (
                <FormField
                  control={form.control}
                  name="language"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Language</FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value || undefined}
                        disabled={isReadOnly}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Select the language" />
                        </SelectTrigger>
                        <SelectContent className="w-full z-[99999999]">
                          <SelectGroup>
                            {languages.map((language) => (
                              <SelectItem key={language.code} value={language.code}>
                                {language.flag}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
            </div>
          </div>
          <div className="flex-1">
            {isManualReferralInput && (
              <FormField
                control={form.control}
                name="howDidYouHearAboutUs"
                render={({ field }) => (
                  <FormItem className="mb-2">
                    <FormLabel>How did we receive the referral?</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value || undefined}
                      disabled={isReadOnly}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select answer..." />
                      </SelectTrigger>
                      <SelectContent className="w-full z-[99999999]">
                        {howDidYouHearAboutUsAnswers.sort().map((answer) => (
                          <SelectItem value={answer} key={answer}>
                            {answer}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormItem>
                )}
              />
            )}
            <FormField
              control={form.control}
              name="gender"
              render={({ field }) => (
                <FormItem className="mb-2">
                  <FormLabel>Gender</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value || undefined}
                    disabled={isReadOnly}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select the gender" />
                    </SelectTrigger>
                    <SelectContent className="w-full z-[99999999]">
                      <SelectGroup>
                        {genders.map((gender) => (
                          <SelectItem key={gender} value={gender}>
                            {gender}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="senderOrganizationAgbCode"
              disabled={isReadOnly}
              render={({ field }) => (
                <FormItem className="mb-2">
                  <FormLabel>Sender Organization AGB Code</FormLabel>
                  <FormControl>
                    <Input placeholder="AGB Code" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="senderOrganization"
              disabled={isReadOnly}
              render={({ field }) => (
                <FormItem className="mb-2">
                  <FormLabel>Sender Organization</FormLabel>
                  <FormControl>
                    <Input placeholder="Sender Organization" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="senderPractitioner"
              disabled={isReadOnly}
              render={({ field }) => (
                <FormItem className="mb-2">
                  <FormLabel>Sender Practitioner</FormLabel>
                  <FormControl>
                    <Input placeholder="Sender Practitioner" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="zorgdomeinNumber"
              disabled={isReadOnly}
              render={({ field }) => (
                <FormItem className="mb-2">
                  <FormLabel>ZorgDomein Number</FormLabel>
                  <FormControl>
                    <Input placeholder="ZorgDomein Number" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="zorgdomeinProductCode"
              disabled={isReadOnly}
              render={({ field }) => (
                <FormItem className="mb-2">
                  <FormLabel>ZorgDomein Product Code</FormLabel>
                  <FormControl>
                    <Input placeholder="ZorgDomein Product Code" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {isManualReferralInput && (
              <FormField
                control={form.control}
                name="referralLetter"
                disabled={isReadOnly}
                render={({ field }) => {
                  return (
                    <FormItem className="mb-2">
                      <FormLabel>Referral Letter</FormLabel>
                      <FormControl>
                        <div className="cursor-pointer relative">
                          <label
                            htmlFor="referralLetter"
                            className="cursor-pointer flex items-center space-x-2"
                          >
                            <div className="w-4 h-4">
                              <Paperclip className="w-4 h-4" />
                            </div>
                            <Input
                              id="referralLetter"
                              type="file"
                              {...field}
                              accept=".pdf, image/*"
                              onChange={onFileChange}
                            />
                          </label>
                        </div>
                      </FormControl>
                      <FormDescription>
                        <p className="italic pl-6">Upload the referral letter here.</p>
                      </FormDescription>

                      <FormField
                        control={form.control}
                        name="file"
                        render={() => {
                          return <FormMessage />;
                        }}
                      />
                    </FormItem>
                  );
                }}
              />
            )}

            <div
              className={`${
                !referral?.id && isDupe ? "visible" : "hidden"
              } bg-gray-100 dark:bg-slate-800 p-4 rounded text-sm mt-5 flex items-start space-x-2`}
            >
              <div className="h-6 w-6 pt-1">
                <Copy className="h-4 w-4" />
              </div>
              <p>It looks like there is a patient already with this BSN and email.</p>
            </div>

            {!isReadOnly && (
              <div className="w-full flex justify-end pt-6">
                <Button
                  className="mt-2"
                  type="submit"
                  // disabled={Object.keys(form.formState.dirtyFields).length === 0}
                >
                  <Save className="w-4 h-4 mr-2" />
                  <p>{isSaving ? "Saving..." : "Save"}</p>
                </Button>
              </div>
            )}
          </div>
        </div>
      </form>
    </Form>
  );
};
