import { ActiveStatus } from "./statuses/active";
import { NotFoundStatus } from "./statuses/notFound";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { getSimpleFormattedDateTime } from "../caseLoadCockpit/utils";
import { BigRegistrationReport, BigRegistrationReportStatus } from "./types";
import { Loader2 } from "lucide-react";
import { BigRegistrationCodeColumn } from "./bigRegisterColumn/bigRegistrationCodeColumn";

export const OverviewTable = ({
  isLoading,
  data,
}: {
  isLoading: boolean;
  data: BigRegistrationReport[];
}) => {
  const getRowClass = (status: BigRegistrationReportStatus) => {
    switch (status) {
      case "Active":
        return "bg-green-50/50 hover:bg-green-50";
      case "NotFound":
        return "bg-red-50/50 hover:bg-red-50";
      default:
        return "";
    }
  };

  const getStatusComponent = (status: BigRegistrationReportStatus) => {
    switch (status) {
      case "Active":
        return <ActiveStatus />;
      case "NotFound":
        return <NotFoundStatus />;
      default:
        return <></>;
    }
  };

  const renderTableBody = () => {
    if (isLoading) {
      return (
        <TableRow>
          <TableCell colSpan={4}>
            <div className="p-12 flex items-center justify-center space-x-2">
              <div className="w-4 h-4">
                <Loader2 className="w-4 h-4 opacity-70 animate-spin" />
              </div>
              <p>Loading...</p>
            </div>
          </TableCell>
        </TableRow>
      );
    }

    if (data?.length == 0) {
      return (
        <TableRow>
          <TableCell colSpan={4}>
            <div className="p-2 flex items-center justify-center space-x-2">
              <p>No results.</p>
            </div>
          </TableCell>
        </TableRow>
      );
    }

    return (
      <>
        {data!
          .toSorted((a, b) => a.email.localeCompare(b.email))
          .map((x) => (
            <TableRow key={x.email} className={getRowClass(x.status)}>
              <TableCell className="py-1">{x.email}</TableCell>
              <TableCell className="py-1">
                <BigRegistrationCodeColumn data={x} />
              </TableCell>
              <TableCell className="py-1">
                {getStatusComponent(x.status)}
              </TableCell>
              <TableCell className="py-1 flex justify-end items-center">
                {getSimpleFormattedDateTime(x.lastChecked)}
              </TableCell>
            </TableRow>
          ))}
      </>
    );
  };

  return (
    <Table limitHeight maxHeight="max-h-[70vh]" className="border rounded">
      <TableHeader className="border-b">
        <TableRow>
          <TableHead>Psychologist</TableHead>
          <TableHead>BIG Registration Code</TableHead>
          <TableHead>Status</TableHead>
          <TableHead className="flex justify-end items-center">
            Last Refreshed
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>{renderTableBody()}</TableBody>
    </Table>
  );
};
