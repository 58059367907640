import { useState } from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import { PsychologistIntakeAssignment } from "./types";
import { IntakeLimitRow } from "./IntakeLimitRow";
import { hasDeviation } from "./utils";
import { FiltersButton } from "./filters/filtersButton";
import { SelectableValue } from "../../../../components/ui/multiselect";
import { useGetIntakeDeviationsForAdmin } from "../../hooks";
import { Info, Loader2 } from "lucide-react";
import { getEmployeeLastName } from "../../../../utils/getEmployeeLastName";

export type Row = {
  epdEmployeeId: string;
  employeeName: string;
  locations: string[];
  isCurrentWeekPartOfIndefinite: boolean;
  currentWeekNumberOfIntakes: number;
  futureWeeks: {
    start: string;
    end: string | undefined;
    isIndefinite: boolean;
    numberOfIntakes: number;
  }[];
  comments: string | undefined;
};

export const IntakeLimitsTable = () => {
  const [showOnlyDeviations, setShowOnlyDeviations] = useState(false);
  const [locationsFilter, setLocationsFilter] = useState<SelectableValue[]>([]);

  const { data, isLoading } = useGetIntakeDeviationsForAdmin();

  function groupAndTransform(
    assignments: PsychologistIntakeAssignment[]
  ): Row[] {
    const grouped: { [key: string]: Row } = {};

    for (const assignment of assignments) {
      if (!grouped[assignment.epdEmployeeId]) {
        grouped[assignment.epdEmployeeId] = {
          epdEmployeeId: assignment.epdEmployeeId,
          employeeName: assignment.employeeName,
          locations: assignment.locations,
          currentWeekNumberOfIntakes: 3,
          isCurrentWeekPartOfIndefinite: assignment.isCurrentWeekIndefinite,
          futureWeeks: [],
          comments: assignment.comments,
        };
      }

      if (assignment.isCurrentWeek) {
        grouped[assignment.epdEmployeeId].currentWeekNumberOfIntakes =
          assignment.numberOfIntakes;

        if (assignment.isIndefinite) {
          grouped[assignment.epdEmployeeId].futureWeeks.push({
            start: assignment.start,
            end: assignment.end,
            isIndefinite: assignment.isIndefinite,
            numberOfIntakes: assignment.numberOfIntakes,
          });
        }
      } else {
        grouped[assignment.epdEmployeeId].futureWeeks.push({
          start: assignment.start,
          end: assignment.end,
          isIndefinite: assignment.isIndefinite,
          numberOfIntakes: assignment.numberOfIntakes,
        });
      }
    }

    let result = Object.values(grouped);

    if (showOnlyDeviations) {
      result = result.filter((psy) => hasDeviation(psy));
    }

    if (locationsFilter.length > 0) {
      result = result.filter((psy) =>
        locationsFilter.some((loc) => psy.locations.includes(loc.value))
      );
    }

    return result.sort((a, b) =>
      getEmployeeLastName(a.employeeName).localeCompare(
        getEmployeeLastName(b.employeeName)
      )
    );
  }

  return (
    <div>
      <div className="flex items-center justify-end space-x-4">
        <div className="w-full flex justify-between items-start">
          <div>
            <h1 className="text-xl font-bold">
              Manage the maximum number of intakes
            </h1>
            <p>
              Manage how many <span className="underline">intakes per day</span>{" "}
              the psychologists can accept.
            </p>

            <div className="mt-4 pl-2 pr-4 py-2 bg-blue-50 dark:bg-slate-800 inline-flex items-start space-x-2 rounded-lg">
              <div className="h-4 w-4 pt-1">
                <Info className="h-4 w-4" />
              </div>
              <div>
                <h2>
                  After updating the deviations, the Dashboard will
                  automatically re-run the calendar algorithm.
                </h2>
                <p className="text-xs">
                  This process typically takes 3-5 minutes. Your modifications
                  will appear on the calendar once the algorithm finishes.
                </p>
              </div>
            </div>
          </div>

          <FiltersButton
            showOnlyDeviations={showOnlyDeviations}
            setShowOnlyDeviations={setShowOnlyDeviations}
            locationsFilter={locationsFilter}
            setLocationsFilter={setLocationsFilter}
          />
        </div>
      </div>

      <div className="border rounded mt-6">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-1/6">Psychologist</TableHead>
              <TableHead className="w-1/5">Location(s)</TableHead>
              <TableHead className="w-[60px]">Current Week</TableHead>
              <TableHead className="w-1/4">Future Weeks</TableHead>
              <TableHead className="w-1/4">Comments</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {isLoading ? (
              <div className="p-4 flex items-center space-x-2">
                <Loader2 className="w-4 h-4 animate-spin" />
                <p>Loading...</p>
              </div>
            ) : (
              <>
                {groupAndTransform(data!).length == 0 ? (
                  <div className="p-4 flex items-center space-x-2">
                    No entries found.
                  </div>
                ) : (
                  groupAndTransform(data!).map((psy) => (
                    <IntakeLimitRow data={psy} />
                  ))
                )}
              </>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
