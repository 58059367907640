import { useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import List from "@mui/material/List";
import * as colors from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Divider from "@mui/material/Divider";
import ListSubheader from "@mui/material/ListSubheader";
import { observer } from "mobx-react";
import useStores from "../../useStores";
import getInitials from "../../utils/getInitials";
import NavItem from "./NavItem";
import navConfig from "../../navConfig";
import { styled } from "@mui/system";
import { MoreVertical } from "lucide-react";
import { useIsEnabled } from "../../feature-management/useIsEnabled";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import { Globe, LogOut, RefreshCwOff, RefreshCw } from "lucide-react";
import { useTranslation } from "react-i18next";
import { DutchFlag, GbFlag } from "../../shared/Flags";
import { AdminMenu } from "./AdminMenu";
import roleNames from "../../utils/constants/roleNames";
import { useGetAuthorizations } from "../../shared/hooks/useGetAuthorizations";

const StyledRoot = styled("div")(() => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  marginTop: "64px",
  maxWidth: "260px",
}));

const StyledNavigation = styled("nav")(({ theme }) => ({
  overflow: "auto",
  padding: theme.spacing(2),
  flexGrow: 1,
  color: "red",
}));

const StyledProfile = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  alignItems: "center",
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-dot": {
    backgroundColor: colors.green[600],
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    width: 9,
    height: 9,
    borderRadius: "50%",
  },
}));

const StyledAvatar = styled(Avatar)(() => ({
  width: 40,
  height: 40,
}));

const StyledDetails = styled("div")(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

function reduceChildRoutes({ navItems, pathname, item, depth = 0 }) {
  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    navItems.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={item.href}
        label={item.label}
        open={Boolean(open)}
        title={item.title}
      >
        {/* eslint-disable-next-line no-use-before-define */}
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    navItems.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={item.href}
        label={item.label}
        title={item.title}
      />
    );
  }

  return navItems;
}

function renderNavItems({ items, subheader, key, visibleByRole, userRoles }) {
  if (visibleByRole === undefined || userRoles.includes(visibleByRole)) {
    return (
      <List key={key}>
        {subheader && <ListSubheader disableSticky>{subheader}</ListSubheader>}
        {items.reduce(
          (navItems, item) =>
            reduceChildRoutes({
              navItems,
              item,
            }),
          []
        )}
      </List>
    );
  }

  return null;
}

const SidebarNav = () => {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const { authStore } = useStores();
  const [isUsingOldDesign, setIsUsingOldDesign] = useState(
    localStorage.getItem("force-old-design") === "true"
  );
  const { data: authorization } = useGetAuthorizations();

  const isCrmEnabled =
    authStore.roles.includes(roleNames.ADMINISTRATOR) ||
    authorization?.position == "Matching Psychologist";

  const [navigationItems, setNavigationItems] = useState(navConfig);

  useIsEnabled("EnableCustomerRelationshipManagerApi", {
    onSuccess: (data) => {
      // enabled for all MPs (based on role), but also maybe for some additional users via feature flags
      if (data || isCrmEnabled) {
        const iPracticeSectionIndex = navigationItems.findIndex(
          (x) => x.subheader === "iPractice"
        );

        const updatedNavigationItems = [...navigationItems];
        updatedNavigationItems[iPracticeSectionIndex].items.push({
          title: "Patiëntenverwijzingen",
          href: "/patient-referrals",
          icon: AssignmentOutlinedIcon,
        });

        setNavigationItems(updatedNavigationItems);
      }
    },
  });

  return (
    <StyledRoot>
      <StyledNavigation>
        {navigationItems.map((list) =>
          renderNavItems({
            items: list.items,
            subheader: list.subheader,
            visibleByRole: list.visibleByRole,
            pathname: location.pathname,
            key: list.subheader,
            userRoles: authStore.roles,
          })
        )}

        <AdminMenu />
      </StyledNavigation>

      <Divider />

      <StyledProfile>
        <StyledBadge
          overlap="circular"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          variant="dot"
        >
          <StyledAvatar alt="Person">
            {getInitials(authStore.user.userName)}
          </StyledAvatar>
        </StyledBadge>
        <div className="w-full flex justify-between items-center">
          <StyledDetails>
            <Typography variant="h5" color="textPrimary">
              {authStore.username}
            </Typography>
            <Typography variant="body2">{authStore.email}</Typography>
          </StyledDetails>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <MoreVertical className="w-4 h-4 cursor-pointer" />
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className="w-72"
              style={{ zIndex: 99999 }}
              side="right"
              align="end"
            >
              <DropdownMenuGroup>
                <DropdownMenuSub>
                  <DropdownMenuSubTrigger>
                    <Globe className="mr-2 h-4 w-4" />
                    <span>{t("profile-action-menu-change-language")}</span>
                  </DropdownMenuSubTrigger>
                  <DropdownMenuPortal>
                    <DropdownMenuSubContent className="min-w-32">
                      <DropdownMenuItem
                        className="cursor-pointer flex items-center space-x-2"
                        onClick={async (e) => {
                          e.preventDefault();
                          localStorage.setItem("preferred-lang", "nl");
                          await i18n.changeLanguage("nl");
                        }}
                      >
                        <DutchFlag className="mr-2 h-4 w-4" />
                        <span
                          className={`${
                            i18n.language === "nl" ? "font-bold" : "font-normal"
                          }`}
                        >
                          Nederlands
                        </span>
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        className="cursor-pointer flex items-center space-x-2"
                        onClick={async (e) => {
                          e.preventDefault();
                          localStorage.setItem("preferred-lang", "en");
                          await i18n.changeLanguage("en");
                        }}
                      >
                        <GbFlag className="h-4 w-4" />
                        <span
                          className={`${
                            i18n.language === "en" ? "font-bold" : "font-normal"
                          }`}
                        >
                          English
                        </span>
                      </DropdownMenuItem>
                    </DropdownMenuSubContent>
                  </DropdownMenuPortal>
                </DropdownMenuSub>
              </DropdownMenuGroup>
              <DropdownMenuItem
                className="cursor-pointer"
                onClick={() => {
                  const newValue = !isUsingOldDesign;
                  setIsUsingOldDesign(newValue);

                  if (newValue) {
                    localStorage.setItem("force-old-design", "true");
                  } else {
                    localStorage.removeItem("force-old-design");
                  }

                  window.location.reload();
                }}
              >
                {isUsingOldDesign ? (
                  <RefreshCw className="mr-2 h-4 w-4" />
                ) : (
                  <RefreshCwOff className="mr-2 h-4 w-4" />
                )}
                {isUsingOldDesign
                  ? "Switch to the new design"
                  : "Switch to the old design"}
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                className="cursor-pointer"
                onClick={() => authStore.logOut()}
              >
                <LogOut className="mr-2 h-4 w-4" />
                <span>{t("profile-action-menu-log-out")}</span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </StyledProfile>
    </StyledRoot>
  );
};

export default observer(SidebarNav);
