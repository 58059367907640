import { useGetBacklogStatisticsReport } from "../../hooks";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../../components/ui/table";
import { Skeleton } from "../../../../../components/ui/skeleton";

export const BacklogReportTable = ({
  locationIds,
}: {
  locationIds: string[];
}) => {
  const { data, isLoading } = useGetBacklogStatisticsReport(locationIds);

  if (isLoading) {
    return (
      <div className="mt-6">
        <Skeleton className="h-[450px] w-full rounded" />
      </div>
    );
  }

  return (
    <div className="mt-6 overflow-x-auto">
      <Table className="border min-w-[1200px] table-auto">
        <TableHeader>
          <TableRow>
            <TableHead>Location</TableHead>
            <TableHead className="w-[150px]">New</TableHead>
            <TableHead className="w-[150px]">Reach Out</TableHead>
            <TableHead className="w-[150px]">
              Register Patient & Intakes
            </TableHead>
            <TableHead className="w-[150px]">
              Awaiting Patient Approval
            </TableHead>
            <TableHead className="w-[150px]">
              Awaiting sending IWelcome
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data && data.length > 0 ? (
            data.map((d) => {
              return (
                <TableRow key={d.location}>
                  <TableCell>{d.location}</TableCell>
                  <TableCell className="w-[150px]">{d.countOfNew}</TableCell>
                  <TableCell className="w-[150px]">
                    {d.countOfReachOut}
                  </TableCell>
                  <TableCell className="w-[150px]">
                    {d.countOfRegisterPatientAndIntake}
                  </TableCell>
                  <TableCell className="w-[150px]">
                    {d.countOfAwaitingPatientApproval}
                  </TableCell>
                  <TableCell className="w-[150px]">
                    {d.countOfAwaitingIWelcomeEmailSend}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={6} className="py-1">
                No data found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};
