import { useSnackbar } from "notistack";
import axios from "axios";
import * as HttpStatus from "http-status-codes";
import * as Sentry from "@sentry/browser";

export const sentryDontLog = "sentry_dont_log";

const SENTRY_ERROR = "error";

const AxiosSetup = () => {
  const { enqueueSnackbar } = useSnackbar();

  axios.defaults.baseURL = `${window.location.protocol}//${window.location.host}/api/`;
  axios.defaults.headers.common.Accept = "application/json";
  axios.defaults.xsrfHeaderName = "X-XSRF-TOKEN";
  axios.defaults.withCredentials = true;

  axios.interceptors.response.use(
    (res) => {
      return Promise.resolve(res);
    },
    (error) => {
      if (!error.response) {
        enqueueSnackbar("No response from server", { variant: "error" });

        return Promise.reject(error);
      }
      switch (error.response.status) {
        case HttpStatus.INTERNAL_SERVER_ERROR:
          enqueueSnackbar("Server error", { variant: "error" });
          Sentry.captureMessage("INTERNAL_SERVER_ERROR 500", SENTRY_ERROR);
          break;
        case HttpStatus.NOT_FOUND:
          // user not found during /authorization -> redirect to login
          if (error.request.responseURL.includes("authorization")) {
            window.location.href = "/identity/account/login";
          }
          break;
        case HttpStatus.UNAUTHORIZED:
        case HttpStatus.FORBIDDEN:
          return Promise.reject({
            message: sentryDontLog,
            status: error.response.status,
          });
        case HttpStatus.REQUEST_TOO_LONG:
          enqueueSnackbar(
            "Bestand is te groot, verklein het bestand en probeer opnieuw.",
            { variant: "error" }
          );
          break;
        case HttpStatus.BAD_REQUEST:
        case HttpStatus.CONFLICT:
          break;
        default:
          Sentry.captureMessage(
            `Error: Other status code: ${error.response.status}`,
            SENTRY_ERROR
          );
          break;
      }

      return Promise.reject(error);
    }
  );

  return null;
};

export default AxiosSetup;
