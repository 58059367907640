import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import axios from "axios";

class AuthStore {
  user = {};

  constructor() {
    makeObservable(this, {
      user: observable,
      username: computed,
      email: computed,
      roles: computed,
    });
  }

  get username() {
    return (
      this.user.userName &&
      this.user.userName.substr(0, this.user.userName.indexOf("@"))
    );
  }

  get email() {
    return this.user.userName;
  }

  get roles() {
    if (this.user.roles) {
      return this.user.roles;
    }

    return [];
  }

  async logOut() {
    await axios.post(
      "identity/account/logout",
      {},
      { baseURL: `${window.location.protocol}//${window.location.host}` }
    );

    runInAction(() => {
      this.user = {};
      window.location.href = "/identity/account/login";
    });
  }

  setAuthorization(data) {
    this.user = data;
  }
}

export default AuthStore;
