import React from "react";
import { Grid } from "@mui/material";
import CreateDossierGgz from "./CreateDossierGgz";
import StyledRoot from "../../shared/StyledRootDiv";

const CreateDossierGgzPage = ({ match }) => {
  return (
    <StyledRoot>
      <Grid container justifyContent="center">
        <Grid item xs={8}>
          <CreateDossierGgz patientId={match.params.patientId} />
        </Grid>
      </Grid>
    </StyledRoot>
  );
};

export default CreateDossierGgzPage;
