const UnauthorizedPage = () => {
  return (
    <main className="min-h-screen w-full bg-gradient-to-tl dark:from-gray-950 dark:to-gray-900 from-blue-400 via-blue-200 via-20% to-blue-100 text-foreground-muted flex items-center justify-center">
      <div className="text-center flex flex-col items-center justify-center w-full">
        <h1 className="text-6xl font-bold">Unauthorized</h1>
        <p className="pt-8 text-lg w-1/3">
          It looks like your account is not (yet) setup with the necessary roles
          to access the iPractice Dashboard.
        </p>
        <p className="pt-2">
          Please contact IT Support at support@ipractice.nl, or{" "}
          <span
            onClick={() => (window.location.href = "/identity/account/login")}
            className="text-link cursor-pointer hover:underline"
          >
            go back to the login page.
          </span>
        </p>
      </div>
    </main>
  );
};

export default UnauthorizedPage;
