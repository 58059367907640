import React, { useEffect } from "react";
import { Grid, Box } from "@mui/material";
import { observer } from "mobx-react";
import EditPatient from "../../shared/EditPatient";
import useStores from "../../useStores";
import Dossiers from "./Dossiers";
import Loading from "../../shared/Loading";
import Header from "../../shared/Header";
import StyledRoot from "../../shared/StyledRootDiv";

const PatientPage = ({ match }) => {
  const { patientStore } = useStores();
  const { patientId, dossierId } = match.params;

  useEffect(() => {
    if (dossierId === null) {
      patientStore.fetchPatientInformation(patientId);
    } else {
      patientStore.fetchPatientInformation(patientId, parseInt(dossierId));
    }

    return function cleanup() {
      patientStore.reset();
    };
  }, [patientId, dossierId, patientStore]);

  if (
    patientStore.patient.dossiers == null ||
    typeof patientStore.patient.dossiers === "undefined"
  ) {
    return <Loading />;
  }

  return (
    <StyledRoot>
      <Header
        pageTitle="cliënt"
        title={`${patientStore.patient.firstName} ${patientStore.patient.lastName}`}
      />

      <Grid container spacing={4}>
        <Grid item xs={6} xl={4}>
          <Box mb={4}>
            <EditPatient />
          </Box>
        </Grid>
        <Grid item xs={12} xl={6}>
          <Dossiers patientId={patientId} />
        </Grid>
      </Grid>
    </StyledRoot>
  );
};

export default observer(PatientPage);
