import { useEffect, useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectGroup,
  SelectValue,
} from "../../../components/ui/select";
import { Input } from "../../../components/ui/input";
import {
  useSavePsychologistPositionTrajectory,
  useSaveTotalWorkingHours,
  useSaveWorkingHoursAsOnlinePsychologist,
} from "../hooks";
import { PreIntakeReportPositionTrajectory } from "../types";
import useStores from "../../../useStores";
import roleNames from "../../../utils/constants/roleNames";
import { useDebounce } from "use-debounce";

export const DetailsColumn = ({
  psychologistEpdId,
  originalTotalWorkingHours,
  originalWorkingHoursAsOnlinePsychologist,
  originalPositionTrajectory,
}: {
  psychologistEpdId: number;
  originalTotalWorkingHours: number;
  originalWorkingHoursAsOnlinePsychologist: number;
  originalPositionTrajectory: PreIntakeReportPositionTrajectory;
}) => {
  const { authStore } = useStores();
  const isReadOnly = !authStore.roles.includes(roleNames.ADMINISTRATOR);
  const [hasMounted, setHasMounted] = useState(false);

  const readableOpTrajectory = "OP";
  const readableOpAndTransferTrajectory = "OP + Transfer";
  const readableOpAndSkpTrajectory = "OP + SKP";
  const readableOpInGermanyTrajectory = "OP in Germany";

  type readablePositionTrajectoryType =
    | typeof readableOpTrajectory
    | typeof readableOpAndTransferTrajectory
    | typeof readableOpAndSkpTrajectory
    | typeof readableOpInGermanyTrajectory;

  const getReadablePositionTrajectory = (
    positionTrajectory: PreIntakeReportPositionTrajectory
  ) => {
    switch (positionTrajectory) {
      case PreIntakeReportPositionTrajectory.OnlyOnlinePsychologist:
        return readableOpTrajectory;
      case PreIntakeReportPositionTrajectory.OnlinePsychologistAndTransfer:
        return readableOpAndTransferTrajectory;
      case PreIntakeReportPositionTrajectory.OnlinePsychologistTransitioningToConsultationRoomPsychologist:
        return readableOpAndSkpTrajectory;
      case PreIntakeReportPositionTrajectory.OnlinePsychologistInGermany:
        return readableOpInGermanyTrajectory;
    }
  };

  const getTrajectory = (
    positionTrajectory: readablePositionTrajectoryType
  ) => {
    switch (positionTrajectory) {
      case readableOpTrajectory:
        return PreIntakeReportPositionTrajectory.OnlyOnlinePsychologist;
      case readableOpAndTransferTrajectory:
        return PreIntakeReportPositionTrajectory.OnlinePsychologistAndTransfer;
      case readableOpAndSkpTrajectory:
        return PreIntakeReportPositionTrajectory.OnlinePsychologistTransitioningToConsultationRoomPsychologist;
      case readableOpInGermanyTrajectory:
        return PreIntakeReportPositionTrajectory.OnlinePsychologistInGermany;
    }
  };

  const [totalWorkingHours, setTotalWorkingHours] = useState<number>(
    originalTotalWorkingHours
  );
  const [debouncedTotalWorkingHours] = useDebounce(totalWorkingHours, 1000);

  const [
    workingHoursAsOnlinePsychologist,
    setWorkingHoursAsOnlinePsychologist,
  ] = useState<number>(originalWorkingHoursAsOnlinePsychologist);

  const [debouncedWorkingHoursAsOnlinePsychologist] = useDebounce(
    workingHoursAsOnlinePsychologist,
    1000
  );

  const [trajectory, setTrajectory] = useState<readablePositionTrajectoryType>(
    getReadablePositionTrajectory(originalPositionTrajectory)
  );

  const {
    mutate: saveTotalWorkingHours,
    isLoading: isSavingTotalWorkingHours,
  } = useSaveTotalWorkingHours(() => {
    alert("Something went wrong - please ping the dev team");
  });

  const {
    mutate: saveWorkingHoursAsPsychologist,
    isLoading: isSavingWorkingHoursAsPsychologist,
  } = useSaveWorkingHoursAsOnlinePsychologist(() => {
    alert("Something went wrong - please ping the dev team");
  });

  const { mutate: saveTrajectory, isLoading: isSavingTrajectory } =
    useSavePsychologistPositionTrajectory(() => {
      alert("Something went wrong - please ping the dev team");
    });

  useEffect(() => {
    if (!hasMounted) {
      setHasMounted(true);
      return;
    }

    saveTotalWorkingHours({
      psychologistEpdId,
      totalWorkingHours: debouncedTotalWorkingHours,
    });
  }, [debouncedTotalWorkingHours]);

  useEffect(() => {
    if (!hasMounted) {
      setHasMounted(true);
      return;
    }

    saveWorkingHoursAsPsychologist({
      psychologistEpdId,
      workingHoursAsOnlinePsychologist:
        debouncedWorkingHoursAsOnlinePsychologist,
    });
  }, [debouncedWorkingHoursAsOnlinePsychologist]);

  return (
    <div className="space-y-2">
      {isReadOnly ? (
        <div>
          <p className="text-xs">
            OP hours:{" "}
            <span className="font-bold">
              {workingHoursAsOnlinePsychologist}
            </span>
          </p>
          <p className="text-xs">
            Contract: <span className="font-bold">{totalWorkingHours}</span>
          </p>
        </div>
      ) : (
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <p className="text-xs w-[80px]">OP hours:</p>
            <Input
              className="w-[80px]"
              disabled={isSavingWorkingHoursAsPsychologist}
              value={workingHoursAsOnlinePsychologist}
              onChange={(e: any) => {
                setWorkingHoursAsOnlinePsychologist(Number(e.target.value));
              }}
            />
          </div>

          <div className="flex items-center space-x-2">
            <p className="text-xs w-[80px]">Contract:</p>

            <Input
              className="w-[80px]"
              disabled={isSavingTotalWorkingHours}
              value={totalWorkingHours}
              onChange={(e: any) => {
                setTotalWorkingHours(Number(e.target.value));
              }}
            />
          </div>
        </div>
      )}

      {isReadOnly ? (
        <p className="text-xs">{trajectory}</p>
      ) : (
        <Select
          value={trajectory}
          disabled={isReadOnly || isSavingTrajectory}
          onValueChange={async (x: readablePositionTrajectoryType) => {
            setTrajectory(x);
            await saveTrajectory({
              psychologistEpdId,
              positionTrajectory: getTrajectory(x),
            });
          }}
        >
          <SelectTrigger className="w-full">
            <SelectValue />
          </SelectTrigger>
          <SelectContent className={`z-[9999999] max-h-[30vh]`}>
            <SelectGroup>
              <SelectItem value={readableOpTrajectory}>
                {readableOpTrajectory}
              </SelectItem>
              <SelectItem value={readableOpAndTransferTrajectory}>
                {readableOpAndTransferTrajectory}
              </SelectItem>
              <SelectItem value={readableOpAndSkpTrajectory}>
                {readableOpAndSkpTrajectory}
              </SelectItem>
              <SelectItem value={readableOpInGermanyTrajectory}>
                {readableOpInGermanyTrajectory}
              </SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      )}
    </div>
  );
};
