import { Loader2 } from "lucide-react";
import { useTranslation } from "react-i18next";

export const PatientRemarks = ({
  isLoading,
  remarks,
}: {
  isLoading: boolean;
  remarks: string[];
}) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div className="pt-1 flex items-center space-x-1">
        <p>{t("case-load-manager-column-remarks")}:</p>
        <div className="text-xs flex items-center space-x-1">
          <Loader2 className="w-3 h-3 animate-spin" />
          <p className="italic">{t("case-load-manager-loading")}</p>
        </div>
      </div>
    );
  }

  if (remarks.length === 0) {
    return (
      <div className="pt-1 flex items-center space-x-1">
        <p>
          {t("case-load-manager-column-remarks")}:{" "}
          {t("case-load-manager-no-remarks")}.
        </p>
      </div>
    );
  }

  return (
    <div className="pt-1">
      <p>{t("case-load-manager-column-remarks")}:</p>
      <ul className="list-inside list-disc pl-1">
        {remarks.map((remark, i) => (
          <li key={i}>{remark}</li>
        ))}
      </ul>
    </div>
  );
};
