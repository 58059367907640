import { SlidersHorizontal } from "lucide-react";
import { Button } from "../../../../../components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../../components/ui/popover";
import { DeviationsOnlyFilter } from "./deviationsOnlyFilter";
import { LocationsFilter } from "../../locationsFilter";
import { SelectableValue } from "../../../../../components/ui/multiselect";

export const FiltersButton = ({
  showOnlyDeviations,
  setShowOnlyDeviations,
  locationsFilter,
  setLocationsFilter,
}: {
  showOnlyDeviations: boolean;
  setShowOnlyDeviations: (newValue: boolean) => void;
  locationsFilter: SelectableValue[];
  setLocationsFilter: React.Dispatch<React.SetStateAction<SelectableValue[]>>;
}) => {
  const getNumberOfFiltersInUse = () => {
    const deviationFilter = showOnlyDeviations ? 1 : 0;
    const locationFilter = locationsFilter.length;

    return deviationFilter + locationFilter;
  };
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" className="flex items-center space-x-2">
          <SlidersHorizontal className="w-4 h-4" />
          <p>Filters</p>
          {getNumberOfFiltersInUse() > 0 && (
            <div className="text-xs w-4 h-4 rounded-full bg-ipractice-blue text-center text-white">
              {getNumberOfFiltersInUse()}
            </div>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent align="end" side="bottom" className="min-w-[500px]">
        <h2 className="font-bold text-xl">Set filters</h2>
        <div className="flex flex-col space-y-4 mt-6">
          <DeviationsOnlyFilter
            isChecked={showOnlyDeviations}
            onCheckedChange={setShowOnlyDeviations}
          />
          <LocationsFilter
            selected={locationsFilter}
            onSelectionChange={setLocationsFilter}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
};
