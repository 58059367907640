import { Button } from "../../../../components/ui/button";
import { AlertCode, MainListPatient } from "../../types";
import { AlertCircle } from "lucide-react";
import { useTranslation } from "react-i18next";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../../components/ui/tooltip";
import { useResolveAlerts } from "../hooks";
import { toast } from "sonner";
import { InactiveDossierOfCaseLoadEntry } from "./inactiveDossierOfCaseLoadEntry";

export const AlertIndicator = ({
  data,
  showLabel,
}: {
  data: MainListPatient;
  showLabel: boolean;
}) => {
  const { t } = useTranslation();
  const alerts = data.alerts;

  const { mutate: resolveAlerts, isLoading: isResolvingAlerts } =
    useResolveAlerts(() => {
      toast(t("case-load-manager-resolved-alert-success-toast-title"), {
        description: t("case-load-manager-resolved-alert-success-toast-desc"),
      });
    });

  if (alerts.some((a) => a.code === "DossierNoLongerActive")) {
    return (
      <InactiveDossierOfCaseLoadEntry
        caseLoadId={data.id}
        patientName={data.name}
      />
    );
  }

  const unresolvedAlerts = alerts.filter((a) => !a.hasMarkedAsCompleted);

  if (unresolvedAlerts.length === 0) return <></>;

  const getReadableAlertText = (alertCode: AlertCode) => {
    switch (alertCode) {
      case "NoRegularAppointmentsInLastMonth":
        return t("case-load-manager-alert-no-regular-appointment");
      case "NoChatAppointmentsInLastMonth":
        return t("case-load-manager-alert-no-chat-appointment");
      case "ChatAppointmentCreatedOutsideBusinessHours":
        return t("case-load-manager-alert-chat-appointment-outside-hours");
      default:
        return t("case-load-manager-alert-unknown");
    }
  };

  return (
    <div>
      <TooltipProvider delayDuration={100}>
        <Tooltip>
          <TooltipTrigger className="w-full flex items-center justify-center space-x-2">
            <AlertCircle className="h-5 w-5 text-red-600" />
            {showLabel && <p className="font-bold hover:underline">Alerts</p>}
          </TooltipTrigger>
          <TooltipContent style={{ zIndex: 99999 }} className="max-w-[24rem]">
            <ul className="list-inside list-disc p-2">
              {unresolvedAlerts.map((a) => {
                return <li key={a.code}>{getReadableAlertText(a.code)}</li>;
              })}
            </ul>

            <Button
              className="mt-3 text-sm w-full"
              onClick={async (e) => {
                if (isResolvingAlerts) return;

                await resolveAlerts({ caseLoadEntryId: data.id });
              }}
            >
              {isResolvingAlerts
                ? t(
                    "case-load-manager-alert-resolving-single-alert-button-caption"
                  )
                : t(
                    "case-load-manager-alert-resolve-single-alert-button-caption"
                  )}
            </Button>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};
