import { useTranslation } from "react-i18next";
import { ProgressChart } from "./progressChart/progressChart";
import { OpToCrpPsychologistRatio } from "./psychologistRatio/opToCrpPsychologistRatio";

export const CaseloadStatistics = () => {
  const { t } = useTranslation();

  return (
    <div>
      <ProgressChart />

      <div className="mt-12 pt-12 border-t">
        <OpToCrpPsychologistRatio />
      </div>
    </div>
  );
};
