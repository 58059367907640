import { Loader2, Undo2 } from "lucide-react";
import { Button } from "../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogDescription,
  DialogTitle,
} from "../../../components/ui/dialog";
import { useState } from "react";
import { useDeleteOffboardingData } from "./hooks";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";

export const UndoMarkAsOffboarding = ({ id }: { id: string }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const { mutate: save, isLoading: isSaving } = useDeleteOffboardingData(() => {
    toast("Successful undo", {
      description:
        "You have undid the marking of the next consult as the last.",
    });
  });

  return (
    <>
      <div
        className="inline-flex items-center space-x-2 pt-1 text-link cursor-pointer hover:underline text-xs"
        onClick={() => setIsOpen(true)}
      >
        <div className="h-3 w-3">
          <Undo2 className="h-3 w-3" />
        </div>
        <p>{t("case-load-manager-offboarding-undo")}</p>
      </div>
      <Dialog
        open={isOpen}
        onOpenChange={(newValue) => {
          setIsOpen(newValue);
        }}
      >
        <DialogContent className="p-8">
          <DialogHeader>
            <DialogTitle className="text-xl">
              {t("case-load-manager-offboarding-undo-title")}
            </DialogTitle>
          </DialogHeader>

          <DialogDescription>
            {t("case-load-manager-offboarding-undo-desc")}
          </DialogDescription>

          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => {
                setIsOpen(false);
              }}
              className="w-1/2"
            >
              {t("case-load-manager-offboarding-undo-cancel")}
            </Button>
            <Button
              onClick={async (e) => {
                e.preventDefault();
                await save({ id });
              }}
              className="w-1/2 flex items-center space-x-2"
            >
              {isSaving && <Loader2 className="w-4 h-4 animate-spin" />}
              <p>{t("case-load-manager-offboarding-undo-save")}</p>
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
