import { useMutation } from "react-query";
import { Button } from "../../components/ui/button";
import axios from "axios";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import { useState } from "react";

export const ReSyncHiBobUserDataButton = () => {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const { mutate, isLoading } = useMutation({
    mutationFn: async () => {
      const res = await axios.post(`hibobadmin/resync-all-employees`, {});

      return res.data;
    },
    onSuccess: () => {
      setIsConfirmationDialogOpen(true);
    },
  });

  return (
    <>
      <Button
        onClick={async () => {
          await mutate();
        }}
      >
        {isLoading ? "Sychronizing..." : "Re-sync HiBob user data"}
      </Button>

      <Dialog
        open={isConfirmationDialogOpen}
        onOpenChange={(newValue) => {
          setIsConfirmationDialogOpen(newValue);
        }}
      >
        <DialogContent className="p-8">
          <DialogHeader>
            <DialogTitle className="text-xl">
              Successfully re-synced
            </DialogTitle>
            <DialogDescription>
              <p>Data from Hi-Bob has been successfully synchronized.</p>
            </DialogDescription>
            <div className="flex items-center justify-center w-full pt-4">
              <Button
                variant="outline"
                onClick={() => window.location.reload()}
              >
                OK, refresh page
              </Button>
            </div>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </>
  );
};
