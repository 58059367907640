import { ColumnDef } from "@tanstack/react-table";
import { MainListPatient } from "../../../types";
import { SortableCell } from "../../../../../components/ui/table";
import { useTranslation } from "react-i18next";
import { NeedsCheckinCheckbox } from "../../checkin/needsCheckinCheckbox";

export const CheckinNeededColName = "Needs check-in";

export const CheckinNeededCol: ColumnDef<MainListPatient> = {
  id: CheckinNeededColName,
  accessorKey: "isCheckinNeeded",
  size: 200,
  minSize: 100,
  header: ({ column }) => {
    const { t } = useTranslation();

    return (
      <SortableCell
        column={column}
        label={t("case-load-manager-column-needs-checkin")}
      />
    );
  },
  cell: ({ table, row: { index, original } }) => {
    return (
      <NeedsCheckinCheckbox
        onBlur={() => {
          // @ts-expect-error sssh
          table?.options?.meta?.updateData(index, id, value);
        }}
        data={original}
      />
    );
  },
};
